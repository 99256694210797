import { createClient } from "contentful";
import { Loading } from "../../components/loading-screen";
import { NewFooter } from "../../components/new-footer";
import { NewHeader } from "../../components/new-header";
import Markdown from "markdown-to-jsx";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatWebsiteDate } from "../../utils/functions";

export const CareerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [careerDetails, setCareerDetails] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    handleGetCareerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetCareerDetails = async () => {
    try {
      await client.getEntry(id).then((entries) => {
        console.log("fields", entries);
        setCareerDetails({
          ...entries.fields,
          createdOn: entries.sys.createdAt,
        });
        setLoading(false);
      });
    } catch (error) {
      console.log({ error });
      setLoading(false);
      setHasError(true);
    }
  };

  console.log({ careerDetails });

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_career_page'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <button className='td-btn' onClick={() => navigate("/careers")}>
              <div className='chevron chevron-left' />
              <span>Return to careers</span>
            </button>
            <h3>{careerDetails?.jobTitle}</h3>
            <div className='key-details'>
              <p>
                <span>Level:</span>
                <span>{careerDetails?.level || "-"}</span>
              </p>
              <p>
                <span>Location:</span>
                <span>{careerDetails?.location || "-"}</span>
              </p>
              <p>
                <span>Posted:</span>
                <span>{formatWebsiteDate(careerDetails?.publishedDate)}</span>
              </p>
            </div>
            <div className='description'>
              <h5>What we’re looking for</h5>
              <p>{careerDetails?.jobDescription || "-"}</p>
            </div>
            <div className='role-details'>
              <h5>Responsibilities</h5>
              <div>
                <span className='' markdown='1'>
                  <Markdown>{careerDetails?.responsibilities}</Markdown>
                </span>
              </div>
            </div>
            <div className='role-details'>
              <h5>Qualifications</h5>
              <div>
                <span className='' markdown='1'>
                  <Markdown>{careerDetails?.qualifications}</Markdown>
                </span>
              </div>
            </div>
            <div className='role-details'>
              <h5>Benefits</h5>
              <div>
                <span className='' markdown='1'>
                  <Markdown>{careerDetails?.benefits}</Markdown>
                </span>
              </div>
            </div>
            <div className='role-details'>
              <h5>About Us</h5>
              <span>{careerDetails?.aboutUs}</span>
            </div>
            <NewFooter isApplicationForm={true} careerDetails={careerDetails} />
          </div>
        </div>
      </div>
    </Loading>
  );
};
