import { createClient } from "contentful";
import { Loading } from "../../components/loading-screen";
import { NewHeader } from "../../components/new-header";
import { useEffect, useState } from "react";
import {
  getSurroundingText,
  searchResultStructure,
} from "../../utils/functions";
import { NewFooter } from "../../components/new-footer";
import { Pagination } from "../../components/pagination";
import { useNavigate } from "react-router-dom";

export const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [paginationOptions, setPaginationOptions] = useState({
    pages: 0,
    limit: 4,
    currentPage: 0,
  });
  const navigate = useNavigate();
  const searchQueryParam = new URLSearchParams(window.location.search).get("q");

  console.log({ paginationOptions });

  useEffect(() => {
    if (!results.length) return;
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  const contentModelsToExempt = [
    // "products",
    "partnerBrands",
    "partners",
  ];

  const handleSearchResults = async (searchParam) => {
    setLoading(true);
    try {
      if (!paginationOptions.pages) {
        const { items: searchedItems } = await client.getEntries({
          query: searchParam || searchQueryParam,
        });
        console.log({ searchedItems });
        const filteredResults = searchedItems.filter(
          (el) => !contentModelsToExempt.includes(el.sys.contentType.sys.id)
        );
        console.log({ filteredResults });
        setPaginationOptions({
          ...paginationOptions,
          pages: filteredResults.length,
        });
      }
      const { total, items } = await client.getEntries({
        query: searchParam || searchQueryParam,
        limit: paginationOptions.limit,
        skip: !paginationOptions.currentPage
          ? paginationOptions.currentPage
          : (paginationOptions.currentPage - 1) * paginationOptions.limit,
      });
      console.log({ total, items });
      const pageItems = items.filter(
        (el) => !contentModelsToExempt.includes(el.sys.contentType.sys.id)
      );
      console.log({ pageItems });
      const categories = pageItems.reduce((acc, curr) => {
        if (!acc.includes(curr?.sys?.contentType?.sys?.id)) {
          acc.push(curr?.sys?.contentType?.sys?.id);
        }
        return acc;
      }, []);
      const results = pageItems.map((item) => {
        const { title, description, link, category, isFile } =
          searchResultStructure(item);
        return {
          title,
          description,
          category,
          link,
          isFile,
        };
      });
      setResults(results);
      setCategories(categories);
    } catch (error) {
      console.log("searching error", error);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOptions.currentPage]);

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_search_results'>
        <div className='wrapper'>
          <NewHeader />
          <div className='body'>
            <h3>
              Showing {paginationOptions.pages || 0} Result
              {paginationOptions.pages > 1 ? "s" : ""}
            </h3>
            <div className='filter-page'>
              <div>
                <h5>Filter Results</h5>
                <div className='categories'>
                  {categories.length ? (
                    <p
                      className={selectedFilter === "all" ? "active" : ""}
                      onClick={() => setSelectedFilter("all")}
                    >
                      All
                    </p>
                  ) : null}
                  {categories.map((el) => (
                    <p
                      key={el}
                      className={el === selectedFilter ? "active" : ""}
                      onClick={() => setSelectedFilter(el)}
                    >
                      {el}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            {results.length ? (
              results
                .filter((el) => {
                  if (selectedFilter !== "all")
                    return el.category.toLowerCase() === selectedFilter;
                  return el;
                })
                .map((result, key) => (
                  <div
                    className='result'
                    key={key}
                    onClick={() =>
                      result.isFile
                        ? window.open(result.link, "_blank")
                        : navigate(result.link)
                    }
                  >
                    <p className='title'>{result?.title}</p>
                    <p className='info'>
                      {getSurroundingText(
                        result?.description,
                        searchQueryParam
                      )}
                    </p>
                  </div>
                ))
            ) : !results.length && !loading ? (
              <>
                <p>We found no results for your search query</p>
                <button className='td-btn' onClick={() => navigate("/")}>
                  <span className='chevron chevron-left' />
                  <p>Home</p>
                </button>
              </>
            ) : null}
            <Pagination
              pages={paginationOptions.pages}
              limit={paginationOptions.limit}
              setCurrentPage={(newPage) =>
                setPaginationOptions({
                  ...paginationOptions,
                  currentPage: newPage,
                })
              }
            />
          </div>
          <NewFooter />
        </div>
      </div>
    </Loading>
  );
};
