import { useState } from "react";
import { SUPPORTED_COUNTRIES } from "../utils/functions";

export function CountryFilterPolicies({
  countryCode,
  page,
  onSamePage,
  setCountryCode,
}) {
  const [country, setCountry] = useState(countryCode);

  const handleChangeCountry = (code) => {
    if (!page) return;
    if (!onSamePage) {
      const url = `/${page}?country=${code}`;
      window.location.replace(url);
    } else {
      setCountryCode(code);
      setCountry(code);
    }
  };
  return (
    <div className='filter-country'>
      <label>Select Country</label>
      <select
        className='form-control'
        value={country}
        onChange={(e) => handleChangeCountry(e.target.value)}
      >
        <option value={SUPPORTED_COUNTRIES.NG}>Nigeria</option>
        <option value={SUPPORTED_COUNTRIES.GLOBAL}>Global</option>
      </select>
    </div>
  );
}
