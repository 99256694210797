import Skeleton from "react-loading-skeleton";
import { ITEMS_OUT_OF_STOCK, remedyProductError } from "../../utils/functions";

export function CartList({
  loading = false,
  cart,
  handleAddMinusItem,
  currency,
}) {
  const cartList = loading ? [1, 3, 4] : cart?.items?.length ? cart.items : [];
  return !cartList?.length ? (
    <div className='trade-uk_cart-empty'>
      <div className='bg-empty'>
        <img src='../images/basket.svg' alt='' />
      </div>
      <div className='empty-text'>
        <h4>No items in cart</h4>
        <h6>
          Your shopping cart is currently empty. Time to start adding some
          items!
        </h6>
      </div>
    </div>
  ) : (
    <div className='trade-uk_cart-list'>
      {cartList.map((order, key) =>
        loading ? (
          <div className='' key={key} style={{ padding: "8px 16px" }}>
            <Skeleton height={80} style={{}} />
          </div>
        ) : (
          order.items.map((el, key) => {
            const isOrderOutOfStock =
              order?.metadata?.error === ITEMS_OUT_OF_STOCK;
            return (
              <>
                {order?.metadata?.fulfillable === false ? (
                  <div className='unfulfillable-orders-notice' key={key}>
                    <img src='../images/error-icon-2.svg' alt='' />
                    <p>
                      {order?.metadata?.errorMessage || ""}.{" "}
                      {remedyProductError(order?.metadata?.error)}
                    </p>
                  </div>
                ) : null}
                <div className={`trade-uk_cart-list-item`} key={key}>
                  <img
                    src={`${process.env.REACT_APP_PRODUCT_URL}/${el.variantId}.png`}
                    alt=''
                  />
                  <div className='item-details'>
                    <p>{el?.name}</p>
                    <h6>
                      {currency}
                      {el.price}
                    </h6>
                  </div>
                  <div className='counter'>
                    <p
                      className='arith'
                      onClick={() => {
                        if (isOrderOutOfStock) return;
                        handleAddMinusItem({ ...el, orderIndex: key }, "minus");
                      }}
                    >
                      -
                    </p>
                    <p className='count'>{el.quantity}</p>
                    <p
                      className='arith'
                      onClick={() => {
                        if (isOrderOutOfStock) return;
                        handleAddMinusItem({ ...el, orderIndex: key }, "add");
                      }}
                    >
                      +
                    </p>
                  </div>
                </div>
              </>
            );
          })
        )
      )}
    </div>
  );
}
