/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import {
  NG_WHATSAPP_LINE,
  PAYMENT,
  UK_WHATSAPP_LINE,
  clearLS,
  formatMoney,
  handleLSCartOrderSave,
  handleRetailOutletSave,
  handleRetrieveCartOrders,
  handleUserSave,
  printErrorMessage,
} from "../utils/functions";
import { StatusRenderer } from "./error-screen";
import { UpdateEmailForm } from "./update-email";
import { CartList } from "./components/cartList";
import { CreditContractModal } from "./credit-contract";

export function OrderSummary() {
  const [loading, setLoading] = useState(true);
  const [processingPaymentOnDelivery, setProcessingPaymentOnDelivery] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [user, setUser] = useState(null);
  const [retailOutlet, setRetailOutlet] = useState(null);
  const [shippingTypes, setShippingTypes] = useState([]);
  const [initialCart, setInitialCart] = useState(null);
  const [shippingRates, setShippingRates] = useState(null);
  const [selectedShippingType, setSelectedShippingType] = useState("");
  const [preparedOrders, setPreparedOrders] = useState(null);
  const [createdOrder, setCreatedOrder] = useState(null); // this is used for when orders have been created and users just need to pay
  const [promoCode, setPromoCode] = useState("");
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [error, setError] = useState({
    hasError: false,
    message: "",
    title: "",
  });
  const [loanBreakdown, setLoanBreakdown] = useState({});
  const navigate = useNavigate();
  const isShippingTypeSelected = (type) => selectedShippingType === type;
  const qParameters = new URLSearchParams(window.location.search);
  const token = qParameters.get("token");
  const orderPaymentReference = qParameters.get("orderPaymentReference");
  // const extPayment = qParameters.get("extPayment");
  const isANigerianOutlet = retailOutlet?.country === "NG";
  const currency = isANigerianOutlet ? "₦" : "£";

  const walletBalancePaymentOption =
    selectedPaymentOptions.find((el) => el.type === PAYMENT.WALLET) || null;
  const creditBalancePaymentOption =
    selectedPaymentOptions.find((el) => el.type === PAYMENT.CREDIT) || null;

  useEffect(() => {
    // debounce cart item increase/decrease quantity
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  useEffect(() => {
    try {
      processSimilarities(); // check if perhaps cart was in LS and a new token for new cart is provided
      if (!token)
        return setError({
          hasError: true,
          message: "Cannot find token to validate",
          title: "Invalid Token",
        }); // break page if user needs to authenticate but is without a token
      handleAuthenticateOrderToken();
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (initialCart?.v2CartItems) handlePrepareCartItems();
  }, [initialCart?.v2CartItems?.length]);

  const processSimilarities = () => {
    const cart = handleRetrieveCartOrders();
    if (cart && token === cart.authToken) {
      return true;
    }
    clearLS();
    return false;
  };

  const handleSetPaymentOption = (option) => {
    if (isProcessing) return;
    let balance = fees.total;
    let paymentOptions = selectedPaymentOptions;
    if (!paymentOptions.find((el) => el.type === option)) {
      paymentOptions.push({ type: option });
    } else {
      paymentOptions = paymentOptions.filter((el) => el.type !== option);
    }
    paymentOptions = paymentOptions.map((el) => {
      if (el.type === PAYMENT.WALLET) {
        if (balance > user.availableBalance) {
          // if the balance - userAvailable Balance has a remainder
          el.amount = user.availableBalance;
        } else el.amount = balance;
        balance = balance - user.availableBalance;
      }
      if (el.type === PAYMENT.CREDIT) {
        if (balance > user.creditBalance) {
          // if the balance - creditAvailable Balance has a remainder
          el.amount = user.creditBalance;
        } else el.amount = balance;
        if (balance) balance = balance - user.creditBalance;
      }
      return { ...el, amount: Number(el.amount) };
    });
    return setSelectedPaymentOptions([...paymentOptions]);
  };

  const fulfillableOrderCount = useMemo(() => {
    const fulfillableOrders =
      preparedOrders?.orders?.reduce((acc, curr) => {
        if (curr?.metadata?.fulfillable) {
          return [...acc, ...curr.items];
        }
        return acc;
      }, []) || [];
    return fulfillableOrders.length;
  }, [initialCart]);

  const handleAuthenticateOrderToken = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_AWS_API_URL}/login`;
      if (orderPaymentReference)
        url = url + `?reference=${orderPaymentReference}`;
      const {
        data: { data },
      } = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (orderPaymentReference) {
        const {
          retailOutlet,
          user,
          availableBalance,
          creditBalance,
          paymentTransaction,
          hasActiveContract = false,
        } = data;
        if (!paymentTransaction) {
          return setError({
            hasError: true,
            title: "Transaction Error",
            message: "We could not find your order, please make a new one",
            canRetry: true,
            retryText: "Go to whatsapp",
            retryFunction: () =>
              (window.location.href =
                retailOutlet?.country === "UK"
                  ? UK_WHATSAPP_LINE
                  : NG_WHATSAPP_LINE),
          });
        }
        setCreatedOrder(paymentTransaction);
        const { amount, currency, _id } = paymentTransaction;
        setUser({
          ...user,
          availableBalance,
          creditBalance,
          hasActiveContract,
        });
        handleUserSave({
          ...user,
          availableBalance,
          creditBalance,
          hasActiveContract,
        });
        setRetailOutlet(retailOutlet);
        handleRetailOutletSave(retailOutlet);
        setInitialCart({
          estimatedDeliveryDate: paymentTransaction?.estimatedDeliveryDate,
        });
        handleLSCartOrderSave({ orderTotal: amount, currency, _id });
        setLoading(false);
      } else {
        const {
          cartOrder,
          retailOutlet,
          user,
          paymentReference,
          availableBalance,
          creditBalance,
        } = data;
        const newCart = {
          ...cartOrder,
          v2CartItems: cartOrder.items,
          paymentReference,
        }; // the v2 is the one that changes
        setInitialCart(newCart);
        setUser({ ...user, availableBalance, creditBalance });
        handleLSCartOrderSave(newCart); // save initial cart to the LS
        handleRetailOutletSave(retailOutlet);
        setRetailOutlet(retailOutlet);
        handleUserSave({ ...user, availableBalance, creditBalance });
      }
      // if (extPayment) navigate("/verify-phone", { state: { extPayment } });
    } catch (error) {
      const { message, title } = printErrorMessage(error);
      setLoading(false);
      setError({ hasError: true, message, title });
    }
  }, []);

  const handlePrepareCartItems = useCallback(async () => {
    try {
      setLoading(true);
      setSelectedShippingType("");
      setShippingTypes([]);
      const preparedOrders = await handlePrepareOrder();
      await handleGetShippingRate(preparedOrders);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [initialCart]);

  const handlePrepareOrder = async () => {
    const {
      retailOutletId,
      _id,
      items,
      extChannel,
      issuedAt,
      preview,
      contactPhone,
      v2CartItems,
    } = initialCart;
    const pItems = preparedOrders?.rawOrder?.items?.length
      ? preparedOrders.rawOrder.items
      : v2CartItems?.length
      ? v2CartItems
      : items;
    try {
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_API_V2}/retail/orders/prepare`,
        {
          retailOutletId,
          _id,
          items: pItems,
          extChannel,
          issuedAt,
          preview,
          contactPhone,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setPreparedOrders(data);
      // update the initial cart to have the new items
      const mappedCartItems = data.orders.reduce((acc, curr) => {
        const items = curr.items.map((el) => ({
          ...el,
          metadata: curr.metadata,
        }));
        return [...acc, ...items];
      }, []);
      const newCart = { ...initialCart, v2CartItems: mappedCartItems };
      setInitialCart(newCart);
      handleLSCartOrderSave(newCart);
      return data;
    } catch (error) {
      const err = printErrorMessage(error, "", true); // use server message
      setError({ hasError: true, title: err.title, message: err.message });
      setIsProcessing(false);
      setLoading(false);
    }
  };

  const handleGetShippingRate = async (preparedOrders) => {
    try {
      const { orders, retailOutletId } = preparedOrders;
      const fulfillableOrders = orders.filter((el) => el.metadata.fulfillable);
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_API_V3}/shipping/rate`,
        { orders: fulfillableOrders, retailOutletId },
        {}
      );
      setShippingTypes([
        {
          type: "express",
          amount: data?.shippingDetails?.expressCost?.amount,
          delivery: "1-2 working days",
          tax: data?.shippingDetails?.expressCost?.tax,
        },
        {
          type: "standard",
          amount: data?.shippingDetails?.standardCost?.amount,
          delivery: "3-5 working days",
          tax: data?.shippingDetails?.standardCost?.tax,
        },
      ]);
      setSelectedShippingType("standard");
      setLoading(false);
      setShippingRates(data);
    } catch (error) {
      // window.alert(error);
      setLoading(false);
    }
  };

  const handleCreateCartOrder = async (ratesData) => {
    try {
      const {
        data: { data },
      } = await axios.post(
        process.env.REACT_APP_AWS_CART_ORDER_API,
        ratesData,
        {
          headers: {
            "x-api-key": user.apiKey,
          },
        }
      );
      return data;
    } catch (error) {}
  };

  const handleGoToPay = async () => {
    // this handles payment for non-NG outlets
    setIsProcessing(true);
    const cartOrder = await handleCreateCartOrder({
      ...shippingRates,
      shippingType: selectedShippingType,
      rawOrder: preparedOrders.rawOrder,
    });
    navigate("/wholesale/pay", {
      state: {
        cartOrder,
      },
    });
  };

  const handleAddMinusItem = (itemToEdit, operator) => {
    if (!operator || isProcessing) return;
    const {
      rawOrder: { items },
      orders,
    } = preparedOrders;
    const { variantId, orderIndex } = itemToEdit;
    const itemIndex = items.findIndex((el) => el.variantId === variantId);
    let { quantity } = items[itemIndex];
    if (operator === "minus" && quantity === 1) return;
    if (operator === "minus" && quantity > 1) quantity = quantity - 1;
    if (operator === "add") quantity = quantity + 1;
    items[itemIndex] = { ...items[itemIndex], quantity }; // update raw order
    const order = orders[itemToEdit.orderIndex];
    const orderItemIndex = order.items.findIndex(
      (el) => el.variantId === variantId
    );
    orders[orderIndex].items[orderItemIndex].quantity = quantity; // update prepared order[item]
    setPreparedOrders({ ...preparedOrders, orders });
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      handlePrepareCartItems();
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  const fees = useMemo(() => {
    if (orderPaymentReference)
      return {
        total: (createdOrder?.amount || 0).toFixed(2),
        subtotal: (createdOrder?.amount || 0).toFixed(2),
      };
    let subtotal = 0;
    const selectedDeliveryOption =
      shippingTypes.find((el) => el.type === selectedShippingType) || {};
    let deliveryfee = selectedDeliveryOption?.amount || 0;
    const shippingTax = selectedDeliveryOption?.tax || 0;
    let saleTax = 0;
    let vat = 0;
    let discount = 0;
    let processingCostTax = shippingRates?.processingCost?.tax || 0;
    let processingCost = shippingRates?.processingCost?.amount || 0;
    const fulfillableOrders =
      preparedOrders?.orders?.filter((el) => el.metadata.fulfillable) || [];

    if (fulfillableOrders.length) {
      subtotal = fulfillableOrders.reduce(
        (acc, curr) => acc + curr?.subTotal || 0,
        0
      );
      // vat = fulfillableOrders.reduce((acc, curr) => acc + curr?.taxes || 0, 0);
      discount = fulfillableOrders.reduce(
        (acc, curr) => acc + curr?.discounts || 0,
        0
      );
      const promoTaxes = fulfillableOrders.reduce(
        (acc, curr) => acc + curr?.promoTaxes || 0,
        0
      );
      saleTax = shippingTax + processingCostTax + promoTaxes;
    }
    return {
      subtotal: subtotal.toFixed(2),
      processingCost: processingCost.toFixed(2),
      deliveryfee: deliveryfee.toFixed(2),
      saleTax: saleTax.toFixed(2),
      vat: vat.toFixed(2),
      discount,
      total: (
        subtotal +
        processingCost +
        deliveryfee +
        saleTax -
        discount
      ).toFixed(2),
    };
  }, [preparedOrders?.orders?.length, selectedShippingType, createdOrder]);

  const handlePrepareLoanContract = async () => {
    try {
      await axios.post(
        `https://0zl4k1w352.execute-api.us-east-1.amazonaws.com/dev/confirm-loan`,
        { amount: creditBalancePaymentOption?.amount, creditAmount: true },
        { headers: { "x-api-key": user.apiKey } }
      );
      setIsProcessing(false);
      handleOpenCreditContractModal();
    } catch (error) {
      setIsProcessing(false);
      console.log("preparing loan contract", error.response.data);
      setLoanBreakdown(error.response.data);
    }
    return handleOpenCreditContractModal();
  };

  const completePayment = async () => {
    setIsProcessing(true);
    const balance = Number(balanceTopay);
    if (!balance) return handleChargeOrder(); // call charge order is balance is 0
    const body = {
      availableBalance: walletBalancePaymentOption?.amount || 0,
      creditBalance: creditBalancePaymentOption?.amount || 0,
      amount: balance,
    };
    // if (initialCart?._id) body.cartOrderId = initialCart._id;
    if (orderPaymentReference)
      body.orderPaymentReference = orderPaymentReference;
    setIsProcessing(false);
    const payData = {
      country: retailOutlet.country,
      orderPaymentReference,
      email: retailOutlet.email,
      amount: balance,
      creditBalance: creditBalancePaymentOption?.amount || 0,
      walletCapacity: walletBalancePaymentOption?.amount || 0, // money that was in the wallet before user needed to transfer more
    };
    return navigate("/wholesale/pay", {
      state: payData,
    });
  };

  const handleCreatePaymentAuth = async () => {
    // handles NG outlet payments
    setIsProcessing(true);
    try {
      if (!retailOutlet.email) return handleOpenEmailUpdateModal();
      // if credit is used in payment, the user has to sign a contract
      if (creditBalancePaymentOption?.amount)
        return handlePrepareLoanContract();
      await completePayment();
    } catch (error) {
      setIsProcessing(false);
      handleErrorDisplay(error);
    }
  };

  const handleOpenEmailUpdateModal = () => {
    document.getElementById("trigger-email-button").click();
  };

  const handleOpenCreditContractModal = () => {
    document.getElementById("trigger-credit-contract-button").click();
  };

  const handleMakePaymentOnDelivery = async () => {
    try {
      setProcessingPaymentOnDelivery(true);
      const url = `${process.env.REACT_APP_AWS_API_URL}/create-order`;
      const {
        data: {
          data: { reference },
        },
      } = await axios.post(
        url,
        { ...preparedOrders },
        {
          headers: {
            "x-api-key": user.apiKey,
          },
        }
      );
      return navigate("/wholesale/confirm-payment", {
        state: {
          reference,
          hasOrderReference: true, // the order is created and has a reference
        },
      });
    } catch (error) {
      setProcessingPaymentOnDelivery(false);
      handleErrorDisplay(error);
    }
  };

  const handleChargeOrder = async (params) => {
    try {
      const body = {
        availableBalance: walletBalancePaymentOption?.amount || 0,
        creditBalance: creditBalancePaymentOption?.amount || 0,
      };
      if (orderPaymentReference) {
        body["orderPaymentReference"] = orderPaymentReference;
      }
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL}/charge-order`,
        body,
        {
          headers: {
            "x-api-key": user?.apiKey,
            "Content-Type": "application/json",
          },
        }
      );
      return navigate("/wholesale/confirm-payment", {
        state: {
          reference: data.transactionReference,
          hasOrderReference: true, // the order is created and has a reference
          hasActiveLoanContract: user.hasActiveContract,
        },
      });
    } catch (error) {
      setIsProcessing(false);
      handleErrorDisplay(error);
    }
  };

  const balanceTopay = useMemo(() => {
    let amount = fees.total;
    if (!selectedPaymentOptions.length) return amount;
    selectedPaymentOptions.forEach((el) => {
      if (el.type === PAYMENT.WALLET && amount) {
        amount = amount - (user.availableBalance || 0);
      }
      if (el.type === PAYMENT.CREDIT && amount) {
        amount = amount - (user.creditBalance || 0);
      }
    });
    if (amount < 0) return 0;
    return amount;
  }, [selectedPaymentOptions, fees.total]);

  const handleErrorDisplay = (error) => {
    if (!error) return;
    const { message, title } = printErrorMessage(error);
    setError({ hasError: true, message, title });
  };

  return (
    <div className='trade-uk-checkout'>
      <div className='trade-uk-checkout_header'>
        <p>Your Order</p>
      </div>
      {error.hasError ? (
        <StatusRenderer
          message={error.message}
          title={error.title}
          canRetry={error?.canRetry || false}
          retryText={error?.retryText || null}
          retryFunction={error?.retryFunction || null}
        />
      ) : (
        <>
          <div
            className='trade-uk-checkout body'
            id={isANigerianOutlet ? "isNG" : ""}
          >
            <div className='trade-uk-checkout_shipping-address'>
              <h3>Shipping address</h3>
              {loading ? (
                <Skeleton height={120} />
              ) : (
                <div className='_card'>
                  <p id='name'>{retailOutlet?.outletBusinessName || "-"}</p>
                  <span id='address'>
                    {retailOutlet?.formattedAddress || "-"}
                  </span>
                  <span id='phone'>{retailOutlet?.contactPhone || "-"}</span>
                </div>
              )}
            </div>

            {isANigerianOutlet ? (
              <div className='trade-uk-checkout_delivery'>
                <h3>Delivery</h3>
                <div className='_card etd'>
                  {loading ? (
                    [1].map((_, key) => (
                      <Skeleton key={key} height={80} className='option' />
                    ))
                  ) : (
                    <div className='option'>
                      <div className='order-details'>
                        <b>Estimated delivery on</b>
                        <p id='etd'>
                          {moment(initialCart.estimatedDeliveryDate).format(
                            "dddd, Do MMMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            <div className='trade-uk-checkout_payment-summary'>
              <h3>Payment Summary</h3>
              {loading ? (
                <Skeleton height={120} />
              ) : (
                <div className='_card'>
                  <div className='summary-flex'>
                    <p>Subtotal</p>
                    <p>
                      {currency} {formatMoney(fees?.subtotal || 0)}
                    </p>
                  </div>
                  <div className='summary-flex'>
                    <p>Delivery fee</p>
                    <p>
                      {currency} {formatMoney(fees?.deliveryfee || 0)}
                    </p>
                  </div>
                  <div className='summary-flex'>
                    <p>Processing Cost</p>
                    <p>
                      {currency} {formatMoney(fees?.processingCost || 0)}
                    </p>
                  </div>
                  <div className='summary-flex'>
                    <p>V.A.T</p>
                    <p>
                      {currency} {formatMoney(fees?.saleTax || 0)}
                    </p>
                  </div>
                  {fees.discount ? (
                    <div className='summary-flex discount'>
                      <p>Discounts</p>
                      <p>
                        - {currency} {formatMoney(fees?.discount)}
                      </p>
                    </div>
                  ) : null}
                  <div className='summary-flex total'>
                    <p>Total</p>
                    <p>
                      {currency} {formatMoney(fees?.total)}
                    </p>
                  </div>
                </div>
              )}
            </div>

            {isANigerianOutlet ? null : (
              <>
                <div className='trade-uk-checkout_delivery'>
                  <h3>Delivery Options</h3>
                  <div className='_card'>
                    {loading ? (
                      [1, 2].map((_, key) => (
                        <Skeleton key={key} height={80} className='option' />
                      ))
                    ) : shippingTypes?.length ? (
                      shippingTypes.map((el, key) => (
                        <div
                          key={key}
                          className={`option ${
                            isShippingTypeSelected(el.type) && "active"
                          }`}
                          onClick={() =>
                            isProcessing
                              ? null
                              : setSelectedShippingType(el.type)
                          }
                        >
                          <input
                            type='radio'
                            checked={isShippingTypeSelected(el.type)}
                          />
                          <div className='order-details'>
                            <b>{el.type} delivery</b>
                            <p>Delivered in {el.delivery}</p>
                          </div>
                          <p className='price'>
                            {currency} {el.amount}
                          </p>
                        </div>
                      ))
                    ) : (
                      <StatusRenderer
                        title={"Error!"}
                        message={
                          "Unable to retrieve delivery options based on your orders at the moment"
                        }
                        useInAContainer={true}
                      />
                    )}
                  </div>
                </div>
                <div className='trade-uk-checkout_cart-items'>
                  <h3>Cart Items</h3>{" "}
                  <div className='_card'>
                    {loading ? (
                      [1, 2, 3].map((_, key) => (
                        <Skeleton
                          height={80}
                          key={key}
                          style={{ marginBottom: 16 }}
                        />
                      ))
                    ) : (
                      <CartList
                        disabled={isProcessing}
                        cart={{ items: preparedOrders?.orders }}
                        loading={loading}
                        handleAddMinusItem={handleAddMinusItem}
                        currency={currency}
                      />
                    )}
                    <div className='trade-uk_promo'>
                      <input
                        className='form-control'
                        placeholder='Enter promo code'
                        onChange={(e) => setPromoCode(e.target.value)}
                      />
                      <button
                        className='trade-uk-app-button'
                        disabled={!promoCode.length}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {isANigerianOutlet ? (
              <div className='trade-uk-checkout_payment-methods'>
                <h3>Payment Methods</h3>
                <div className='_card'>
                  {loading ? (
                    [1, 2].map((_, key) => (
                      <Skeleton key={key} height={80} className='option' />
                    ))
                  ) : (
                    <>
                      {user?.availableBalance > 0 ? (
                        <div
                          className={`option ${
                            !!walletBalancePaymentOption ? "selected" : ""
                          }`}
                          onClick={() => handleSetPaymentOption(PAYMENT.WALLET)}
                        >
                          <input
                            type='checkbox'
                            checked={!!walletBalancePaymentOption}
                          />
                          <div>
                            <p>apply Wallet Balance</p>
                            <h5>₦{formatMoney(user.availableBalance)}</h5>
                          </div>
                          {!!walletBalancePaymentOption ? (
                            <h3 className='deducted'>
                              -₦{formatMoney(walletBalancePaymentOption.amount)}
                            </h3>
                          ) : null}
                        </div>
                      ) : null}
                      {user?.creditBalance > 0 ? (
                        <div
                          className={`option ${
                            !!creditBalancePaymentOption ? "selected" : ""
                          }`}
                          onClick={() => handleSetPaymentOption(PAYMENT.CREDIT)}
                        >
                          <input
                            type='checkbox'
                            checked={!!creditBalancePaymentOption}
                          />
                          <div>
                            <p>apply Credit Balance</p>
                            <h5>₦{formatMoney(user.creditBalance)}</h5>
                          </div>
                          {!!creditBalancePaymentOption ? (
                            <h3 className='deducted'>
                              -₦{formatMoney(creditBalancePaymentOption.amount)}
                            </h3>
                          ) : null}
                        </div>
                      ) : null}
                      <div className='balance'>
                        <h5>To pay:</h5>
                        <p>
                          {currency}
                          {formatMoney(balanceTopay)}
                        </p>
                      </div>
                      <button
                        className='trade-uk-app-button'
                        onClick={handleCreatePaymentAuth}
                        disabled={
                          isProcessing ||
                          processingPaymentOnDelivery ||
                          (Number(user?.availableBalance) >=
                            Number(balanceTopay) &&
                            !selectedPaymentOptions.length)
                        }
                      >
                        {isProcessing ? (
                          <div
                            class='spinner-border text-success app-spinner'
                            role='status'
                          >
                            <span class='sr-only'>Loading...</span>
                          </div>
                        ) : (
                          "Continue"
                        )}
                      </button>
                      {retailOutlet?.paymentRequired ||
                      !retailOutlet?.kyc?.fieldVerified ||
                      orderPaymentReference ? null : (
                        <>
                          <div className='payment-or'>OR</div>
                          <button
                            className='trade-uk-app-button outline'
                            disabled={
                              selectedPaymentOptions.length ||
                              processingPaymentOnDelivery
                            }
                            onClick={handleMakePaymentOnDelivery}
                          >
                            {processingPaymentOnDelivery ? (
                              <div
                                class='spinner-border text-success app-spinner'
                                role='status'
                              >
                                <span class='sr-only'>Loading...</span>
                              </div>
                            ) : (
                              "Pay on delivery"
                            )}
                          </button>
                          <div className='delivery-disclaimer'>
                            <img src='../images/error-icon-yellow.svg' alt='' />
                            <p>
                              <b>Note:</b> Payment guarantees your order. Unpaid
                              orders are subject to availability and may incur
                              price changes
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          {isANigerianOutlet ? null : (
            <div className='trade-uk-checkout_footer'>
              <p>
                For information about shipping & returns, see TradeDepot
                Wholesale's
                <a
                  href={`${window.location.origin}/returns`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {" "}
                  Shipping and Returns Policy
                </a>
              </p>
              <button
                onClick={handleGoToPay}
                disabled={
                  loading ||
                  !shippingTypes ||
                  !fees.total ||
                  error.hasError ||
                  isProcessing ||
                  !selectedShippingType
                }
                className='trade-uk-app-button'
              >
                {isProcessing ? (
                  <div
                    class='spinner-border text-success app-spinner'
                    role='status'
                  >
                    <span class='sr-only'>Loading...</span>
                  </div>
                ) : (
                  `Pay ${
                    fulfillableOrderCount &&
                    fulfillableOrderCount <
                      preparedOrders?.rawOrder?.items?.length &&
                    !loading
                      ? `(${fulfillableOrderCount} items)`
                      : ""
                  }`
                )}
              </button>
            </div>
          )}
        </>
      )}
      <UpdateEmailForm
        user={user}
        updateEmail={(email) => {
          setRetailOutlet({ ...retailOutlet, email });
          handleRetailOutletSave({ ...retailOutlet, email });
          document.getElementById("btn-email-modal-close").click();
          setIsProcessing(false);
          handleCreatePaymentAuth();
        }}
        closeModal={() => {
          setIsProcessing(false);
        }}
      />
      <CreditContractModal
        creditAmount={creditBalancePaymentOption?.amount || 0}
        continueToPay={async () => {
          await completePayment();
          document.getElementById("credit-contract-close-btn").click();
        }}
        loading={isProcessing}
        loanBreakdown={loanBreakdown}
      />
    </div>
  );
}
