import React from "react";

export const TradeFooter = () => {
  return (
    <div className="trade-footer__content">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 pb-5">
            <img src="images/logo-alt-png.png" width="200" alt="" />
            <p>
              TradeDepot enables Factory-to-Retail distribution for
              <br />
              Consumer Goods companies.
            </p>
            <a
              href="https://www.facebook.com/tradedepot.co"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-2x fa-facebook-square"></i>
            </a>
            <a
              href="https://twitter.com/tradedepotHQ"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-2x fa-twitter-square"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/tradedepot/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-2x fa-linkedin-square"></i>
            </a>
            <a
              href="https://www.instagram.com/tradedepot.co/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-2x fa-instagram"></i>
            </a>
          </div>
          <div className="col-lg-2 pb-5"></div>
          <div className="col-lg-3 pb-5 pd-c">
            <h6>COMPANY</h6>
            <a href="/culture">Our Culture</a>
            <br />
            <a href="/careers">Careers</a>
            <br />
          </div>
          <div className="col-lg-3">
            <h6>CONTACT US</h6>
            <p>
              3/4 Adewunmi Industrial Estate, <br />
              Kudirat Abiola Way, Oregun, Lagos.
            </p>
            <p>
              1390 Market, St, Suite 200, San Francisco, <br />
              CA 94102 San Francisco.
            </p>
            <p>
              <b>Email:</b> ​
              <a href="mailto:hello@tradedepot.co">hello@tradedepot.co</a>
              <br />
              <b>Phone:</b> 0700 999 0000
            </p>
          </div>
        </div>

        <div className="trade-footer__line">
          © {new Date().getFullYear()} TradeDepot, Inc. All rights reserved.
          <p className="float-right">
            <a href="/terms" className="mr-4">
              Privacy
            </a>
            <a
              href="https://help.tradedepot.co/"
              target="_blank"
              className="mr-4"
              rel="noreferrer"
            >
              Help Center
            </a>
            <a href="/terms" className="mr-4">
              Terms
            </a>
            <a href="/terms">Acceptable Use</a>
          </p>
          <p></p>
        </div>
      </div>
    </div>
  );
};
