import { useState } from "react";
import { CountryFilterPolicies } from "../../components/country-filter-policies";
import { NewFooter } from "../../components/new-footer";
import { NewHeader } from "../../components/new-header";
import { SUPPORTED_COUNTRIES } from "../../utils/functions";
import {
  // GHPrivacyPolicies,
  NGPrivacyPolicies,
  UKPrivacyPolicies as GlobalPolicies,
} from "../../privacy";

export function Policy() {
  const [countryCode, setCountryCode] = useState("NG");

  return (
    <div className='td_new td_policy'>
      <div className='wrapper'>
        <NewHeader />
        <div className='body'>
          <div className='introduction'>
            <h2 className='title'>Privacy</h2>
            <h6>26.08.2024</h6>
            <hr />
            <CountryFilterPolicies
              countryCode={countryCode}
              page={"terms"}
              onSamePage={true}
              setCountryCode={setCountryCode}
            />
            {/* <h5>Trade Depot Privacy Notice</h5> */}
          </div>
          {countryCode === SUPPORTED_COUNTRIES.NG ? (
            <NGPrivacyPolicies />
          ) : (
            // ) : countryCode === SUPPORTED_COUNTRIES.GH ? (
            //   <GHPrivacyPolicies />
            <GlobalPolicies />
          )}
        </div>
        <NewFooter />
      </div>
    </div>
  );
}
