/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Careers } from "./careers";
import { Culture } from "./culture";
import { LandingView as Supply } from "./landing";
// import { LandingTwo } from "./landing2";
// import { Terms } from "./terms";
import lottie from "lottie-web";
import animationData from "./animation/circular.json";
import { LandingUk } from "./landinguk";
import { Layout } from "./layout";
// import { PrivacyPolicies } from "./privacy";
import { UkShippingReturns } from "./shipping-returns";
import { ConfirmPayment } from "./checkout-screens/confirm-payment";
// import { Auth } from "./checkout-screens/auth";
import "react-loading-skeleton/dist/skeleton.css";
import { Payment } from "./checkout-screens/pay";
import { OrderSummary } from "./checkout-screens/order-summary";
import { AddressInput } from "./checkout-screens/address";
import { VerifyPhone } from "./checkout-screens/verify-phone";
// import { TermsConditions } from "./terms2";
import { KeyAccountTerms } from "./key-account-terms";
import { NewLanding } from "./pages/v2-website/new-landing";
import { FeaturedBrands } from "./pages/v2-website/featured-brands";
import { BrandPage } from "./pages/v2-website/brand-page";
import { Distributor } from "./pages/v2-website/distributor";
import { Partners } from "./pages/v2-website/partners";
import { Retailers } from "./pages/v2-website/retailers";
import { NewCareers } from "./pages/v2-website/careers";
import { Blog } from "./pages/v2-website/blog";
import { BlogPost } from "./pages/v2-website/blog-post";
import { Resources } from "./pages/v2-website/resources";
import { ContactUs } from "./pages/v2-website/contact-us";
import { Policy } from "./pages/v2-website/policy";
import { TermsAndConditions } from "./pages/v2-website/terms-conditions";
import { NotFoundPage } from "./pages/v2-website/not-found";
import "react-multi-carousel/lib/styles.css";
import { CareerDetails } from "./pages/v2-website/career.details";
import { SearchResults } from "./pages/v2-website/search.results";

function Router() {
  // const location = useLocation();

  // const possibleRoutes = [
  //   "/",
  //   "/careers",
  //   "/culture",
  //   "/terms",
  //   "/landing-2",
  //   "/supply",
  //   "/en_ng",
  //   "/en_gb",
  //   "/privacy",
  //   "/returns",
  //   "/auth",
  //   "/wholesale/complete",
  //   "/wholesale/checkout",
  //   "/wholesale/confirm-payment",
  //   "/wholesale/pay",
  //   "/change-address",
  //   "/verify-phone",
  //   "/key-account-terms",
  //   "/",
  //   "/new/brands",
  //   "/new/brand",
  //   "/new/distributors",
  //   "/new/partners",
  //   "/new/retailers",
  //   "/new/careers",
  //   "/new/blog",
  //   "/new/resources",
  //   "/new/contact",
  //   "/new/policy",
  //   "/new/terms",
  // ];

  // useEffect(() => {
  //   if (
  //     window.location.href.endsWith("/") &&
  //     window.location.pathname.length > 1
  //   ) {
  //     return (window.location.href = window.location.href.slice(0, -1));
  //   }
  //   if (
  //     !possibleRoutes.includes(location.pathname) &&
  //     !location.pathname.startsWith("/new/brand") &&
  //     !location.pathname.startsWith("/new/blog/post")
  //   ) {
  //     return (window.location.href = "/");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById("circular"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <Routes>
      {/* New landing routes */}
      <Route path='/' element={<Layout />}>
        <Route index element={<NewLanding />} />
        <Route path='/en_gb' element={<NewLanding />} />
        <Route path='/en_ng' element={<NewLanding />} />
        <Route path='/brands' element={<FeaturedBrands />} />
        <Route path='/brand/:id' element={<BrandPage />} />
        <Route path='/distributors' element={<Distributor />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/marketplace' element={<Retailers />} />
        <Route path='/careers' element={<NewCareers />} />
        <Route path='/career/:id' element={<CareerDetails />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/post/:id' element={<BlogPost />} />
        <Route path='/resources' element={<Resources />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/privacy' element={<Policy />} />
        <Route path='/terms' element={<TermsAndConditions />} />
        <Route path='/search-results' element={<SearchResults />} />
        <Route path='/wholesale/confirm-payment' element={<ConfirmPayment />} />
        <Route path='/wholesale/checkout' element={<OrderSummary />} />
        <Route path='/wholesale/pay' element={<Payment />} />
        <Route path='/change-address' element={<AddressInput />} />
        <Route path='/verify-phone' element={<VerifyPhone />} />
        <Route path='/returns' element={<UkShippingReturns />} />
        <Route path='/key-account-terms' element={<KeyAccountTerms />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>

      {/* Old routes */}
      <Route path='/old' element={<Layout />}>
        {/* <Route index element={<LandingView />} /> */}
        <Route index element={<LandingUk />} />
        <Route path='/old/careers' element={<Careers />} />
        <Route path='/old/culture' element={<Culture />} />
        <Route path='/old/supply' element={<Supply />} />
        {/* 
        <Route path='/landing-2' element={<LandingTwo />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/en_gb' element={<LandingUk />} />
        <Route path='/en_ng' element={<LandingUk />} />
        <Route path='/terms' element={<TermsConditions />} />
        <Route path='/key-account-terms' element={<KeyAccountTerms />} />
        <Route path='/privacy' element={<PrivacyPolicies />} />
        <Route path='/returns' element={<UkShippingReturns />} /> */}
      </Route>
    </Routes>
  );
}

export default Router;
