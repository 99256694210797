import { useState } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";
import { StatusRenderer } from "./error-screen";
import { printErrorMessage } from "../utils/functions";

export const UpdateEmailForm = ({ user, updateEmail, closeModal }) => {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [sentToken, setSentToken] = useState(null); // from the api
  const [otp, setOTP] = useState(null); // inputted otp
  const [error, setError] = useState({ hasError: false, errorMessage: "" });
  const [loading, setLoading] = useState(false);

  const handleVerifyOTP = async () => {
    setLoading(true);
    try {
      if (otp !== sentToken) {
        setLoading(false);
        return setError({
          hasError: true,
          title: "Invalid Token",
          errorMessage:
            "The OTP you have inputted does not match the token we sent you",
          canRetry: true,
          retryText: "Want to try again?",
          retryFunction: () => {
            setError((current) => ({ ...current, hasError: false }));
            setOTP(null);
            return;
          },
        });
      }
      const url = `${process.env.REACT_APP_CONSOLE_V3}/retail/accounts/update-email`;
      const res = await axios.post(
        url,
        { email, validateToken: true },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": user.apiKey,
          },
        }
      );
      if (res.data.status === "success") updateEmail(email);
    } catch (error) {
      const { message, title } = printErrorMessage(error);
      setLoading(false);
      return setError({
        hasError: true,
        title,
        errorMessage: message,
        canRetry: true,
        retryText: "Try again?",
        retryFunction: () =>
          setError((current) => ({ ...current, hasError: false })),
      });
    }
  };

  const handleSendEmailOTP = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_FIREBASE_URL}/phoneauth/send-totp`,
        {
          email,
          domain: "tradedepot",
          mode: "email",
        }
      );
      setStep(2);
      setSentToken(res.data.token);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { message, title } = printErrorMessage(error);
      console.log({ message, title });
      setError({
        hasError: true,
        errorMessage: message,
        title,
        canRetry: true,
        retryText: "Try again!",
        retryFunction: () => setError({ hasError: false }),
      });
    }
  };

  return (
    <div className='trade-checkout-update-email'>
      <button
        className='trigger-button'
        id='trigger-email-button'
        data-bs-toggle='modal'
        data-bs-target='#exampleModal'
        style={{ display: "none" }}
      >
        Trigger Update email
      </button>
      <div
        class={"trade-checkout-modal modal fade"}
        id='exampleModal'
        tabindex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          {error.hasError ? (
            <div class='modal-content'>
              <div class='modal-body'>
                <StatusRenderer
                  message={error.errorMessage}
                  title='Error'
                  useInAContainer={true}
                  canRetry={error?.canRetry}
                  retryText={error?.retryText}
                  retryFunction={error?.retryFunction || null}
                />
              </div>
            </div>
          ) : (
            <>
              {step === 1 ? (
                <EmailInput
                  setEmail={setEmail}
                  handleSendEmailOTP={handleSendEmailOTP}
                  email={email}
                  closeModal={closeModal}
                  loading={loading}
                />
              ) : step === 2 ? (
                <OTPInput
                  otp={otp}
                  setOTP={setOTP}
                  handleVerifyOTP={handleVerifyOTP}
                  closeModal={closeModal}
                  loading={loading}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const EmailInput = ({
  setEmail,
  handleSendEmailOTP,
  email,
  closeModal,
  loading,
}) => {
  return (
    <div class='modal-content'>
      <div class='modal-header'>
        <h1 class='modal-title fs-5' id='exampleModalLabel'>
          Confirm Email Address
        </h1>
        <button
          type='button'
          class='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          id='btn-email-modal-close'
          onClick={closeModal}
        >
          <i class='bi bi-x-lg'></i>
        </button>
      </div>
      <div class='modal-body'>
        Please enter your email address to complete your payment
        <div className='mt-3'>
          <label>Enter email address</label>
          <input
            className='form-control'
            placeholder='email@email.co'
            onChange={({ target: { value } }) => setEmail(value)}
          />
        </div>
      </div>
      <div class='modal-footer'>
        <button
          type='button'
          className='trade-uk-app-button'
          disabled={!email || loading}
          onClick={handleSendEmailOTP}
        >
          {loading ? (
            <div class='spinner-border text-success app-spinner' role='status'>
              <span class='sr-only'>Loading...</span>
            </div>
          ) : (
            "Continue"
          )}
        </button>
      </div>
    </div>
  );
};

const OTPInput = ({ otp, setOTP, handleVerifyOTP, closeModal, loading }) => {
  return (
    <div class='modal-content'>
      <div class='modal-header'>
        <h1 class='modal-title fs-5' id='exampleModalLabel'>
          Enter OTP
        </h1>
        <button
          type='button'
          class='btn-close'
          id='btn-email-modal-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          onClick={closeModal}
        >
          <i class='bi bi-x-lg'></i>
        </button>
      </div>
      <div className='otp-input'>
        <OtpInput
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          containerStyle={{ justifyContent: "center" }}
          inputStyle={{ height: 45, width: 45 }}
          onChange={(value) => setOTP(value)}
          value={otp}
        />
      </div>

      <div class='modal-footer'>
        <button
          type='button'
          className='trade-uk-app-button'
          onClick={handleVerifyOTP}
          disabled={!otp || otp?.length < 6 || loading}
        >
          {loading ? (
            <div class='spinner-border text-success app-spinner' role='status'>
              <span class='sr-only'>Loading...</span>
            </div>
          ) : (
            "Verify"
          )}
        </button>
      </div>
    </div>
  );
};
