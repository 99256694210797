import { NewHeader } from "../../components/new-header";
import BlogImg from "../../assets/Group 3719@2x.png";
import { NewFooter } from "../../components/new-footer";
import { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Loading } from "../../components/loading-screen";
import { Pagination } from "../../components/pagination";

export function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [mostRecentBlog, setMostRecentBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    pages: 0,
    limit: 6,
    currentPage: 0,
  });

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchBlogs = async () => {
    try {
      setLoading(true);
      if (!mostRecentBlog) {
        let latestBlogPost = await client.getEntries({
          "sys.contentType.sys.id": "blog",
          order: "sys.createdAt",
          limit: 1,
        });
        setPaginationOptions({
          ...paginationOptions,
          pages: latestBlogPost.total,
        });
        latestBlogPost = latestBlogPost.items.map((item) => ({
          title: item.fields.blogTitle,
          _id: item.sys.id,
          image: item.fields.blogSourceImageLink,
          url: item.fields.blogLink,
        }));
        setMostRecentBlog(latestBlogPost[0]);
      }
      const res = await client.getEntries({
        "sys.contentType.sys.id": "blog",
        limit: paginationOptions.limit,
        skip: !paginationOptions.currentPage
          ? paginationOptions.currentPage
          : (paginationOptions.currentPage - 1) * paginationOptions.limit,
      });
      const blogs = res.items.map((item) => ({
        title: item.fields.blogTitle,
        image:
          item.fields.blogSourceImageLink ||
          item?.fields?.blogImage?.fields?.file?.url ||
          "",
        url: item.fields.blogLink,
        _id: item.sys.id,
      }));
      setBlogs(blogs);
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      setHasError(true);
    }
  };

  useEffect(() => {
    if (paginationOptions.currentPage < 1) return;
    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOptions.currentPage]);

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_blog'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <h3 className='mobile'>Newsroom</h3>
            <div className='row introduction'>
              <div className='col-md-6 col-sm-12'>
                <h3>Newsroom</h3>
                <h5>{mostRecentBlog?.title}</h5>
                <p>{mostRecentBlog?.description}</p>
                <a
                  className='td-btn-link'
                  href={mostRecentBlog?.url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='td-btn'>
                    <span>Find out more</span>
                    <span className='chevron chevron-right' />
                  </button>
                </a>
              </div>
              <div className='col-md-6 col-sm-12'>
                <img src={BlogImg} alt='blog-img-1' className='img-fluid' />
              </div>
            </div>
            <div className='blog_page'>
              <div className='blog-posts'>
                {blogs.map((blog, key) => (
                  <a
                    className='td-btn-link wrapper-link'
                    href={blog?.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <div className='post' key={key}>
                      <div className='_content'>
                        <div className='img_container'>
                          <img src={blog?.image} alt={`blog_img_${key}`} />
                        </div>
                        <p>{blog.title}</p>
                        <a
                          className='td-btn-link'
                          href={blog?.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <button className='td-btn'>
                            <span>Read more</span>
                            <span className='chevron chevron-right' />
                          </button>
                        </a>
                      </div>
                      {/* <div className='photo-img'>
                        <img src={PostImg} alt='post-1' className='img-fluid' />
                      </div> */}
                      <div className='chevron chevron-right _mobile' />
                    </div>
                  </a>
                ))}
              </div>
              <Pagination
                pages={paginationOptions.pages}
                limit={paginationOptions.limit}
                setCurrentPage={(newPage) =>
                  setPaginationOptions({
                    ...paginationOptions,
                    currentPage: newPage,
                  })
                }
              />
              <NewFooter />
            </div>
          </div>
        </div>
      </div>
    </Loading>
  );
}
