import { SUPPORTED_COUNTRIES } from "../utils/functions";

export function WebsiteFooter({
  isColored,
  countryCode = SUPPORTED_COUNTRIES.GB,
}) {
  return (
    <div className={`trade-uk-landing__footer ${isColored ? "colored" : ""}`}>
      <a className='' href='/'>
        <img src='/images/logo-alt.svg' width='140' alt='' />
      </a>
      <div
        className='trade-uk-landing__footer-connect'
        data-bs-spy='scroll'
        id='getapp'
      >
        <div className='download-app'>
          <h3>Download the TradeDepot App</h3>
          <div className='devices-downloads'>
            <a
              href={process.env.REACT_APP_PLAY_STORE_DOWNLOAD_URL}
              target='_blank'
              rel='noreferrer'
            >
              <img src='/images/google-play-badge.png' alt='' />
            </a>
            <a
              href={process.env.REACT_APP_APPLE_STORE_DOWNLOAD_URL}
              target='_blank'
              rel='noreferrer'
            >
              <img src='/images/ios-app-badge.svg' alt='' />
            </a>
          </div>
        </div>
        {isColored ? (
          <div className='sub-links'>
            <ul>
              <li>
                <a href={`/terms?country=${countryCode}`}>Terms & Conditions</a>
              </li>
              <li>
                <a href={`/privacy?country=${countryCode}`}>Privacy Policy</a>
              </li>
              <li>
                <a href={`/returns?country=${countryCode}`}>
                  Shipping & Returns
                </a>
              </li>
            </ul>
          </div>
        ) : null}
        <div className='socials'>
          <p>Connect with us</p>
          <div className='_icons'>
            <a
              href='https://twitter.com/tradedepotHQ'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa fa-twitter'></i>
            </a>
            <a
              href='http://www.instagram.com/tradedepot.wholesale'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa fa-2x fa-instagram'></i>
            </a>
            <a
              href='http://www.facebook.com/tradedepot.wholesale'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa fa-2x fa-facebook'></i>
            </a>

            <a
              href='https://www.linkedin.com/company/tradedepot/'
              target='_blank'
              rel='noreferrer'
            >
              <i className='fa fa-2x fa-linkedin'></i>
            </a>
          </div>
        </div>
      </div>
      <div className='trade-uk-landing__footer-copyright'>
        {isColored ? null : <a href={`/terms`}>Terms & Privacy</a>}
        <p> &copy;TradeDepot. All rights reserved.</p>
      </div>
    </div>
  );
}
