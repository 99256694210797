import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SHOP,
  handleRetrieveUser,
  printErrorMessage,
} from "../utils/functions";
import { StatusRenderer } from "./error-screen";

export function VerifyPhone() {
  const [loading, setLoading] = useState(false);
  const [formBody, setFormBody] = useState({
    phoneNumber: "",
    amount: null,
    token: "",
  });
  const [verifiedAgent, setVerifiedAgent] = useState(null);
  const [isTokenSent, setIsTokenSent] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    message: "",
    title: "",
  });
  const navigate = useNavigate();
  const user = handleRetrieveUser();

  const handleInputValue = (key, value) => {
    return setFormBody({ ...formBody, [key]: value });
  };

  const handleVerifyAgentsNumber = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(
        // `https://ryv8zgxumb.execute-api.us-east-1.amazonaws.com/prod/get-agent?phoneNumber=2347049737891`,
        // `${
        //   process.env.REACT_APP_API_DRIVER_APP
        // }/get-agent?phoneNumber=${formBody.phoneNumber.substring(1)}`,
        `${
          process.env.REACT_APP_API_DRIVER_APP
        }/get-agent?phoneNumber=${formBody.phoneNumber.substring(1)}`,
        {
          headers: {
            "x-api-key": user.apiKey,
          },
        }
      );
      setVerifiedAgent(data.agent);
      handleSendOTP();
    } catch (error) {
      const { message, title } = printErrorMessage(error);
      setError({
        hasError: true,
        message,
        title,
        canRetry: true,
        retryText: "Try Again",
        retryFunction: () => setError({ hasError: false }),
      });
      setLoading(false);
    }
  };

  const handleSendOTP = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_DRIVER_APP}/charge-otp`,
        { amount: formBody.amount },
        { headers: { "x-api-key": user?.apiKey } }
      );
      setIsTokenSent(true);
      setLoading(false);
    } catch (error) {
      console.log("sending otp", error);
      const { message, title } = printErrorMessage(error);
      setError({
        hasError: true,
        message,
        title,
        canRetry: true,
        retryText: "Try resending OTP",
        retryFunction: () => {
          setError({ hasError: false });
          handleSendOTP();
        },
      });
      setLoading(false);
    }
  };

  const handlePayAgent = async () => {
    setLoading(true);
    try {
      const {
        data: { reference },
      } = await axios.post(
        `${process.env.REACT_APP_API_DRIVER_APP}/outlet-charge`,
        {
          amount: formBody.amount,
          token: formBody.token,
          phoneNumber: formBody.phoneNumber,
          extChannel: SHOP.MOBILE,
        },
        { headers: { "x-api-key": user?.apiKey } }
      );
      return navigate("/wholesale/confirm-payment", {
        state: {
          reference, // is this order reference?
          //     ngPayment: true,
          //     amount: formBody.amount,
          hasOrderReference: true,
        },
      });
    } catch (error) {
      console.log("making agent payment", error);
      const { message, title } = printErrorMessage(error);
      setError({ hasError: true, message, title });
      setLoading(false);
    }
  };

  return (
    <>
      <div className='trade-uk-checkout'>
        <div className='trade-uk-checkout_header'>
          <p>Phone Verification</p>
        </div>
      </div>
      {error.hasError ? (
        <StatusRenderer
          message={error.message}
          title={error.title}
          canRetry={error?.canRetry}
          retryText={error?.retryText}
          retryFunction={error?.retryFunction}
        />
      ) : (
        <div className='trade-uk_payment-verify-phone'>
          <h3>Verify phone number</h3>
          <h5>Enter your agent's phone number</h5>
          <div className='mt-3 mb-3'>
            <label>Enter Agent's Number</label>
            <input
              placeholder='+2348034392012'
              className='form-control'
              disabled={loading}
              type='number'
              onChange={({ target: { value } }) =>
                handleInputValue("phoneNumber", value)
              }
            />
            {verifiedAgent ? (
              <div className='verified-details'>
                <img src='../../images/success.svg' alt='' />
                <h2>
                  {verifiedAgent?.firstName} {verifiedAgent?.lastName}
                </h2>
              </div>
            ) : null}
          </div>
          <div className='mt-3 mb-3'>
            <label>Amount (₦)</label>
            <input
              placeholder='1000'
              disabled={loading}
              className='form-control'
              onChange={({ target: { value } }) =>
                handleInputValue("amount", Number(value))
              }
            />
          </div>
          {isTokenSent ? (
            <div className='mt-3 mb-3'>
              <label>Input OTP</label>
              <input
                className='form-control'
                onChange={({ target: { value } }) =>
                  handleInputValue("token", value)
                }
              />
            </div>
          ) : null}

          <br />
          <br />
          <br />
          <br />
          <button
            className='trade-uk-app-button'
            disabled={
              !formBody.phoneNumber.length || !formBody.amount || loading
            }
            onClick={isTokenSent ? handlePayAgent : handleVerifyAgentsNumber}
          >
            {loading ? (
              <div
                class='spinner-border text-success app-spinner'
                role='status'
              >
                <span class='sr-only'>Loading...</span>
              </div>
            ) : isTokenSent ? (
              "Pay Agent"
            ) : (
              "Verify Phone"
            )}
          </button>
        </div>
      )}
    </>
  );
}
