import React from "react";

export const LandingView = () => {
  return (
    <>
      <div className='trade-landing'>
        <div className='trade-landing__ban'>
          <div id='circular' className='circular-pattern'></div>
          <nav className='navbar navbar-expand-lg navbar-dark absolute-top opaque-navbar trade-landing__full'>
            <div className='container'>
              <a className='navbar-brand' href='/'>
                <img src='images/logo-alt.svg' width='200' alt='' />
              </a>
              <button
                className='navbar-toggler navbar-toggler-right'
                type='button'
                data-toggle='collapse'
                data-target='#navbarResponsive'
                aria-controls='navbarResponsive'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
              <div className='collapse navbar-collapse' id='navbarResponsive'>
                <ul className='navbar-nav ml-auto'>
                  <li className='nav-item'>
                    <a className='nav-link' href='https://blog.tradedepot.co/'>
                      Blog
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='https://app.tradedepot.co/'>
                      Login
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='typeform-share button trade-landing__ban__btn'
                      href='https://tradedepot.typeform.com/to/YpDE0w'
                      data-mode='drawer_right'
                      data-submit-close-delay='5'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Sign Up{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className='trade-landing__mobile'>
            <div className='container'>
              <div className='trade-landing__topnav'>
                <img src='images/logo-alt.svg' width='150' alt='' />
                <div id='mobile-nav'>
                  <a href='https://app.tradedepot.co/'>Login</a>
                  <a
                    href='https://tradedepot.typeform.com/to/YpDE0w'
                    className='mobile-sign-up'
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='content'>
            <div className='trade-landing__ban__title'>
              <div className='container'>
                <div className='row d-flex'>
                  <div className='col-lg-9'>
                    <h1 className='animate__animated animate__fadeInUp animate__delay-.5s'>
                      <span>Retail distribution</span>
                      <span>made simple</span>{" "}
                    </h1>
                    <p className='mt-3 animate__animated animate__fadeInUp animate__delay-1s'>
                      When you register as a supplier, TradeDepot becomes a
                      full-time distributor of your products. We buy and store
                      the inventory, and take care of everything from shipping
                      and pricing to customer service and returns.
                    </p>
                    <button
                      className='btn play-button animate__animated animate__fadeInUp animate__delay-1.5s'
                      data-toggle='modal'
                      data-target='#exampleModal'
                      type='button'
                    >
                      <span className='play-button__image'></span>
                      <span className='play-button__text'>Watch Video</span>
                    </button>
                    <a
                      className='typeform-share button trade-landing__ban__title__btn btn'
                      href='https://tradedepot.typeform.com/to/YpDE0w'
                      data-mode='drawer_right'
                      data-submit-close-delay='5'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div className='trade-landing__ban__patternBottom'></div>
            </div>

            <div className='trade-landing__ban__brand'>
              <div className='container'>
                <div className='row d-flex'>
                  <div className='col'>
                    <p>Trusted by the world's biggest brands</p>
                  </div>
                </div>

                <div className='row d-flex align-items-center'>
                  <div className='col-1'>
                    <img src='images/1.png' alt='' />
                  </div>
                  <div className='col-1'>
                    <img src='images/3.png' alt='' />
                  </div>
                  <div className='col-1'>
                    <img src='images/4.png' style={{ width: "80%" }} alt='' />
                  </div>
                  <div className='col-1'>
                    <a href='#brands-images' className='brand-link'>
                      See all{" "}
                      <span>
                        <i className='mdi mdi-arrow-down'></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='trade-benefits'
        style={{ paddingTop: "8rem", paddingRight: "4rem" }}
      >
        <div className='container'>
          <div className='row d-flex align-items-stretch'>
            <div className='col-md-5'>
              <div className='trade-benefits__right-side animate__animated animate__fadeInUp animate__delay-2s'>
                <div className='trade-benefits__main-side__block'>
                  <div className=''>
                    <h6>DISTRIBUTION</h6>
                    <h2>
                      Reach millions with <br />
                      TradeDepot
                    </h2>
                    <p>
                      Top consumer goods producers as well as importers and
                      local manufacturers use TradeDepot for a reason: the tens
                      of thousands of retailers we service every day
                    </p>

                    <a
                      href='https://tradedepot.typeform.com/to/YpDE0w'
                      data-mode='drawer_right'
                      data-submit-close-delay='5'
                      target='_blank'
                      className='btn typeform-share'
                      rel='noreferrer'
                    >
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='trade-benefits__img alt'>
                <div className='trade-benefits__widget animate__animated animate__fadeInDown animate__delay-4s'>
                  <img
                    src='./images/sample-widget-1.svg'
                    alt=''
                    className='d-none d-lg-flex'
                  />
                </div>
                <img
                  src='./images/sample-alt-alt.png'
                  alt=''
                  style={{ boxShadow: "none", borderRadius: "0" }}
                  className='animate__animated animate__zoomIn animate__delay-3s lady-img'
                />
                <div className='trade-benefits__widget-alt animate__animated animate__fadeInUp animate__delay-4s'>
                  <img
                    src='./images/sample-widget-2.svg'
                    alt=''
                    className='d-none d-lg-flex'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='trade-benefits__bottom animate__animated animate__fadeInUp animate__delay-4s'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className=''>
                  <div className='trade-benefits__right-side__block'>
                    <div className=''>
                      <img src='./images/TD-Web-Data-Driven.svg' alt='' />
                    </div>
                    <div className=''>
                      <h2>Data-Driven</h2>
                      <p>
                        Real-time trade intelligence from actual transactions
                        enable us get your products into the right retail stores
                        at the right time
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='trade-benefits__right-side__block'>
                  <img src='./images/TD-Web-Deliver-Smiles.svg' alt='' />
                  <h2>Deliver Smiles</h2>
                  <p>
                    We handle the inventory, shipping, returns and customer
                    service through our distribution facilities across Africa.
                    If you want to take care of the shipping yourself, we enable
                    that too
                  </p>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='trade-benefits__right-side__block'>
                  <img src='./images/TD-Web-Inventory-Finance.svg' alt='' />
                  <h2>Inventory Finance</h2>
                  <p>
                    We provide micro-loans to retailers to enable them buy more
                    of your products, backed by leading Financial Partners
                  </p>
                  <a
                    href='https://tradedepot.typeform.com/to/YpDE0w'
                    className='typeform-share'
                    data-mode='drawer_right'
                    data-submit-close-delay='5'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Learn More <i className='fa fa-arrow-right'></i>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='trade-benefits'
        style={{ background: "#f8f8fb", paddingTop: "8rem" }}
      >
        <div className='container'>
          <div className='row d-flex align-items-stretch'>
            <div className='col-md-8'>
              <div className='trade-benefits__img animate__animated animate__zoomIn animate__delay-3s'>
                <img
                  src='./images/sample.png'
                  alt=''
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className='col-md-4'>
              <div className='trade-benefits__right-side animate__animated animate__fadeInUp animate__delay-2s'>
                <div className='trade-benefits__main-side__block'>
                  <div className=''>
                    <h6>TRADE INSIGHTS</h6>
                    <h2>
                      Analyse performance vs your competition in real-time
                    </h2>
                    <p>
                      We verify your official email and immediately give you
                      access to view your company's performance in trade
                      benchmarked against peers. No fuss
                    </p>
                    <a
                      href='https://tradedepot.typeform.com/to/YpDE0w'
                      data-mode='drawer_right'
                      data-submit-close-delay='5'
                      target='_blank'
                      className='btn typeform-share'
                      rel='noreferrer'
                    >
                      Sign Up
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='trade-benefits__bottom animate__animated animate__fadeInUp animate__delay-4s'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className=''>
                  <div className='trade-benefits__right-side__block'>
                    <div className=''>
                      <img src='./images/TD-Web-See.svg' alt='' />
                    </div>
                    <div className=''>
                      <h2>See</h2>
                      <p>
                        Outlet volumes by product, channel, location and
                        category
                      </p>
                      <a
                        href='https://tradedepot.typeform.com/to/YpDE0w'
                        className='btn typeform-share'
                        data-mode='drawer_right'
                        data-submit-close-delay='5'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Get Started <i className='fa fa-arrow-right'></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='trade-benefits__right-side__block'>
                  <img src='./images/TD-Web-Analyse.svg' alt='' />
                  <h2>Analyze</h2>
                  <p>
                    Benchmark performance against category leaders and median
                    performance. See where your products rank
                  </p>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='trade-benefits__right-side__block'>
                  <img src='./images/TD-Web-Act.svg' alt='' />
                  <h2>Act</h2>
                  <p>
                    Run trade promotions and messaging campaigns, launch new
                    products and get samples to leading stores
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='trade-brands' id='brands-images'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='trade-brands__img'>
                <h2>Driving growth for amazing brands</h2>
                <p>
                  TradeDepot delivers an innovative route-to-market for the
                  leading consumer goods producers in Africa
                </p>
                <ul className='trade-brands__img__list'>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/1.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/3.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/4.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/5.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/9.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/7.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/10.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/11.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/12.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/19.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/8.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/13.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/20.png' alt='' />
                  </li>
                  <li
                    className='trade-brands__img__list__item'
                    style={{ marginLeft: "20px" }}
                  >
                    <img src='images/15.png' style={{ width: "25%" }} alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/16.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/17.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item'>
                    <img src='images/18.png' alt='' />
                  </li>
                  <li className='trade-brands__img__list__item d-none d-lg-block'>
                    <img src='images/2.png' alt='' />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='trade-footer'>
        <div className='trade-footer__header'>
          <div className='container'>
            <div className='row d-flex'>
              <div className='col-lg-12 text-left'>
                <div
                  className=''
                  style={{
                    borderBottom: "0.5px solid #152c531f",
                    paddingBottom: "2rem",
                  }}
                >
                  <h1>Get started today</h1>
                  <p>
                    Put your products on the shelves of the tens of thousands of
                    retailers we service every day
                  </p>
                  <a
                    className='typeform-share button trade-footer__header-btn mr-3'
                    href='https://tradedepot.typeform.com/to/YpDE0w'
                    data-mode='drawer_right'
                    data-submit-close-delay='5'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Contact Us{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='trade-footer__content'>
          <div className='trade-footer__pattern'></div>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 pb-5'>
                <p>
                  TradeDepot enables factory-to-retail distribution for consumer
                  goods companies.
                </p>
                <a
                  href='https://www.facebook.com/tradedepot.co'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa fa-2x fa-facebook-square'></i>
                </a>
                <a
                  href='https://twitter.com/tradedepotHQ'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa fa-2x fa-twitter-square'></i>
                </a>
                <a
                  href='https://www.linkedin.com/company/tradedepot/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa fa-2x fa-linkedin-square'></i>
                </a>
                <a
                  href='https://www.instagram.com/tradedepot.co/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fa fa-2x fa-instagram'></i>
                </a>
              </div>
              <div className='col-lg-2 pb-5'></div>
              <div className='col-lg-3 pb-5 pd-c'>
                <h6>RESOURCES</h6>
                <a href='/careers'>Careers</a>
                <br />
                <a href='/culture'>Culture</a>
                <br />
                <a href='https://trends.tradedepot.co/'>Trends</a>
                <br />
                <a href='https://blog.tradedepot.co/'>Blog</a>
                <br />
              </div>
              <div className='col-lg-3'>
                <h6>CONTACT US</h6>
                <p>
                  3/4 Adewunmi Industrial Estate, <br />
                  Kudirat Abiola Way, Oregun, Lagos.
                </p>
                <p>
                  1390 Market St, Suite 200, <br />
                  CA 94102 San Francisco.
                </p>
                <p>
                  <b>Email:</b> ​
                  <a href='mailto:hello@tradedepot.co'>hello@tradedepot.co</a>
                  <br />
                  <b>Phone:</b> 0700 999 0000
                </p>
              </div>
            </div>

            <div className='trade-footer__line'>
              © {new Date().getFullYear()} TradeDepot, Inc. All rights reserved.
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
            <div className='modal-body'>
              <iframe
                title="'you-tube-landing"
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/8LDgkVw57l8'
                frameborder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
