import React from "react";
import { CountryFilterPolicies } from "./components/country-filter-policies";
import { SUPPORTED_COUNTRIES } from "./utils/functions";
import { WebsiteFooter } from "./components/website-footer";
import { WebsiteHeader } from "./components/website-header";

export function PrivacyPolicies() {
  const qParameters = new URLSearchParams(window.location.search);
  const countryCode = qParameters.get("country") || SUPPORTED_COUNTRIES.GLOBAL;

  return (
    <>
      <div className=''>
        <div className='trade-uk-landing__intro'>
          <WebsiteHeader />
        </div>
        <div className='trade-uk-terms'>
          <p className='trade-uk-terms__title'>Privacy Policies</p>
          <div className='container'>
            <CountryFilterPolicies countryCode={countryCode} page='privacy' />
            {countryCode === SUPPORTED_COUNTRIES.NG ? (
              <NGPrivacyPolicies />
            ) : (
              // ) : countryCode === SUPPORTED_COUNTRIES.GH ? (
              //   <GHPrivacyPolicies />
              <UKPrivacyPolicies />
            )}
          </div>
        </div>
        <WebsiteFooter isColored={true} countryCode={countryCode} />
      </div>
    </>
  );
}

export const UKPrivacyPolicies = () => {
  return (
    <div className='trade-uk-terms__content'>
      <h3>1. INTRODUCTION</h3>
      <p>
        1.1 We take your privacy seriously. This Privacy Policy (together with
        our Terms and Conditions) sets out details about your privacy rights and
        how we gather, use and share your personal data – including the personal
        data we already hold about you now and any further personal data we
        might collect about you, either from you or from a third party when you
        use the TradeDepot Wholesale Website and Mobile app or become a customer
        or supplier of TradeDepot Wholesale.
        <br />
        <br />
        1.2 If you have any questions about how we use your personal data,
        please contact{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
      </p>
      <h3>2. About Us</h3>
      <p>
        2.1. TradeDepot Wholesale is operated by TradeDepot, the trading style
        of Frontier Africa Trading Co Ltd, a company registered in England,
        whose registered address is at 200 Brook Drive, Reading, United Kingdom
        RG2 6UB. Our company registration number is 14788482.
        <br />
        <br />
        2.2 TradeDepot is referred to as 'we', 'us' or 'our' in this Privacy
        Policy. Any use of the above terminology or other words in the singular,
        plural, capitalisation and/or he/she or they, are taken as
        interchangeable and therefore as referring to same.
      </p>
      <h3>3. Your Privacy Rights</h3>
      <p>
        3.1 In addition to the right to be informed about how we use your
        personal data (as set out in this Privacy Policy), you have various
        other rights in respect of the personal data we hold about you – these
        are set out in more detail below. If you wish to exercise any of these
        rights, please contact{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
        :
        <ul>
          <li>
            <b>Right to object</b>: You can object to our processing of your
            personal data where we are relying on a legitimate interest (or
            those of a third party) and there is something about your particular
            situation which makes you want to object to processing on this
            ground. You also have the right to object where we are processing
            your personal data for direct marketing purposes. Please contact us
            as noted above, providing details of your objection.
          </li>
          <li>
            <b>Access to your personal data</b>: You can request access to a
            copy of your personal data that we hold, along with information on
            what personal data we use, why we use it, who we share it with, how
            long we keep it for and whether it has been used for any automated
            decision-making.
          </li>
          <li>
            <b>Right to withdraw consent:</b>: If you have given us your consent
            to use your personal data, you can withdraw your consent at any
            time. In particular, if you have given us consent to send you
            marketing emails, you can withdraw your consent by clicking the
            "unsubscribe" link in any marketing email which you receive.
          </li>
          <li>
            <b>Rectification</b>: You can ask us to change or complete any
            inaccurate or incomplete personal data held about you.
          </li>
          <li>
            <b>Erasure</b>: You can ask us to delete your personal data where it
            is no longer necessary for us to use it, you have withdrawn consent,
            or where we have no lawful basis for keeping it. The account
            deletion process can be initiated under account settings on our
            mobile and web applications.
          </li>
          <li>
            <b>Portability</b>: You can ask us to provide you or a third party
            with some of the personal data that we hold about you in a
            structured, commonly used, electronic form, so it can be easily
            transferred.
          </li>
          <li>
            <b>Restriction</b>: You can ask us to restrict the personal data we
            use about you where you have asked for it to be erased or where you
            have objected to our use of it.
          </li>
          <li>
            <b>No automated decision-making</b>: Automated decision-making takes
            place when an electronic system uses personal data to make a
            decision without human intervention. You have the right not to be
            subject to automated decisions that will create legal effects or
            have a similar significant impact on you, unless you have given us
            your consent, it is necessary for a contract between you and us or
            is otherwise permitted by law. You also have certain rights to
            challenge decisions made about you. We do not currently carry out
            automated decision-making in relation to customers and suppliers,
            but we will notify you by updating this Privacy Policy if this
            changes.
          </li>
        </ul>
        3.2 We may need to verify your identity in order to process your request
        and ask you to provide valid identification documents to allow us to do
        so. Please note that we might use a third party to collect and validate
        your identification documents.
      </p>
      <h3>4. Information We Collect About You</h3>
      <p>
        Information you give us
        <br />
        <br />
        4.1 When you become a customer and register for an online customer
        account, we collect and use the following:
        <br />
        <ul className='alphas'>
          <li>full name;</li>
          <li>date of birth;</li>
          <li>
            home and business address, e-mail address and contact details;
          </li>
          <li> gender;</li>
          <li>
            a copy of your of Government issued id, proof of residency and a
            selfie of you;
          </li>
          <li>
            information about your business, business partners, beneficial
            owners or directors in which case you must ensure that you have
            permission to do this.
          </li>
        </ul>
        Other Information we collect
        <br />
        <br />
        4.2 We also collect and use the following personal data about you
        indirectly:
        <br />
        <ul className='alphas'>
          <li>
            if you are a credit customer at TradeDepot Wholesale, information
            obtained from any credit checks or other checks we are required to
            carry out for legal and regulatory purposes (see the section on
            'Anti Money Laundering/ Sanctions' below for further information);
          </li>
          <li>
            in order to administer the Website and understand how the Website is
            used, we collect technical information including your IP address,
            your login information, products you have viewed or searched,
            browser type, plug-ins you use, operating systems and platforms,
            URL, page response times, download errors, length of visits to
            certain pages, page interaction information, methods used to browse
            away from a page;
          </li>
          <li>
            information collected through the App, such as your purchases and
            account balance and geo-location data that identifies your location
            within the UK (you can change your settings at any time);
          </li>
          <li> any phone number used to contact us;</li>
        </ul>
      </p>
      <h3>5. How We Use Your Personal Data and Legal Basis</h3>
      <p>
        5.1 We use personal data held about you for the following purposes in
        line with our legitimate interests to administer the Website and the
        App, to engage with users when they request our products and services,
        and provide us with products and services and to protect and exercise
        our rights as a business:
        <br />
        <ul className='alphas'>
          <li>to respond to your queries;</li>
          <li>
            to manage any customer trading account with us and update the
            records we hold about you from time to time;
          </li>
          <li>
            for customers who hold credit accounts, to carry out credit checks;
          </li>
          <li>
            to provide you with your goods and information about your goods and
            our services and to provide you with information via post, email,
            telephone or SMS about other goods and services we offer that are
            similar to those you have already purchased or enquired about and
            that may be of interest to you, to make suggestions and
            recommendations to you about goods or services that may interest you
            (for further information, see the section on 'Keeping You Up to
            Date' below);
          </li>
          <li>
            to analyse your purchases and purchase behaviour so we can provide a
            better service to you and to keep our website safe and secure;
          </li>
          <li>
            we may anonymise the data we hold about you or your business and
            share it with our suppliers for their own reporting or marketing
            activities;
          </li>
          <li>
            if false or inaccurate information is provided and fraud is
            suspected or identified then this will be recorded and details will
            be passed to fraud prevention agencies. Law enforcement agencies may
            access and use this information. The information may also be used to
            prevent theft, fraud and money laundering for example, when checking
            details for customer registrations, credit applications or managing
            credit accounts;
          </li>
          <li>to administer events and hospitality.</li>
        </ul>
        5.2. We will also use your personal data, for example, your name,
        address and contact details, to carry out our contractual obligations to
        provide you with products or services which you have ordered or
        purchased from us and to contact you in relation to such products and
        services.
        <br />
        <br />
        5.3. As we are a regulated business, we are required by law to verify
        the identity of our customers and suppliers and carry out Anti Money
        Laundering and Sanctions checks.
        <br />
        <br />
        5.4 We will process your personal data, including your name, address and
        identity verification documents, for the purposes of preventing money
        laundering or terrorist financing, as it is necessary in order to
        prevent unlawful acts and for the purposes of meeting regulatory
        requirements.
        <br />
        <br />
        5.5 We are legally permitted to hold this information for no more than
        five years from the end of your business relationship with TradeDepot.
      </p>
      <h3>6. Special Categories of Personal Data</h3>
      <p>
        6.1 Special protection is given to certain kinds of personal data that
        is particularly sensitive. This is information about your health status,
        racial or ethnic origin, political views, religious or similar beliefs,
        sex life or sexual orientation, genetic or biometric identifiers, trade
        union membership ('special categories of personal data') or about your
        criminal convictions or offences.
        <br />
        <br />
        6.2 Where we collect any special categories of personal data, we will
        take appropriate steps to ensure that we have explicit consent or
        another legal basis to hold, use and retain the information. In
        particular, we collect special category personal data in relation to
        Anti Money Laundering and Sanctions checks and our legal basis for using
        this personal data is as set out at 5 above.
      </p>
      <h3>7. Disclosure of Your Personal Data</h3>
      <p>
        7.1 We may share your personal data in the following scenarios. Unless
        stated otherwise, we either do this in our in line with our legitimate
        interests to provide you with and engage with you in relation to our
        goods and services, the commercial interests of our suppliers, to
        generate legitimate business interest or for contractual purposes:
        <br />
        <ul className='alphas'>
          <li>
            selected third parties for the performance of our contractual
            obligations with you such as Delivery companies;
          </li>
          <li>
            with our suppliers for their supply chain management purposes (for
            example, your business name, address and purchase history), but not
            for their own marketing purposes unless you have indicated that you
            wish to receive such marketing;
          </li>
          <li>
            sub-contractors we may use in technical, payment and delivery
            services, events and hospitality;
          </li>
          <li>
            where you have agreed to receive marketing emails from us, we need
            to pass your personal data on to our service providers who help us
            with these marketing activities;
          </li>
          <li>
            analytics and search engine providers that assist us with
            improvement and optimisation of our site who may anonymise the
            information and send on to third parties for statistical purposes;
          </li>
          <li>
            credit reference agencies and other background check providers for
            Anti Money Laundering/ Sanctions purposes (see the section on 'Anti
            Money Laundering/ Sanctions' above for further information); and
          </li>
        </ul>
        7.2 Occasionally, we may also share personal data with relevant third
        parties:
        <br />
        <ul className='alphas'>
          <li>where you have given us your consent to do so;</li>
          <li>where we sell or buy any business or assets;</li>
          <li>
            if we are under a duty to disclose or share your personal data to
            comply with a legal obligation;
          </li>
          <li>
            for law enforcement purposes, including the prevention or detection
            of crime;
          </li>
          <li>
            to enforce or apply our Terms and Conditions and Shipping and
            Returns policy; or
          </li>
          <li>
            where it is fair and reasonable for us to do so in the
            circumstances.
          </li>
        </ul>
      </p>
      <h3>8. Where We Store Your Personal Data</h3>
      <p>
        8.1 We may need to transfer your information outside the UK to service
        providers, agents, subcontractors and regulatory authorities in
        countries where data protection laws may not provide the same level of
        protection as those in the European Economic Area ("EEA"), such as the
        USA.
        <br />
        <br />
        8.2 We will only transfer your personal data outside the EEA where
        either:
        <br />
        <ul className='alphas'>
          <li>
            the transfer is to a country which the EU Commission has decided
            ensures an adequate level of protection for your personal data; or
          </li>
          <li>
            we have put in place our own measures to ensure adequate security as
            required by data protection law. These measures include ensuring
            that your personal data is kept safe by carrying out strict security
            checks on our overseas partners and suppliers, backed by strong
            contractual undertakings approved by the relevant regulators such as
            the EU style model clauses. Some US providers may also be certified
            under the EU-US Privacy Shield which confirms they have appropriate
            measures in place to ensure the protection of your data.
          </li>
        </ul>
        8.3 If you would like further information, please contact{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
        .
      </p>
      <h3>9. Keeping You Up To Date</h3>
      <p>
        9.1 If you are a customer, we will communicate relevant news and
        information about products and services similar to those you have
        purchased or enquired about on the App, by post, email, telephone, SMS
        or other electronic messages, unless you tell us that you do not wish to
        receive them.
        <br />
        <br />
        9.2 You can unsubscribe at any time by clicking the
        <b>"unsubscribe"</b> link in any marketing email which you receive.
      </p>
      <h3>10. How Long We Hold Your Personal Data</h3>
      <p>
        10.1 We will not retain your personal data for any longer than is
        necessary for our purposes, including for the purposes of satisfying any
        legal, accounting or reporting requirements. If you have any questions
        about data retention then please email{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>
        .
        <br />
        <br />
        10.2 Where we have been using your personal data to provide you with
        marketing, we will remove you from our marketing list if you ask us to
        do so, but we may still need to keep your information for ongoing
        contractual purposes if you continue to be our customer and for legal,
        accounting and regulatory reporting reasons.
      </p>
      <h3>11. Right to Complain</h3>
      <p>
        11.1. You can make a complaint to us by contacting us via{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co
        </a>{" "}
        or to the data protection supervisory authority – in the UK, this is the
        Information Commissioner's Office, at{" "}
        <a href='https://ico.org.uk' target='_blank' rel='noreferrer'>
          https://ico.org.uk
        </a>
        .{" "}
      </p>
      <h3>12. Changes to this Privacy Policy</h3>
      <p>
        12.1. This Privacy Policy will be reviewed periodically and we will
        update it if we make any material changes to the manner in which we
        process and use your personal data.
      </p>
    </div>
  );
};

export const NGPrivacyPolicies = () => {
  return (
    <div className='trade-uk-terms__content'>
      <h3>1. Who We Are</h3>
      <p>
        1.1. TradeDepot Limited (“TradeDepot” or “Us” or “We” or “Our”) is a
        retail technology company based in Lagos, Nigeria, with Our registered
        address at{" "}
        <b>3/4 Adewunmi Industrial Estate, Kudirat Abiola Way, Oregun, Lagos</b>
        . Our business involves the supply of fast-moving consumer goods and
        certain financial services to small and medium-scale businesses (Our
        “Services”). Our Privacy Policy has been prepared to meet the
        requirements of the Nigeria Data Protection Act (the “NDPA”). We are
        committed to protecting the confidentiality and privacy of personally
        identifiable information (“Personal Data”) entrusted to Us through this
        website and/or Our mobile application (“Website”). Our Privacy Policy,
        together with our Terms of Use, explains when and why We collect
        Personal Data, how We use the Personal Data, conditions under which We
        may disclose Personal Data to others and the efforts We take to keep
        Personal Data secure. The terms ‘you’ and ‘your’ means you as an
        individual accessing this Website, and applying for or utilising Our
        Services. Where We make decisions on how Personal Data is used in
        connection with Our Services, We are acting as a Data Controller and
        will be responsible for the obligations of a Data Controller under the
        NDPA in connection with the processing of Personal Data. For example, We
        use this Privacy Policy and other notices to provide you with
        information about Our use of Personal Data, as required by the NDPA.
        Where We only use Personal Data requested by other Data Controllers, We
        would be acting as Data Processors and those other Data Controllers are
        similarly responsible for the obligations of a Data Controller under the
        NDPA in connection with the processing of those categories of Personal
        Data. If you are using Our Services through those other Data
        Controllers, you should contact them if you have questions or concerns
        about the processing of your Personal Data or compliance with the NDPA
        and other applicable laws.
        <br />
        <br />
        1.2. We may update and modify this Privacy Policy from time to time, so
        please do return to the Website and review this Privacy Policy
        regularly. Unless otherwise stated, any updates to this Privacy Policy
        become effective when We post the updates on the Website. Your continued
        use of the Website for Our Services following an update to the Privacy
        Policy means that you are aware of the updated Privacy Policy and have
        no objections to any such updated Privacy Policy. Please read the
        following carefully to understand Our views and practices regarding the
        processing of your Personal Data.
      </p>
      <h3>2. Personal Data We Collect</h3>
      <p>
        2.1. We may collect the following information from you as part of the
        process of providing the Services. The categories of Personal Data We
        may collect from you includes:
        <br />
        <br />
        2.1.1. Basic Personal Data which may include your name, date of birth,
        and gender;
        <br />
        <br />
        2.1.2. Information that you provide by filling in forms on the Website;
        <br />
        <br />
        2.1.3. Your contact details such as postal address, home address,
        delivery address, email address, and phone number;
        <br />
        <br />
        2.1.4. Banking and payment card details, including account number,
        National Identity Number(NIN) and Bank Verification Number(BVN);
        <br />
        <br />
        2.1.5. Verification information such as past addresses and proof of
        income and expenditure, including national identification number;
        <br />
        <br />
        2.1.6. Details of any loan applied for, such as the amount, preferred
        repayment period, and bank details for making repayments;
        <br />
        <br />
        2.1.7. Information We need to assess the affordability of the loan, such
        as your employment details and your monthly income;
        <br />
        <br />
        2.1.8. Identifiers assigned to your computer or other devices, including
        your Internet Protocol (IP) address and other information about your
        visits to the Website;
        <br />
        <br />
        2.1.9. Work details – such as job title, department, company name,
        company address, personal information of employees who may act as your
        agents, work email address, and office phone number;
        <br />
        <br />
        2.1.10. Log in details – such as username and password;
        <br />
        <br />
        2.1.11. Usage Information and Browsing History - such as usage metrics
        (including usage rates, occurrences of technical errors, diagnostic
        reports, settings preferences, content, and advertising interactions);
        <br />
        <br />
        2.1.12. Location Information. We may collect or infer your general
        location information when you use Our Services;
        <br />
        <br />
        2.1.13. We may also collect additional information involving your
        opinion of Our Services and your preferences regarding other services
        such as newsletter subscription;
        <br />
        <br />
        2.1.14. Any other Personal Data that you choose to provide on the
        Website or in your communications with Us. Please note, call recordings
        of conversations may be retained for monitoring and training purposes,
        except where bank card details are provided. When providing bank card
        details, the call recording will be paused and resumed once the card
        details have been fully provided.
        <br />
        <br />
        2.2. <b>Information that we may obtain from third parties</b>
        <br />
        <br />
        In some circumstances, We may collect Personal Data about you from Our
        partners or from publicly available websites to help Us better
        understand Our audience and enhance the relevance of Our content. We may
        collect Personal Data about you from third parties, such as:
        <br />
        <br />
        2.2.1. Some organisations and others with whom you have a relationship
        that provide or publish Personal Data related to you, such as from Our
        customers when they arrange access to Our Services for you or from
        others when they create, post, or submit user content on Our Services
        that may include your Personal Data;
        <br />
        <br />
        2.2.2. Professional or industry organisations and certification /
        licensure agencies that provide or publish Personal Data related to you;
        <br />
        <br />
        2.2.3. Third parties and affiliates who resell or integrate with Our
        Services.
        <br />
        <br />
        2.2.4. Service providers and business partners who work with Us in
        relation to Our Services and that We may utilise to deliver certain
        content, products, or services or to enhance your experience;
        <br />
        <br />
        2.2.5. Marketing, sales generation, and recruiting business partners;
        <br />
        <br />
        2.2.6. Credit bureaus and other similar agencies;
        <br />
        <br />
        2.2.7. Government agencies and others who release or publish public
        records.
        <br />
        <br />
        2.2.8. Other publicly or generally available sources, such as social
        media sites, public and online websites, open databases, the corporate
        affairs commission, and data in the public domain.
        <br />
        <br />
        2.2.9. If you own any asset, We may carry out a search at the relevant
        asset registry. Performing the search helps Us verify you and, in some
        cases, means We can lend higher amounts;
        <br />
        <br />
        2.2.10. A credit check will be carried out by licensed credit bureaus in
        Nigeria to view your credit history and financial commitments. If you
        advise of any other financial commitments not shown on the credit file,
        details will be required;
        <br />
        <br />
        2.2.11. We may also carry out a credit search if payments are missed or
        if We are unable to contact you;
        <br />
        <br />
        2.2.12. We may obtain information about complaints from a regulator, if
        you make a complaint.
        <br />
        <br />
        2.3.{" "}
        <b>
          Information that may be generated during the course of your
          relationship with us, for example:
        </b>
        <br />
        <br />
        2.3.1. Details of repayments that you have made or missed;
        <br />
        <br />
        2.3.2. Account reference details on Our system; and
        <br />
        <br />
        2.3.3. We may collect information about your interactions with the
        Website using cookies and similar technologies. Cookies are small files
        placed on your computer’s hard drive that enable the Website to identify
        your computer as you view different pages. Cookies allow websites and
        applications to store your preferences in order to present contents,
        options or functions that are specific to you. Like most interactive
        websites, Our Website may use cookies to enable the tracking of your
        activity for the duration of a session. We may also use cookies (and
        similar technologies) and analytics tools to collect information about
        you. This information can be used to improve the performance of the
        site, make advertising more relevant and enhance your user experience,
        such as Your location – We use cookies to display information that is
        most relevant to your location. Your usage – We use cookies to
        understand how Our customers use Our Website and interact with Our
        communications. For example, We use technology on Our Website, which
        record user movements, including page scrolling, clicks and text
        entered. (It does not record payment details.) This helps Us identify
        usability issues and improve the assistance We can provide to users and
        is also used for aggregated and statistical reporting purposes. Your
        device – We use cookies to understand what type of device you are using
        to show you the best version of the site. For instance, if you visit Our
        Website on a mobile device, Our technology will ensure that you see a
        version that is optimised for mobile viewing and interaction. Your
        engagement with advertisements – We use cookies to understand what
        advertisements you have been shown, or clicked on, to bill Our
        advertising partners, and to present you with advertisements that are
        more relevant to you
      </p>
      <h3>3. How We Use Personal Data</h3>
      <p>
        5.1. We may store and use your Personal Data for the following purposes:
        <br />
        <ul className='alphas'>
          <li>Search for and subscribe to Our Services.</li>
          <li>Use Our Services or otherwise interact with Us.</li>
          <li>Create or maintain a profile or account with Us.</li>
          <li>
            Purchase, use, or otherwise interact with content, products, or
            services from third-party providers who have a relationship with Us.
          </li>
          <li>Create, post, or submit user content on Our Services.</li>
          <li>Register for or attend one of Our events or locations.</li>
          <li>
            Improve features and website content, and analyse data to develop
            products and services.
          </li>
          <li>Request or sign up for information.</li>
          <li>
            Communicate with Us by phone, email, chat, in person, or otherwise.
          </li>
          <li>
            Complete a questionnaire, survey, support ticket, or other
            information request form.
          </li>
          <li>
            When you express an interest in working with Us or apply for a job
            or position with Us.
          </li>
          <li>
            Address any inappropriate use of Our Website. (as is necessary for
            compliance with Our legal obligations and/or as is necessary for Our
            legitimate interests).
          </li>
          <li>
            Prevent, detect and manage risk against fraud and illegal activities
            using internal and third-party screening tools. (as is necessary for
            compliance with Our legal obligations and/or as is necessary for Our
            legitimate interests).
          </li>
          <li>
            Verify your identity. (as is necessary for compliance with Our legal
            obligations and/or as is necessary for Our legitimate interests).
          </li>
          <li>
            Administering your application and loan agreement (as is necessary
            for the performance of a contract between you and Us and/or as is
            necessary for Our legitimate interests).
          </li>
          <li>
            Carrying out anti-fraud and anti-money laundering checks and
            verifying your identity (as is necessary for compliance with Our
            legal obligations and/or as is necessary for Our legitimate
            interests).
          </li>
          <li>
            Assessing financial risks, including by carrying out credit
            reference checks (as is necessary for the performance of a contract
            between you and Us and/or as is necessary for Our legitimate
            interests).
          </li>
          <li>
            Using your payment details to process payments relating to your loan
            repayments, and refunds (as is necessary for the performance of a
            contract between you and Us and/or as is necessary for Our
            legitimate interests);
          </li>
          <li>
            Communicating with you about your loan application/agreement,
            including responding to your enquiries (as is necessary for the
            performance of a contract between you and Us and/or as is necessary
            for Our legitimate interests);
          </li>
          <li>
            Administering debt recoveries, where you owe Us money under a
            contract or otherwise (as is necessary for the performance of a
            contract between you and Us and/or as is necessary for Our
            legitimate interests);
          </li>
          <li>
            Undertaking statistical analysis, including analysing your use of
            Our Website. This allows Us to develop new, or improve existing
            products and services (as is necessary for Our legitimate
            interests); and
          </li>
          <li>
            fulfilling Our obligations owed to a relevant regulator, tax
            authority or revenue service (as is necessary for compliance with
            Our legal obligations and/or as is necessary for Our legitimate
            interests).
          </li>
        </ul>
        5.2. Our “legitimate interests” as referred to above, include Our
        legitimate business purposes and commercial interests in operating Our
        business in a customer-focused, efficient, and sustainable manner, in
        accordance with all applicable legal and regulatory requirements. Your
        Personal Data is not used for the purposes of solely automated decision
        making or profiling.
      </p>
      <h3>4. How We Share Personal Data</h3>
      <p>
        4.1. We may share your Personal Data with Our associated companies and
        with certain third parties including:
        <br />
        <br />
        4.1.1. Third parties that assist Us in preparing or sending any
        communications to you, or to assist Us in connection with any of Our
        administrative or business functions, or in the provision of any of Our
        services to you, or to third parties acting as Our agents.
        <br />
        <br />
        4.1.2. We reserve the right to sell non-performing accounts. We will
        inform you if We do this.
        <br />
        <br />
        4.1.3. If We are unable to make contact with you, We may employ third
        party companies to trace your whereabouts and re-engage with Us. We may
        also employ third parties to enforce Our rights under any agreement with
        you.
        <br />
        <br />
        4.1.4. If We or Our assets are potentially to be acquired by a third
        party, or if We consider restructuring, Personal Data held by Us about
        Our customers (including borrowers and investors) will be one of the
        transferred assets and may be shared during the potential transaction or
        restructuring process or as part of a mergers & acquisition transaction.
        <br />
        <br />
        4.1.5. Anyone to whom We transfer or may transfer Our rights and duties
        under any agreement with you
        <br />
        <br />
        4.1.6. Legal and regulatory bodies, including fraud prevention agencies
        or when We have a legal obligation to do so.
        <br />
        <br />
        4.1.7. Our auditors, solicitors, professional advisors, sub-contractors
        who have agreed to treat your Personal Data as confidential
      </p>
      <h3>5. How We Protect your Personal Data</h3>
      <p>
        5.1. We employ all reasonable efforts to keep your Personal Data secure
        by taking appropriate technical and organisational measures against any
        unauthorised or unlawful processing of Personal Data and against its
        accidental loss, destruction or damage. We are committed to managing
        your Personal Data in line with the NDPA and best practices. We protect
        your Personal Data using physical, technical, and organisational
        measures to reduce the risks of loss, misuse, unauthorised access,
        disclosure and alteration. We also use industry-recommended security
        protocols to safeguard your Personal Data. Other security safeguards
        include, but are not limited to, data encryption, firewalls, and
        physical access controls to Our buildings and files.
      </p>
      <h3>6. Grounds for Processing your Personal Data</h3>
      <p>
        6.1. We may process your Personal Data for anyone of the lawful basis
        specified below in accordance with the NDPA. If you are unsure as to the
        lawful basis for processing any category of your Personal Data which We
        process, please reach out to Us via{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co.
        </a>{" "}
        <br />
        <br />
        6.1.1. the Data Subject has given consent to the processing of his/her
        Personal Data for one or more specific purposes;
        <br />
        <br />
        6.1.2. the processing is necessary for the performance of a contract to
        which the Data Subject is party or to take steps at the request of the
        Data Subject prior to entering into a contract;
        <br />
        <br />
        6.1.3. processing is necessary for compliance with a legal obligation to
        which We are subject;
        <br />
        <br />
        6.1.4. processing is necessary for legitimate interests pursued by Us or
        by a third party, except where such interests are overridden by the
        interests or fundamental rights and freedoms of the data subject, which
        require the protection of Personal Data; and
        <br />
        <br />
        6.1.5. processing is necessary for the performance of a task carried out
        in the public interest or in exercise of an official public mandate
        vested in Us.
      </p>
      <h3>7. Transfers of Personal Data outside Nigeria</h3>
      <p>
        7.1.The Personal Data that We collect from you may be transferred to,
        and stored at, a destination outside Nigeria. Where your Personal Data
        is transferred outside Nigeria, We will take all steps reasonably
        necessary to ensure that your Personal Data is transferred to a country
        on the Adequacy Whitelist published by the NDPC and, generally, in
        accordance with the provisions of the NDPA. We will take reasonable
        steps to use contractual terms which ensure that any such category of
        Personal Data is adequately protected or that the country to which the
        data is being transferred has reasonably adequate data protection laws
        in place.
      </p>
      <h3>8. Data Retention</h3>
      <p>
        8.1. We retain the Personal Data processed by Us for as long as is
        considered necessary for the purpose for which it was collected
        (including as required by applicable law or regulation). In accordance
        with Our retention policy, We may retain your Personal Data for a
        minimum of 6 (six) years from the end of Our business relationship with
        you. That is, when your account is closed or when all the outstanding
        sums payable to Us have been paid by you.
      </p>
      <h3>9. Security of Information</h3>
      <p>
        9.1. We are aware of the importance of protecting the Personal Data We
        collect from you and therefore We store data in encrypted form on
        computers and control access via secure web pages. We employ firewalls
        and other security technologies to protect Our servers from external
        attacks. We train Our employees in the proper handling of Personal Data
        and when We use other companies to provide services for Us, We require
        them to protect the confidentiality of Personal Data they receive.
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although We will do Our best to protect your Personal
        Data, We cannot guarantee the security of your data transmitted to Our
        Website; any transmission is at your own risk. Once We have received
        your Personal Data, We will use strict procedures and security features
        to try to prevent unauthorised access.
      </p>
      <h3>10. Your rights under the NDPA</h3>
      <p>
        10.1. Your Personal Data is protected under the NDPA and you have a
        number of data protection rights (explained below) which you can seek to
        exercise. Please contact Us via:{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co.
        </a>{" "}
        , if you wish to do so, or if you have any queries in relation to your
        rights. If you seek to exercise your rights, We will explain to you
        whether or not the right applies to you; these rights do not apply in
        all circumstances. Please be aware that if your request is manifestly
        unfounded or excessive, We may refuse to deal with your request in
        accordance with the provisions of the NDPA. We may also charge a
        reasonable fee for dealing with any such requests as requested by the
        NDPA.
        <br />
        <br />
        <ul className='alphas'>
          <li>
            <b>Right to access of Personal Data</b>
            <br />
            You have the right to access the Personal Data We hold about you and
            to obtain certain prescribed information about how We process it, as
            well as the source of the Personal Data We hold about you. This is
            more commonly known as submitting a ‘Data Subject Access Request’.
            We may request proof of your identity before sharing such
            information.
          </li>
          <br />
          <li>
            <b>Right to rectify or erase your Personal Data</b>
            <br />
            If you discover that the Personal Data We hold about you is
            inaccurate or incomplete, you have the right to have this
            information rectified (i.e. corrected). You may ask Us to delete
            information We hold about you in certain circumstances. This right
            is not absolute and only applies in particular circumstances. It may
            therefore not be possible for Us to delete the Personal Data We hold
            about you, for example, if We have an ongoing relationship or are
            required to retain information to comply with Our legal obligations
            or to exercise or defend legal claims.
          </li>
          <br />
          <li>
            <b>Right to Withdraw Consent</b>
            <br />
            Where We have relied on consent as the lawful basis to process your
            Personal Data, You may withdraw your consent by notifying Us
            immediately.
          </li>
          <br />
          <li>
            <b>Right to object to processing</b>
            <br />
            You may not object to the processing of your Personal Data when it
            is based on Our legitimate interests. You may also object to the
            processing of your Personal Data for the purposes of direct
            marketing and profiling, to the extent that such profiling is
            related to direct marketing.
          </li>
          <br />
          <li>
            <b>Right to restriction of processing</b>
            <br />
            In some cases, you may have the right to have the processing of your
            Personal Data restricted. For example, where you contest the
            accuracy of your Personal Data, it may be restricted until the
            accuracy is verified, or where the processing is unlawful but you
            object to it being deleted and request that it be restricted
            instead.
          </li>
          <br />
          <li>
            <b>Right to data portability</b>
            <br />
            You have the right to receive, move, copy, or transfer your Personal
            Data to a controller which is also known as ‘data portability’.
          </li>
          <br />
          <li>
            <b>Right to Complain</b>
            <br />
            Right to submit a complaint to the Nigeria Data Protection
            Commission (NDPC).
          </li>
          <br />
          <li>
            <b>Right to Object</b>
            <br />
            You have the right to object to any automated decision making made
            in connection with your Personal Data. Automated decision making are
            decisions based solely on automated processing by automated means,
            without any human involvement. We may still process your Personal
            Data by automated means, where such is (a) necessary for entering
            into or the performance of a contract between the data subject and a
            data controller; (b) authorised by a written law, which establishes
            suitable measures to safeguard your fundamental rights and freedoms;
            or where this is authorised by you. In that event, We would take
            reasonable steps to implement suitable measures to safeguard your
            fundamental rights, freedoms and interests.
          </li>
        </ul>
      </p>
      <h3>11. Complaints</h3>
      <p>
        11.1. If you have any complaints about Our use of Personal Data, please
        send an email with the details of your complaint to{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co.
        </a>{" "}
        or use the contact details above. We will investigate and respond to any
        complaints We receive. You also have the right to lodge a complaint with
        the NDPC. For further information on your rights and how to complain to
        the NDPC, please refer to the{" "}
        <a href='https://ndpc.gov.ng/' target='_blank' rel='noreferrer'>
          NDPC Website
        </a>
        .
      </p>
      <h3>12. HOW DO WE COLLECT AND STORE CONTACT INFORMATION?</h3>
      <p>
        12.1. The data controller is TradeDepot Limited, registered in Nigeria
        and doing business at No 3/4 Adewunmi Industrial Estate, Kudirat Abiola
        Way, Oregun, Lagos. If you have any questions about this Privacy Policy
        or how and why We process Personal Data, please contact Us at{" "}
        <a href='mailto:dataprotection@tradedepot.co'>
          dataprotection@tradedepot.co.
        </a>
      </p>
      <h4 className='last-modified'>Last Modified: 26th Aug., 2024</h4>
    </div>
  );
};

// export const GHPrivacyPolicies = () => {
//   return (
//     <div className='trade-uk-terms__content'>
//       <h3>1. Who We Are</h3>
//       <p>
//         1.1. Green Lion Company Limited (“Green Lion” or “Us” or “We” or “Our”)
//         is a retail technology company based in Ghana, with Our registered
//         address at P.O. Box MP 281 Ministries, Tema, Ghana. Our business
//         involves the supply of fast-moving consumer goods and certain financial
//         services to small and medium-scale businesses (Our “Services”). Our
//         Privacy Policy has been prepared to meet the requirements of the Data
//         Protection Act, 2012 (DPA). We are committed to protecting the
//         confidentiality and privacy of personally identifiable information
//         (“Personal Data”) entrusted to Us through this website and/or Our mobile
//         application (“Website”). Our Privacy Policy, together with our Terms of
//         Use, explains when and why We collect Personal Data, how We use the
//         Personal Data, conditions under which We may disclose Personal Data to
//         others and the efforts We take to keep Personal Data secure. The terms
//         ‘you’ and ‘your’ means you as an individual accessing this Website, and
//         applying for or utilising Our Services. Where We make decisions on how
//         Personal Data is used in connection with Our Services, We are acting as
//         a Data Controller and will be responsible for the obligations of a Data
//         Controller under the DPA in connection with the processing of Personal
//         Data. For example, We use this Privacy Policy and other notices to
//         provide you with information about Our use of Personal Data, as required
//         by the DPA. Where We only use Personal Data requested by other Data
//         Controllers, We would be acting as Data Processors and those other Data
//         Controllers are similarly responsible for the obligations of a Data
//         Controller under the DPA in connection with the processing of those
//         categories of Personal Data. If you are using Our Services through those
//         other Data Controllers, you should contact them if you have questions or
//         concerns about the processing of your Personal Data or compliance with
//         the DPA and other applicable laws. <br />
//         <br />
//         1.2. We may update and modify this Privacy Policy from time to time, so
//         please do return to the Website and review this Privacy Policy
//         regularly. Unless otherwise stated, any updates to this Privacy Policy
//         become effective when We post the updates on the Website. Your continued
//         use of the Website for Our Services following an update to the Privacy
//         Policy means that you are aware of the updated Privacy Policy and have
//         no objections to any such updated Privacy Policy. Please read the
//         following carefully to understand Our views and practices regarding the
//         processing of your Personal Data.
//       </p>
//       <h3>2. Personal Data We Collect</h3>
//       <p>
//         2.1. We may collect the following information from you as part of the
//         process of providing the Services. The categories of Personal Data We
//         may collect from you includes:
//         <br />
//         <br />
//         2.1.1. Basic Personal Data which may include your name, date of birth,
//         and gender;
//         <br />
//         <br />
//         2.1.2. Information that you provide by filling in forms on the Website;
//         <br />
//         <br />
//         2.1.3. Your contact details such as postal address, home address,
//         delivery address, email address, and phone number;
//         <br />
//         <br />
//         2.1.4. Banking and payment card details, and including account number;
//         <br />
//         <br />
//         2.1.5. Verification information such as past addresses and proof of
//         income and expenditure;
//         <br />
//         <br />
//         2.1.6. Details of any loan applied for, such as the amount, preferred
//         repayment period, and bank details for making repayments;
//         <br />
//         <br />
//         2.1.7. Information We need to assess the affordability of the loan, such
//         as your employment details and your monthly income;
//         <br />
//         <br />
//         2.1.8. Identifiers assigned to your computer or other devices, including
//         your Internet Protocol (IP) address and other information about your
//         visits to the Website;
//         <br />
//         <br />
//         2.1.9. Work details – such as job title, department, company name,
//         company address, personal information of employees who may act as your
//         agents, work email address, and office phone number;
//         <br />
//         <br />
//         2.1.10. Log in details – such as username and password;
//         <br />
//         <br />
//         2.1.11. Usage Information and Browsing History - such as usage metrics
//         (including usage rates, occurrences of technical errors, diagnostic
//         reports, settings preferences, content, and advertising interactions);
//         <br />
//         <br />
//         2.1.12. Location Information. We may collect or infer your general
//         location information when you use Our Services;
//         <br />
//         <br />
//         2.1.13. We may also collect additional information involving your
//         opinion of Our Services and your preferences regarding other services
//         such as newsletter subscription;
//         <br />
//         <br />
//         2.1.14. Any other Personal Data that you choose to provide on the
//         Website or in your communications with Us. Please note, call recordings
//         of conversations may be retained for monitoring and training purposes,
//         except where bank card details are provided. When providing bank card
//         details, the call recording will be paused and resumed once the card
//         details have been fully provided.
//         <br />
//         <br />
//         2.2. <b>Information that we may obtain from third parties</b>
//         <br />
//         <br />
//         In some circumstances, We may collect Personal Data about you from Our
//         partners or from publicly available websites to help Us better
//         understand Our audience and enhance the relevance of Our content. We may
//         collect Personal Data about you from third parties, such as:
//         <br />
//         <br />
//         2.2.1. Some organisations and others with whom you have a relationship
//         that provide or publish Personal Data related to you, such as from Our
//         customers when they arrange access to Our Services for you or from
//         others when they create, post, or submit user content on Our Services
//         that may include your Personal Data.
//         <br />
//         <br />
//         2.2.2. Professional or industry organisations and certification /
//         licensure agencies that provide or publish Personal Data related to you.
//         <br />
//         <br />
//         2.2.3. Third parties and affiliates who resell or integrate with Our
//         Services.
//         <br />
//         <br />
//         2.2.4. Service providers and business partners who work with Us in
//         relation to Our Services and that We may utilise to deliver certain
//         content, products, or services or to enhance your experience.
//         <br />
//         <br />
//         2.2.5. Marketing, sales generation, and recruiting business partners;
//         <br />
//         <br />
//         2.2.6. Credit bureaus and other similar agencies;
//         <br />
//         <br />
//         2.2.7. Government agencies and others who release or publish public
//         records.
//         <br />
//         <br />
//         2.2.8. Other publicly or generally available sources, such as social
//         media sites, public and online websites, open databases, the corporate
//         affairs commission, and data in the public domain.
//         <br />
//         <br />
//         2.2.9. If you own any asset, We may carry out a search at the relevant
//         asset registry. Performing the search helps Us verify you and, in some
//         cases, means We can lend higher amounts.
//         <br />
//         <br />
//         2.2.10. A credit check will be carried out by licensed credit bureaus in
//         Ghana to view your credit history and financial commitments. If you
//         advise of any other financial commitments not shown on the credit file,
//         details will be required.
//         <br />
//         <br />
//         2.2.11. We may also carry out a credit search if payments are missed or
//         if We are unable to contact you.
//         <br />
//         <br />
//         2.2.12. We may obtain information about complaints from a regulator, if
//         you make a complaint.
//         <br />
//         <br />
//         2.3.{" "}
//         <b>
//           Information that may be generated during the course of your
//           relationship with us, for example:
//         </b>
//         <br />
//         <br />
//         2.3.1. Details of repayments that you have made or missed;
//         <br />
//         <br />
//         2.3.2. Account reference details on Our system; and
//         <br />
//         <br />
//         2.3.3. We may collect information about your interactions with the
//         Website using cookies and similar technologies. Cookies are small files
//         placed on your computer’s hard drive that enable the Website to identify
//         your computer as you view different pages. Cookies allow websites and
//         applications to store your preferences in order to present contents,
//         options or functions that are specific to you. Like most interactive
//         websites, Our Website may use cookies to enable the tracking of your
//         activity for the duration of a session. We may also use cookies (and
//         similar technologies) and analytics tools to collect information about
//         you. This information can be used to improve the performance of the
//         site, make advertising more relevant and enhance your user experience,
//         such as Your location – We use cookies to display information that is
//         most relevant to your location. Your usage – We use cookies to
//         understand how Our customers use Our Website and interact with Our
//         communications. For example, We use technology on Our Website, which
//         record user movements, including page scrolling, clicks and text
//         entered. (It does not record payment details.) This helps Us identify
//         usability issues and improve the assistance We can provide to users and
//         is also used for aggregated and statistical reporting purposes. Your
//         device – We use cookies to understand what type of device you are using
//         to show you the best version of the site. For instance, if you visit Our
//         Website on a mobile device, Our technology will ensure that you see a
//         version that is optimised for mobile viewing and interaction. Your
//         engagement with advertisements – We use cookies to understand what
//         advertisements you have been shown, or clicked on, to bill Our
//         advertising partners, and to present you with advertisements that are
//         more relevant to you.
//       </p>
//       <h3>3. How We Use Personal Data</h3>
//       <p>
//         5.1. We may store and use your Personal Data for the following purposes:
//         <br />
//         <ul className='alphas'>
//           <li>Search for and subscribe to Our Services.</li>
//           <li>Use Our Services or otherwise interact with Us.</li>
//           <li>Create or maintain a profile or account with Us.</li>
//           <li>
//             Purchase, use, or otherwise interact with content, products, or
//             services from third-party providers who have a relationship with Us.
//           </li>
//           <li>Create, post, or submit user content on Our Services.</li>
//           <li>Register for or attend one of Our events or locations.</li>
//           <li>
//             Improve features and website content, and analyse data to develop
//             products and services.
//           </li>
//           <li>Request or sign up for information.</li>
//           <li>
//             Communicate with Us by phone, email, chat, in person, or otherwise.
//           </li>
//           <li>
//             Complete a questionnaire, survey, support ticket, or other
//             information request form.
//           </li>
//           <li>
//             When you express an interest in working with Us or apply for a job
//             or position with Us.
//           </li>
//           <li>
//             Address any inappropriate use of Our Website. (as is necessary for
//             compliance with Our legal obligations and/or as is necessary for Our
//             legitimate interests).
//           </li>
//           <li>
//             Prevent, detect and manage risk against fraud and illegal activities
//             using internal and third-party screening tools. (as is necessary for
//             compliance with Our legal obligations and/or as is necessary for Our
//             legitimate interests).
//           </li>
//           <li>
//             Verify your identity. (as is necessary for compliance with Our legal
//             obligations and/or as is necessary for Our legitimate interests).
//           </li>
//           <li>
//             Administering your application and loan agreement (as is necessary
//             for the performance of a contract between you and Us and/or as is
//             necessary for Our legitimate interests).
//           </li>
//           <li>
//             Carrying out anti-fraud and anti-money laundering checks and
//             verifying your identity (as is necessary for compliance with Our
//             legal obligations and/or as is necessary for Our legitimate
//             interests).
//           </li>
//           <li>
//             Assessing financial risks, including by carrying out credit
//             reference checks (as is necessary for the performance of a contract
//             between you and Us and/or as is necessary for Our legitimate
//             interests).
//           </li>
//           <li>
//             Using your payment details to process payments relating to your loan
//             repayments, and refunds (as is necessary for the performance of a
//             contract between you and Us and/or as is necessary for Our
//             legitimate interests);
//           </li>
//           <li>
//             Communicating with you about your loan application/agreement,
//             including responding to your enquiries (as is necessary for the
//             performance of a contract between you and Us and/or as is necessary
//             for Our legitimate interests);
//           </li>
//           <li>
//             dministering debt recoveries, where you owe Us money under a
//             contract or otherwise (as is necessary for the performance of a
//             contract between you and Us and/or as is necessary for Our
//             legitimate interests);
//           </li>
//           <li>
//             Undertaking statistical analysis, including analysing your use of
//             Our Website. This allows Us to develop new, or improve existing
//             products and services (as is necessary for Our legitimate
//             interests); and
//           </li>
//           <li>
//             fulfilling Our obligations owed to a relevant regulator, tax
//             authority or revenue service (as is necessary for compliance with
//             Our legal obligations and/or as is necessary for Our legitimate
//             interests).
//           </li>
//         </ul>
//         5.2. Our “legitimate interests” as referred to above, include Our
//         legitimate business purposes and commercial interests in operating Our
//         business in a customer-focused, efficient, and sustainable manner, in
//         accordance with all applicable legal and regulatory requirements. Your
//         Personal Data is not used for the purposes of solely automated decision
//         making or profiling.
//       </p>
//       <h3>4. How We Share Personal Data</h3>
//       <p>
//         4.1. We may share your Personal Data with Our associated companies and
//         with certain third parties including:
//         <br />
//         <br />
//         4.1.1. Third parties that assist Us in preparing or sending any
//         communications to you, or to assist Us in connection with any of Our
//         administrative or business functions, or in the provision of any of Our
//         services to you, or to third parties acting as Our agents.
//         <br />
//         <br />
//         4.1.2. We reserve the right to sell non-performing accounts. We will
//         inform you if We do this.
//         <br />
//         <br />
//         4.1.3. If We are unable to make contact with you, We may employ third
//         party companies to trace your whereabouts and re-engage with Us. We may
//         also employ third parties to enforce Our rights under any agreement with
//         you.
//         <br />
//         <br />
//         4.1.4. If We or Our assets are potentially to be acquired by a third
//         party, or if We consider restructuring, Personal Data held by Us about
//         Our customers (including borrowers and investors) will be one of the
//         transferred assets and may be shared during the potential transaction or
//         restructuring process or as part of a mergers & acquisition transaction.
//         <br />
//         <br />
//         4.1.5.Anyone to whom We transfer or may transfer Our rights and duties
//         under any agreement with you
//         <br />
//         <br />
//         4.1.6. Legal and regulatory bodies, including fraud prevention agencies
//         or when We have a legal obligation to do so.
//         <br />
//         <br />
//         4.1.7. Our auditors, solicitors, professional advisors, sub-contractors
//         who have agreed to treat your Personal Data as confidential.
//       </p>
//       <h3>5. How We Protect your Personal Data</h3>
//       <p>
//         5.1. We employ all reasonable efforts to keep your Personal Data secure
//         by taking appropriate technical and organisational measures against any
//         unauthorised or unlawful processing of Personal Data and against its
//         accidental loss, destruction or damage. We are committed to managing
//         your Personal Data in line with the DPA and best practices. We protect
//         your Personal Data using physical, technical, and organisational
//         measures to reduce the risks of loss, misuse, unauthorised access,
//         disclosure and alteration. We also use industry-recommended security
//         protocols to safeguard your Personal Data. Other security safeguards
//         include, but are not limited to, data encryption, firewalls, and
//         physical access controls to Our buildings and files.
//       </p>
//       <h3>6. Grounds for Processing your Personal Data</h3>
//       <p>
//         6.1. We may process your Personal Data for anyone of the lawful basis
//         specified below in accordance with the [.]DPA. If you are unsure as to
//         the lawful basis for processing any category of your Personal Data which
//         We process, please reach out to Us via{" "}
//         <a href='mailto:dataprotection@tradedepot.co'>
//           dataprotection@tradedepot.co.
//         </a>{" "}
//         <br />
//         <br />
//         6.1.1. the Data Subject has given consent to the processing of his/her
//         Personal Data for one or more specific purposes;
//         <br />
//         <br />
//         6.1.2. the processing is necessary for the performance of a contract to
//         which the Data Subject is party or to take steps at the request of the
//         Data Subject prior to entering into a contract;
//         <br />
//         <br />
//         6.1.3. processing is necessary for compliance with a legal obligation to
//         which We are subject;
//         <br />
//         <br />
//         6.1.4. processing is necessary for legitimate interests pursued by Us or
//         by a third party, except where such interests are overridden by the
//         interests or fundamental rights and freedoms of the data subject, which
//         require the protection of Personal Data; and
//         <br />
//         <br />
//         6.1.5. processing is necessary for the performance of a task carried out
//         in the public interest or in exercise of an official public mandate
//         vested in Us.
//       </p>
//       <h3>7. Transfers of Personal Data outside Ghana</h3>
//       <p>
//         7.1. The Personal Data that We collect from you may be transferred to,
//         and stored at, a destination outside Ghana. Where your Personal Data is
//         transferred outside Ghana, We will take all steps reasonably necessary
//         to ensure that such transfer of Personal Data is done in accordance with
//         the provisions of the DPA. We will take reasonable steps to use
//         contractual terms which ensure that any such category of Personal Data
//         is adequately protected or that the country to which the data is being
//         transferred has reasonably adequate data protection laws in place.
//       </p>
//       <h3>8. Data Retention</h3>
//       <p>
//         8.1. We retain the Personal Data processed by Us for as long as is
//         considered necessary for the purpose for which it was collected
//         (including as required by applicable law or regulation). In accordance
//         with Our retention policy, We may retain your Personal Data for a
//         minimum of 6 (six) years from the end of Our business relationship with
//         you. That is, when your account is closed or when all the outstanding
//         sums payable to Us have been paid by you.
//       </p>
//       <h3>9. Security of Information</h3>
//       <p>
//         9.1. We are aware of the importance of protecting the Personal Data We
//         collect from you and therefore We store data in encrypted form on
//         computers and control access via secure web pages. We employ firewalls
//         and other security technologies to protect Our servers from external
//         attacks. We train Our employees in the proper handling of Personal Data
//         and when We use other companies to provide services for Us, We require
//         them to protect the confidentiality of Personal Data they receive.
//         Unfortunately, the transmission of information via the internet is not
//         completely secure. Although We will do Our best to protect your Personal
//         Data, We cannot guarantee the security of your data transmitted to Our
//         Website; any transmission is at your own risk. Once We have received
//         your Personal Data, We will use strict procedures and security features
//         to try to prevent unauthorised access.
//       </p>
//       <h3>10. Your rights under the NDPA</h3>
//       <p>
//         10.1. Your Personal Data is protected under the DPA and you have a
//         number of data protection rights (explained below) which you can seek to
//         exercise. Please contact Us via:{" "}
//         <a href='mailto:dataprotection@tradedepot.co'>
//           dataprotection@tradedepot.co.
//         </a>{" "}
//         , if you wish to do so, or if you have any queries in relation to your
//         rights. If you seek to exercise your rights, We will explain to you
//         whether or not the right applies to you; these rights do not apply in
//         all circumstances. Please be aware that if your request is manifestly
//         unfounded or excessive, We may refuse to deal with your request in
//         accordance with the provisions of the DPA. We may also charge a
//         reasonable fee for dealing with any such requests as requested by the
//         DPA.
//         <br />
//         <br />
//         <ul className='alphas'>
//           <li>
//             <b>Right to access of Personal Data</b>
//             <br />
//             You have the right to access the Personal Data We hold about you and
//             to obtain certain prescribed information about how We process it, as
//             well as the source of the Personal Data We hold about you.. We may
//             request proof of your identity before sharing such information.
//           </li>
//           <br />
//           <li>
//             <b>Right to rectify or erase your Personal Data</b>
//             <br />
//             If you discover that the Personal Data We hold about you is
//             inaccurate or incomplete, you have the right to have this
//             information rectified (i.e. corrected). You may ask Us to delete
//             information We hold about you in certain circumstances. This right
//             is not absolute and only applies in particular circumstances. It may
//             therefore not be possible for Us to delete the Personal Data We hold
//             about you, for example, if We have an ongoing relationship or are
//             required to retain information to comply with Our legal obligations
//             or to exercise or defend legal claims.
//           </li>
//           <br />
//           <li>
//             <b>Right to Withdraw Consent</b>
//             <br />
//             Where We have relied on consent as the lawful basis to process your
//             Personal Data, You may withdraw your consent by notifying Us
//             immediately.
//           </li>
//           <br />
//           <li>
//             <b>Right to object to processing</b>
//             <br />
//             You may not object to the processing of your Personal Data when it
//             is based on Our legitimate interests. You may also object to the
//             processing of your Personal Data for the purposes of direct
//             marketing and profiling, to the extent that such profiling is
//             related to direct marketing.
//           </li>
//           <br />
//           <li>
//             <b>Right to restriction of processing</b>
//             <br />
//             In some cases, you may have the right to have the processing of your
//             Personal Data restricted. For example, where you contest the
//             accuracy of your Personal Data, it may be restricted until the
//             accuracy is verified, or where the processing is unlawful but you
//             object to it being deleted and request that it be restricted
//             instead.
//           </li>
//           <br />
//           <li>
//             <b>Right to exempt manual data</b>
//             <br />
//             You have the right to request that We rectify, block, destroy, or
//             erase any manual data that We have of you that is inaccurate or
//             incomplete.
//           </li>
//           <br />
//           <li>
//             <b>Right to Complain</b>
//             <br />
//             Right to submit a complaint to the Data Protection Commission (DPC).
//           </li>
//           <br />
//           <li>
//             <b>Right to Object</b>
//             <br />
//             You have the right to object to any automated decision making made
//             in connection with your Personal Data. Automated decision making are
//             decisions based solely on automated processing by automated means,
//             without any human involvement. We may still process your Personal
//             Data by automated means, where such is (a) necessary for entering
//             into or the performance of a contract between the data subject and a
//             data controller; (b) authorised by a written law, which establishes
//             suitable measures to safeguard your fundamental rights and freedoms;
//             or where this is authorised by you. In that event, We would take
//             reasonable steps to implement suitable measures to safeguard your
//             fundamental rights, freedoms and interests.
//           </li>
//         </ul>
//       </p>
//       <h3>11. Complaints</h3>
//       <p>
//         11.1. If you have any complaints about Our use of Personal Data, please
//         send an email with the details of your complaint to{" "}
//         <a href='mailto:dataprotection@tradedepot.co'>
//           dataprotection@tradedepot.co.
//         </a>{" "}
//         or use the contact details above. We will investigate and respond to any
//         complaints We receive. You also have the right to lodge a complaint with
//         the DPC. For further information on your rights and how to complain to
//         the DPC, please refer to the{" "}
//         <a
//           href='https://www.dataprotection.org.gh/'
//           target='_blank'
//           rel='noreferrer'
//         >
//           DPC Website
//         </a>
//         .
//       </p>
//       <h3>12. HOW DO WE COLLECT AND STORE CONTACT INFORMATION?</h3>
//       <p>
//         12.1. The data controller is Green Lion Company Limited, registered in
//         Ghana and doing business at P.O. Box MP 281 Ministries, Tema, Ghana. If
//         you have any questions about this Privacy Policy or how and why We
//         process Personal Data, please contact Us at{" "}
//         <a href='mailto:dataprotection@tradedepot.co'>
//           dataprotection@tradedepot.co.
//         </a>
//       </p>
//       <h4 className='last-modified'>Last Modified: 5th Sept., 2024</h4>
//     </div>
//   );
// };
