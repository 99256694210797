import { NewHeader } from "../../components/new-header";
import BlogImg from "../../assets/Group 3719@2x.png";
import { useNavigate, useParams } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import { NewFooter } from "../../components/new-footer";
import { createClient } from "contentful";
import { useEffect, useState } from "react";
import { Loading } from "../../components/loading-screen";
import { formatWebsiteDate } from "../../utils/functions";

export function BlogPost() {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    handleFetchBlogPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchBlogPost = async () => {
    setLoading(true);
    try {
      await client.getEntry(id).then((entries) => {
        setBlogPost(entries.fields);
        setLoading(false);
      });
    } catch (error) {
      console.log({ error });
      setLoading(false);
      setHasError(true);
    }
  };

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_blog td_blog_post'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <h3 className='mobile'>Blog</h3>
            <div className='row introduction'>
              <div className='col-md-6 col-sm-12'>
                <h3>Blog</h3>
                <h5>{blogPost?.blogTitle}</h5>
                <button className='td-btn' onClick={() => navigate("/blog")}>
                  <span>Return to stories</span>
                  <span className='chevron chevron-left' />
                </button>
              </div>
              <div className='col-md-6 col-sm-12'>
                <img src={BlogImg} alt='blog-img-1' className='img-fluid' />
              </div>
            </div>
            <div className='blog_page'>
              <div className='row blog_page_container'>
                <div className='col-md-2 col-sm-12 user-info'>
                  <p>{blogPost?.blogAuthor}</p>
                  <p>{formatWebsiteDate(blogPost?.publishedDate || "")}</p>
                </div>
                <div className='col-md-10 col-sm-12'>
                  <div className='section'>
                    <span className='content' markdown='1'>
                      <Markdown>{blogPost?.blogContent}</Markdown>
                    </span>
                  </div>
                </div>
              </div>
              <NewFooter />
            </div>
          </div>
        </div>
      </div>
    </Loading>
  );
}
