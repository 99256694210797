/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { TradeFooter } from "./components/footer";

export const Culture = () => {
  return (
    <>
      <div class="trade-landing">
        <div class="trade-culture__ban">
          <nav class="navbar navbar-expand-lg navbar-dark absolute-top opaque-navbar trade-landing__full">
            <div class="container">
              <a class="navbar-brand" href="/">
                <img src="images/logo-alt.svg" width="200" alt="" />
              </a>
              <button
                class="navbar-toggler navbar-toggler-right"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <a class="nav-link" href="/">
                      Home
                    </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" href="https://app.tradedepot.co/">
                      Login
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="typeform-share button trade-landing__ban__btn"
                      href="https://tradedepot.typeform.com/to/YpDE0w"
                      data-mode="drawer_right"
                      data-submit-close-delay="5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign Up{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div class="trade-landing__mobile">
            <div class="container">
              <div class="trade-landing__topnav">
                <img src="images/logo.png" width="200" alt="" />
                <div id="myLinks">
                  <a href="/">Home</a>
                  <a href="https://app.tradedepot.co/">Login</a>
                  <a href="https://tradedepot.typeform.com/to/YpDE0w">
                    Sign Up
                  </a>
                </div>
                <a
                  href="javascript:void(0);"
                  class="icon"
                  onClick={() => window.myFunction()}
                >
                  <i class="fa fa-bars"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="trade-landing__ban__title">
            <div class="container wow fadeInUp" data-wow-delay="0.3s">
              <div class="row">
                <div class="col-lg-6 text-left my-5 career">
                  <h1>Culture</h1>
                  <p>
                    We are building the Future of Distribution in Emerging
                    Markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="trade-culture__content">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h1>TradeDepot Culture</h1>
              <p class="mb-5">
                We are committed to building the future of retail in Africa, to
                make food and supplies accessible and affordable for even the
                most remote consumers on the continent.
              </p>
            </div>

            <div class="row justify-center">
              <div class="col-lg-6">
                <img class="img-fluid mb-3" src="images/ban.jpg" alt="" />
              </div>
              <div class="col-lg-6">
                <div class="col-lg-12">
                  <h2>About Us</h2>
                  <p>
                    We started out with a mission to make milk distribution to
                    small retailers on the continent better, using technology.
                    Today we are scaling out this same tech to help reliably
                    distribute food, beverages and personal care products to
                    more and more communities, and help small community
                    pharmacies get the medical supplies they need. Our goal is
                    to help every neighbourhood store in Africa get all their
                    supplies at the best possible prices; to be THE supply
                    partner for Africa's retail outlets
                  </p>
                </div>
                <div class="col-lg-12 float-right">
                  <h2>What We Do</h2>
                  <p>
                    We focus on solving real problems for our clients and
                    creating measurable and sustainable value. We understand
                    that 90% of our retail clients are not sophisticated, and so
                    we seek out simple yet disruptive ways for these retailers
                    to access product supplies at affordably prices. Our field
                    teams use smart tools to plan their coverage, the same tools
                    help them recruit new customers and facilitate order
                    fulfilment. We review frequently not just to report
                    progress, but to collectively seek ways to improve the
                    process and win in the long run. Feedback and collaboration
                    is very key to our business. Our field teams provide
                    customer usage feedback to our product teams, and this
                    feedback loops into our iterative product development cycle
                    to ensure our engineering teams keep building great tools
                    that help our customers win.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="trade-culture__line"></div>

          <div class="row">
            <div class="col-lg-12">
              <h2>Who We Are</h2>
              <p>
                We are a bunch of realistic dreamers who believe we can make a
                difference. We see technology as an enabler, but we understand
                that the ultimate long-lasting difference will come from
                dedication and the "human touch" we apply to every thing we do.
              </p>
            </div>

            <div class="col-lg-6">
              <h2>Code of Conduct</h2>
              <p>
                We live by our core values of{" "}
                <em>Solidarity, Honesty, Curiosity</em> and <em>Ambition</em>;
                ensuring at all times that personal and professional ethics
                supersede short-term business goals and achievements.
              </p>
            </div>
            <div class="col-lg-6">
              <h2>Our Future</h2>
              <p>
                First, we are focused on making food and supplies accessible and
                affordable to most people in Nigeria; then we want to take on
                Africa, replicating our success and lessons learnt to other
                countries on the continent
              </p>
            </div>
          </div>

          <div class="trade-culture__subban my-5">
            <div class="row text-center">
              <div class="col-lg-7 m-auto">
                <h1 class="display-3">
                  "Difficult takes a day, impossible takes a week"
                </h1>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row">
              <div class="col-lg-12 mt-5">
                <h2>Special Events</h2>
                <p>
                  On some Fridays we wind down with TGIF hangouts at venues we
                  collectively choose. Birthdays are special days for the entire
                  office, and goodies get passed around
                </p>
              </div>
            </div>

            <div class="row py-5">
              <div class="col-lg-6">
                <h2>Time Off</h2>
                <p>
                  Our vacation policy is “take a vacation when you need to.” We
                  don’t have any rules or forms around how many weeks per year.
                  Frankly, we intermix work and personal time quite a bit,
                  sending slack updates or pushing code commits at odd hours,
                  taking off weekday afternoons for kids’ games, etc. One simple
                  rule: "Always act in TradeDepot and our customers' best
                  interests"
                </p>
                <p>
                  Our parental leave policy is: “take care of your baby and
                  yourself.” New parents are encouraged to take the time they
                  feel is right and to ease back in as they want.
                </p>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12">
                    <h2>Communication</h2>
                    <p>
                      Slack is our primary means of communication and
                      collaboration. All interest groups and projects have their
                      own dedicated Slack channel. So don't miss out on key
                      updates for your team, get Slacking!
                    </p>

                    <div class="trade-culture__line1"></div>
                  </div>
                  <div class="col-lg-12">
                    <h2>Dress Code Policy</h2>
                    <p>
                      We don't have a formal dress code policy, yet we haven't
                      had an office "wardrobe malfunction" incident till date.
                      Always use "good judgment”, dress smart and you will
                      always fit in.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6">
                <h2>Technology</h2>
                <p>
                  We mostly use JavaScript frameworks and sometimes Java and
                  Python to create mobile and web apps in other to deploy
                  world-class, scaleable, service oriented apps, you will find a
                  wide range of frameworks, technologies and platforms within
                  our landscape. Some of these include ReactJS, ReactNative,
                  Firebase, MeteorJS, Mongo, Redis, Docker, Kubernetes,
                  Serverless. Our Primary cloud hosting service provider is
                  Amazon Web Services, and we utilize a lot of the services AWS
                  provides, including ECS, Lambda, Route53, ElasticSearch,
                  Cloudfront, etc.
                </p>
              </div>
              <div class="col-lg-6">
                <h2>Productivity Software</h2>
                <p>
                  Today we use Slack for collaboration, Hubspot CRM for sales
                  pipeline and account management, GitHub for code management,
                  Intercom for in-app customer service and real time support,
                  Zendesk for ticket management, our proprietary Dynamic Sales
                  tools for the field teams, Xero for finance... and of course
                  good old, traditional email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TradeFooter />
    </>
  );
};
