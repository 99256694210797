import * as Yup from "yup";

const genericSchema = {
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  formattedAddress: Yup.string().required("Address is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  companyName: Yup.string().required("Company name is required"),
};

export const PartnerSchema = Yup.object().shape({
  ...genericSchema,
});

export const DistributorSchema = Yup.object().shape({
  ...genericSchema,
  brands: Yup.string().required("Brands is required"),
});

export const ContactFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
});

export const ApplicationFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email().required("Email is required"),
  uploadedDoc: Yup.string().required("Document is required"),
  selectedCareer: Yup.string().required("Please select a job title"),
});
