export const STATUS_TYPE_SUCCESS = "success";
export const STATUS_TYPE_FAIL = "fail";

export function StatusRenderer({
  status = STATUS_TYPE_FAIL,
  message,
  title,
  useInAContainer,
  canRetry = false,
  retryText = "Try Again",
  retryFunction = null,
}) {
  return (
    <div
      className={`trade-uk_app_error-view ${
        useInAContainer ? "container-view" : ""
      }`}
    >
      {status === STATUS_TYPE_FAIL ? (
        <img src='../images/error-icon.svg' alt='' />
      ) : status === STATUS_TYPE_SUCCESS ? (
        <img src='../images/success.svg' alt='' />
      ) : null}
      <h3>{title}</h3>
      <h6>{message}</h6>
      {canRetry ? <h4 onClick={retryFunction}>{retryText}</h4> : null}
    </div>
  );
}
