import { NewHeader } from "../../components/new-header";
import ContactUsImgOne from "../../assets/Group 3724@2x.png";
import ContactUsImgTwo from "../../assets/Group 3589@2x.png";
import { NewFooter } from "../../components/new-footer";
import EnvelopeSvg from "../../assets/TD_Icon_Contact_Disk.svg";

export function ContactUs() {
  return (
    <div className='td_new td_contact'>
      <div className='wrapper'>
        <NewHeader hasTopCircle={false} />
        <div className='body'>
          <div className='introduction'>
            <div className=''>
              <h3>Contact Us</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type
              </p>
            </div>
            <div className=''>
              <img
                src={ContactUsImgOne}
                alt='distributor-img'
                className='img-fluid'
              />
            </div>
          </div>
          <div className='row introduction-2'>
            <div className='col-md-6 col-sm-12 photo-div'>
              <img
                src={ContactUsImgTwo}
                alt='intro-2-img'
                className='img-fluid'
              />
            </div>
            <div className='col-md-6 col-sm-12'>
              <h3>Start a Conversation Today</h3>
              <p>
                We strengthen partnerships with retailers, ensuring premium
                shelf space and high visibility for TradeDepot’s brands. By
                stocking our brands, retailers can enhance their product
                offerings and meet the diverse needs of their customers.
              </p>
              <div className='actions'>
                <img src={EnvelopeSvg} alt='envelope-svg' />
                <button className='td-btn'>
                  <a href='mailto:hello@tradedepot.co'>Hello@tradedepot.co</a>
                </button>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    </div>
  );
}
