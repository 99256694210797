import { NewHeader } from "../../components/new-header";
import CareerImgOne from "../../assets/HR Man.png";
import CareerImgTwo from "../../assets/Post-it note meeting.png";
import CareerImgThree from "../../assets/Group 3720@2x.png";
import { NewFooter } from "../../components/new-footer";
import { createClient } from "contentful";
import { useEffect, useState } from "react";
import { Loading } from "../../components/loading-screen";
import { useNavigate } from "react-router-dom";

export function NewCareers() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [careers, setCareers] = useState([]);
  const navigate = useNavigate();

  console.log({ careers });

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    handleFetchCareers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchCareers = async () => {
    setLoading(true);
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "careers",
        order: "sys.createdAt",
      });
      const careers = res.items.map((item) => ({
        title: item.fields.jobTitle,
        description: item.fields.jobDescription,
        _id: item.sys.id,
      }));
      setCareers(careers);
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(true);
      setHasError(true);
    }
  };

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_careers'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <div className='introduction'>
              <div className=''>
                <h3>Careers</h3>
                <p>
                  Join us in transforming global trade through technology. At
                  TradeDepot, we connect iconic brands with the next billion
                  consumers, using data-driven insights, AI, and financial tech
                  to drive smarter decision-making and growth. We’re seeking
                  innovators ready to push boundaries, solve complex challenges,
                  and lead the future of B2B commerce.
                </p>
              </div>
              <div className=''>
                <img
                  src={CareerImgThree}
                  alt='distributor-img'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='available-roles'>
              <h2>Available Roles</h2>
              <div className='roles'>
                {careers.map((role, key) => (
                  <div className='role' key={key}>
                    <div className='title'>{role?.title}</div>
                    <div className='description'>
                      <p>{role?.description}</p>
                      <button
                        className='td-btn'
                        onClick={() => navigate(`/career/${role._id}`)}
                      >
                        <span>See more</span>
                        <span className='chevron chevron-right' />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='apply-now-container row'>
              <div className='col-md-7 col-sm-12'>
                <h3>Apply Now</h3>
                <p>
                  Email us at the following address, attaching your CV and
                  covering letter, and let us know what role you are interested
                  in and why we’d be great fit together.
                </p>
                <br />
                <p>
                  Alternatively, use the form below to get in touch and kick
                  start your career with TradeDepot
                </p>
                <button className='td-btn'>
                  <a href='mailto:careers@tradedepot.co'>
                    careers@tradedepot.co{" "}
                  </a>
                </button>
              </div>
              <div className='col-md-5 col-sm-12'>
                <img
                  src={CareerImgOne}
                  alt='career-img-one'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='company-culture row'>
              <div className='col-md-5 col-sm-12 photo-div'>
                <img
                  src={CareerImgTwo}
                  alt='career-img-one'
                  className='img-fluid'
                />
              </div>
              <div className='col-md-7 col-sm-12'>
                <h3>Company Culture</h3>
                <p>
                  At TradeDepot, we value curiosity, collaboration, and a
                  relentless drive for innovation. We believe in empowering our
                  team to take ownership, challenge norms, and make impactful
                  decisions. Together, we’re building a supportive and dynamic
                  environment where creativity and ambition thrive.
                </p>
                <a className='td-btn-link' href='#contactUs'>
                  <button className='td-btn'>
                    <span>Join our team</span>
                    <span className='chevron chevron-right' />
                  </button>
                </a>
              </div>
            </div>
            <NewFooter
              isApplicationForm={true}
              items={careers.map((el) => el.title)}
            />
          </div>
        </div>
      </div>
    </Loading>
  );
}
