import React from "react";
import { TradeFooter } from "./components/footer";

export const Careers = () => {
  return (
    <>
      <div className="trade-landing">
        <div className="trade-career__ban">
          <nav className="navbar navbar-expand-lg navbar-dark absolute-top opaque-navbar trade-landing__full">
            <div className="container wow fadeInDown" data-wow-delay="0.3s">
              <a className="navbar-brand" href="/">
                <img src="images/logo-alt.svg" width="200" alt="" />
              </a>
              <button
                className="navbar-toggler navbar-toggler-right"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="https://app.tradedepot.co/">
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="typeform-share button trade-landing__ban__btn"
                      href="https://tradedepot.typeform.com/to/YpDE0w"
                      data-mode="drawer_right"
                      data-submit-close-delay="5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="trade-landing__mobile">
            <div className="container">
              <div className="trade-landing__topnav">
                <img src="images/logo.png" width="200" alt="" />
                <div id="myLinks">
                  <a href="/">Home</a>
                  <a href="https://app.tradedepot.co/">Login</a>
                  <a href="https://tradedepot.typeform.com/to/YpDE0w">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="trade-landing__ban__title">
            <div className="container wow fadeInUp" data-wow-delay="0.3s">
              <div className="row">
                <div className="col-lg-6 text-left my-5 career">
                  <h1>Careers</h1>
                  <p>
                    We are building the future of distribution by creating
                    better lives for retail business owners in emerging markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-career__content">
        <div className="container">
          <div className="row pb-5 align-row-center">
            <div className="col-lg-6">
              <div className="trade-career__content__img-wrapper">
                <img src="images/careers-3.jpeg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-6 py-5 mt-5">
              <h1>Freedom and Responsibility</h1>
              <p className="line-height25">
                We are a bunch of realistic dreamers who believe we can make a
                difference. We see technology as an enabler, but we understand
                that the ultimate long-lasting difference will come from
                dedication and the "human touch" we apply to every thing we do.
                <br />
                <a href="/culture" className="btn trade-career__btn mt-3">
                  Our Culture
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="trade-career__icon mt-5">
          <div className="container">
            <div className="row px-5">
              <div className="col-lg-4 px-5 text-center">
                <img src="images/icon1.png" className="img-fluid icon" alt="" />
                <h3>Work</h3>
                <p>You will work with creative and curious people.</p>
              </div>
              <div className="col-lg-4 px-5 text-center">
                <img src="images/icon2.png" className="img-fluid" alt="" />
                <h3>Learn</h3>
                <p>We are a learning organization with a growth mindset.</p>
              </div>
              <div className="col-lg-4 px-5 text-center">
                <img src="images/icon3.png" className="img-fluid" alt="" />
                <h3>Fun</h3>
                <p>
                  We make sure we create fun ways for people to express
                  themselves.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="trade-career__content__together">
          <div className="container">
            <div className="row px-6 mb-6">
              <div className="col-lg-9 m-auto text-center">
                <h3>Together at TradeDepot</h3>
                <p>
                  Solidarity is key. No one is just a developer or a designer.
                  Titles don't get the job done. Above all, we're a team.
                  Together, we work hard, we solve problems and try to have fun
                  doing so.
                </p>

                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <div className="image-wrapper">
                      <img
                        src="images/careers-4.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="image-wrapper">
                      <img src="images/c2.jpg" className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-career__benefit my-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2>Benefits at TradeDepot</h2>
            </div>
            <div className="col-lg-4">
              <p>
                Comprehensive Health Plans
                <br />
                Flexible Working Hours
                <br />A Learning Culture
              </p>
            </div>
            <div className="col-lg-4">
              <p>
                Competitive Compensation
                <br />
                Bleeding-Edge Technology
                <br />
                Well-Being at Work
              </p>
            </div>
            <div className="col-lg-4">
              <p>
                Personal Time Off
                <br />
                Offsites and Happy Hours
                <br />
                Super Smart Colleagues
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-career__believe my-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="mb-5">What We Believe In</h2>
            </div>
            <div className="col-lg-3">
              <h3>Curiosity</h3>
              <p>
                We are all students, and we are all teachers. Chances are, the
                person sitting next to you knows a keyboard shortcut that can
                change your life.
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Ambition</h3>
              <p>
                We strive to be great. And details are everything. Every pixel,
                every line of code, and every tagline has to be perfect..
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Solidarity</h3>
              <p>
                Solidarity is key. Your title is just a formality. Above all,
                we're a team.
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Honesty</h3>
              <p>
                Honesty is key at our company, however excellence is never
                reached without some criticism. Take it. Use it. Give it..
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="trade-footer">
        <div className="trade-footer__header">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-12 text-left">
                <h1>Your success story starts here.</h1>
                <a
                  className="typeform-share button trade-footer__header-btn mr-3"
                  href="https://tradedepot.typeform.com/to/YpDE0w"
                  data-mode="drawer_right"
                  data-submit-close-delay="5"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
        <TradeFooter />
      </div>
    </>
  );
};
