import React from "react";
import { WebsiteFooter } from "./components/website-footer";
import { WebsiteHeader } from "./components/website-header";
import { SUPPORTED_COUNTRIES } from "./utils/functions";

export function UkShippingReturns() {
  const qParameters = new URLSearchParams(window.location.search);
  const countryCode = qParameters.get("country") || SUPPORTED_COUNTRIES.GB;

  return (
    <>
      <div className=''>
        <div className='trade-uk-landing__intro'>
          <WebsiteHeader />
        </div>
        <div className='trade-uk-terms'>
          <p className='trade-uk-terms__title'>Shipping & Returns</p>
          <div className='container'>
            <div className='trade-uk-terms__content'>
              <h3>1. INTRODUCTION</h3>
              <p>
                The following terminology applies to these Shipping and Returns
                policy, Terms and Conditions, Privacy Policy and any or all
                Agreements: "Client", “Customer”, “You” and “Your” refers to
                you, the person accessing this web app and/or mobile app and
                accepting the Company’s terms and conditions. “Frontier Africa
                Trading Co Ltd’’, “Frontier Africa’’, “TradeDepot Wholesale’’,
                “TradeDepot Wholesale’’, “TradeDepot”, “The Company",
                “Ourselves”, “We”, “Our” and "Us", refers to our Company,
                Frontier Africa Trading Co Ltd trading as TradeDepot. “Party”,
                “Parties”, or “Us”, refers to both the Client and ourselves, or
                either the Client or ourselves. Any use of the above terminology
                or other words in the singular, plural, capitalisation and/or
                he/she or they, are taken as interchangeable and therefore as
                referring to same.
              </p>
              <h3>2. Ordering</h3>
              <p>
                Details regarding the placing of product orders can be found on
                our Terms & Conditions page. The following information relates
                exclusively to the delivery of and return of products ordered.
              </p>
              <h3>3. Product Unavailability</h3>
              <p>
                In the event that an ordered product is temporarily unavailable,
                we will contact you to inform you of the unavailability and
                provide a full refund for the unavailable goods.
              </p>
              <h3>4. Delivery</h3>
              <p>
                We deliver to all destinations in England. Delivery options,
                costs and timelines will be displayed to you when you checkout
                on TradeDepot Wholesale’s web and mobile app. Fulfillment will
                be done by a third party courier service.
              </p>
              <h3>5. Changes/ Cancellations</h3>
              <p>
                No changes or cancellations can be made to your order/delivery
                once the order has been successfully placed on TradeDepot
                Wholesale. Unwanted items can be returned to Us upon receipt by
                You. Please see clause 8 for more information regarding returns.
              </p>
              <h3>6. Customer Responsibilities</h3>
              <p>
                6.1 You must ensure that the delivery, contact and payment
                details you provide for each delivery are correct and lawful.
                <br />
                <br />
                6.2 You must ensure there will be someone over the age of 18
                years available to take delivery at the address provided, within
                the given delivery window, as a signature may be required to
                confirm the delivery. If there is no-one available, you give
                permission for the courier to leave the package in an
                appropriate safe place (e.g. a porch), or with an appropriate
                third-party (e.g. a neighbour). In these cases, you are
                considered to have received the delivery and the transaction is
                completed, at which point, ownership of and responsibility for
                the delivery transfers to you.
                <br />
                <br />
                6.3 In the event that the delivery cannot be left in a safe
                space or with a third-party, we might re-attempt delivery on the
                next working day. If all delivery attempts are unsuccessful, the
                items will be returned to us by the courier, in which case, you
                are responsible for any further delivery charges that will be
                incurred to get the items to you as well as the courier’s return
                to sender fee.
                <br />
                <br />
                6.4 When your delivery arrives, you should check the package for
                any signs of damage or tampering. In the unlikely event that the
                package shows any signs of tampering or damaged, you should
                refuse the delivery. The package will then be returned to us and
                we can undertake an assessment before sending out a replacement,
                issuing a refund or taking further action.
              </p>
              <h3>7. Non-delivery/Mis-delivery</h3>
              <p>
                7.1 If, after the stated delivery period, you have not received
                your delivery, you must contact us via the chat function in our
                mobile and web app within 3 calendar days from the date the
                delivery was due, or ideally sooner, to raise an investigation.
                Courier companies restrict the time available to make claims for
                reimbursement, and so we may not be able to provide replacement
                products and refunds after this period.
                <br />
                <br />
                7.2 If you fail to arrange a redelivery after the courier has
                attempted a delivery, we will refund the full purchase costs
                less the delivery fee and return to sender charges.
                <br />
                <br />
                7.3 If non-delivery occurs due to customer error (e.g. the
                customer entered incorrect shipping details), we will refund the
                full purchase costs less the delivery fee and return to sender
                charges.
              </p>
              <h3>8. Returns</h3>
              <p>
                8.1 In the event that after a delivery, you would like to return
                some of the goods you received, you may return the goods to us.
                <br />
                <br />
                8.2 You have 7 calendar days from the date of delivery to begin
                processing a return with us.
                <br />
                <br />
                8.3 To return goods from an order to us, tap on the return
                button within the order on our mobile and web app.
                <br />
                <br />
                8.4 The app will guide you through the process of returning
                items to us as well as providing the necessary information such
                as return labels, packing instructions and courier information.
                <br />
                <br />
                8.5 The physical return of items will be done through our
                registered courier service who will pick the products from your
                location on the date specified on your mobile and web app. You
                must ensure that the items are properly packed and ready to be
                picked up on the date specified.
                <br />
                <br />
                8.6 You are responsible for paying the cost of shipping to
                return the items to our warehouse and will be asked to pay this
                cost when processing your return on our mobile and web app.
                <br />
                <br />
                8.7 Once returned items have been successfully received at our
                warehouse, we will begin processing your refund for the items
                received. Refunds will be made to the payment method used in the
                initial order and takes approximately 7 business days to
                complete.
              </p>
            </div>
          </div>
        </div>
        <WebsiteFooter isColored={true} countryCode={countryCode} />
      </div>
    </>
  );
}
