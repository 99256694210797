import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { printErrorMessage } from "../utils/functions";
import {
  STATUS_TYPE_FAIL,
  STATUS_TYPE_SUCCESS,
  StatusRenderer,
} from "./error-screen";

export function AddressInput() {
  const inputRef = useRef();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const qParameters = new URLSearchParams(window.location.search);
  const token = qParameters.get("token");
  const [isChangeSuccessful, setIsChangeSuccessful] = useState(false);
  const [error, setError] = useState({
    message: "",
    title: "",
    hasError: false,
  });

  useEffect(() => {
    const googleScript = document.createElement("script");
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_ADDRESS_MAP_KEY}&libraries=places`;
    googleScript.async = true;
    googleScript.defer = true;

    googleScript.onload = () => {
      // Initialize Autocomplete after Google Maps script is loaded
      if (inputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
            componentRestrictions: {
              country: "NG",
            },
            // strictBounds: true,
            // bounds: new google.maps.LatLngBounds(myLatLng, myLatLng),
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          setAddress(place?.formatted_address);
        });
      }
    };

    document.head.appendChild(googleScript);

    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(googleScript);
    };
  }, []); // Empty dependency array ensures that this useEffect runs only once on mount

  const handleSaveAddress = async () => {
    setLoading(true);
    try {
      await axios.post(
        "https://qn6x4esklh.execute-api.us-east-1.amazonaws.com/prod/address/callback",
        { address },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setIsChangeSuccessful(true);
    } catch (error) {
      setLoading(false);
      let err;
      if (error?.response?.status === 401) {
        err = printErrorMessage(error, "auth-token");
      } else {
        err = {
          message: "Failed to update address",
          title: "Update Unsuccessful",
        };
      }
      setError({ ...err, hasError: true });
    }
  };

  return (
    <>
      {isChangeSuccessful ? (
        <StatusRenderer
          status={STATUS_TYPE_SUCCESS}
          message={"Your address has been updated successfully!"}
          title={"Update Successful!"}
        />
      ) : error?.hasError ? (
        <StatusRenderer
          status={STATUS_TYPE_FAIL}
          message={error?.message}
          title={error?.title}
          retryText='Try Again'
          canRetry={true}
          retryFunction={() => setError({ hasError: false })}
        />
      ) : (
        <div className='trade-uk-checkout white-bg'>
          <div className='trade-uk-checkout_header'>
            <p>Location</p>
          </div>
          <div className='trade-uk_address-input'>
            <div className='mt-3 mb-3'>
              <label>Store address</label>
              <input
                ref={inputRef}
                placeholder='Enter your store address'
                className='form-control'
              />
              <img src='./images/location-pin.svg' alt='' />
            </div>
            <button
              className='trade-uk-app-button'
              disabled={loading}
              onClick={handleSaveAddress}
            >
              {loading ? (
                <div
                  class='spinner-border text-success app-spinner'
                  role='status'
                >
                  <span class='sr-only'>Loading...</span>
                </div>
              ) : (
                "Update Address"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
