import { NewHeader } from "../../components/new-header";
import RetailerImgOne from "../../assets/Group 3723@2x.png";
import BenefitImgOne from "../../assets/Group 3582@2x.png";
import BenefitImgTwo from "../../assets/Group 3583@2x.png";
import BenefitImgThree from "../../assets/Group 3586@2x.png";
import AppStoreImg from "../../assets/App Store.png";
import GooglePlayImg from "../../assets/Google.png";
import { NewFooter } from "../../components/new-footer";

export function Retailers() {
  return (
    <div className='td_new td_retailers'>
      <div className='wrapper'>
        <NewHeader hasTopCircle={false} />
        <div className='body'>
          <div className='introduction'>
            <div className=''>
              <h3>Your Local, Global Marketplace</h3>
              <p>
                Discover our curated list of products sourced from manufacturers
                worldwide. Whether you’re an importer, distributor or retailer,
                TradeDepot offers direct access to quality products across
                various categories.
              </p>
              <div className='action-buttons-flex'>
                <a
                  href={`${process.env.REACT_APP_PROCUREMENT_LOGIN}register`}
                  className='td-btn-link'
                  rel='noreferrer'
                  target='_blank'
                  role='button'
                >
                  <button className='td-btn'>
                    <span>Get started</span>
                    <span className='chevron chevron-right' />
                  </button>
                </a>
                <a
                  href={`${process.env.REACT_APP_PROCUREMENT_LOGIN}login`}
                  className='td-btn-link'
                  rel='noreferrer'
                  target='_blank'
                  role='button'
                >
                  <button className='td-btn'>
                    <span>Sign in</span>
                  </button>
                </a>
              </div>
            </div>
            <div className=''>
              <img
                src={RetailerImgOne}
                alt='distributor-img'
                className='img-fluid'
              />
            </div>
          </div>
          <div className='benefits'>
            <div className='benefit'>
              <img
                src={BenefitImgOne}
                alt='benefit-img-one'
                className='img-fluid'
              />
              <div>
                <h3>Wide Range</h3>
                <p>
                  Explore products sourced globally, from African specialty
                  foods to European wines and Asian consumer goods.
                </p>
              </div>
            </div>
            <div className='benefit'>
              <img
                src={BenefitImgTwo}
                alt='benefit-img-one'
                className='img-fluid'
              />
              <div>
                <h3>Effortless Ordering</h3>
                <p>
                  Place orders directly with manufacturers and manage your
                  shipment through our seamless platform.
                </p>
              </div>
            </div>
            <div className='benefit'>
              <img
                src={BenefitImgThree}
                alt='benefit-img-one'
                className='img-fluid'
              />
              <div>
                <h3>Dependable delivery</h3>
                <p>
                  We ensure reliable shipping from our trusted network of
                  suppliers to your warehouse, anywhere in the world.
                </p>
              </div>
            </div>
          </div>
          <div className='app-download'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <h3>Get the app</h3>
                <p>
                  Retailers, streamline your orders on the go with the
                  TradeDepot Wholesale app - Quick easy, and always at your
                  fingertips.
                </p>
                <div className='download-btns'>
                  <a
                    href={process.env.REACT_APP_APPLE_STORE_DOWNLOAD_URL}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={AppStoreImg}
                      alt='app-store-img'
                      className='img-fluid'
                    />
                  </a>
                  <a
                    href={process.env.REACT_APP_PLAY_STORE_DOWNLOAD_URL}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={GooglePlayImg}
                      alt='app-store-img'
                      className='img-fluid'
                    />
                  </a>
                </div>
              </div>
              <div className='col-md-6 col-sm-12 phone-div'>
                <img
                  src='../images/phone-full.png'
                  className='img-fluid app-screen-td'
                  alt='app-screen-td'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    </div>
  );
}
