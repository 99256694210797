import { WebsiteFooter } from "./components/website-footer";
import { WebsiteHeader } from "./components/website-header";

export function KeyAccountTerms() {
  return (
    <>
      <div className=''>
        <div className='trade-uk-landing__intro'>
          <WebsiteHeader />
        </div>
        <div className='trade-uk-terms'>
          <p className='trade-uk-terms__title'>Terms & Conditions</p>
          <div className='container'>
            <div className='trade-uk-terms__content'>
              <h3>1. Introduction</h3>
              <p>
                1.1. These Terms and Conditions (“T&Cs”) govern the supply of
                products on agreed payment terms to our key account customers.
                <br />
                <br />
                1.2. Definition
                <li>
                  “Supplier”: TradeDepot.
                  <li>
                    “Customer”: Key account entities purchasing products from
                    TradeDepot.
                  </li>
                  <li>“Products”: Goods supplied by TradeDepot.</li>
                  <li>
                    “Agreement”: These Terms and Conditions and any other
                    documents incorporated by reference.
                  </li>
                </li>
              </p>
              <h3>2. Orders and Acceptance</h3>
              <p>
                2.1. Customers may place orders via email, phone, or the
                TradeDepot Wholesale customer portal.
                <br />
                <br />
                2.2. All orders are subject to acceptance by TradeDepot. An
                order confirmation will be provided upon acceptance.
                <br />
                <br />
                2.3. TradeDepot reserves the right to reject any order without
                providing a reason.{" "}
              </p>
              <h3>3. Payment Terms</h3>
              <p>
                3.1. Payment for Products is due as expressly agreed by both
                parties in writing.
                <br />
                <br />
                3.2. Invoices will be sent electronically to the Customer’s
                provided email address.
                <br />
                <br />
                3.3. Late payments will incur a late fee of 1.5% per month on
                the outstanding balance.
                <br />
                <br />
                3.4. Customers must ensure payments are made in full without any
                set-off or counterclaim.
              </p>
              <h3>4. Credit Offer</h3>
              <p>
                4.1. Each purchase made by the Customer constitutes an
                acceptance of credit terms offered by TradeDepot.
                <br />
                <br />
                4.2. The Customer agrees to adhere to the agreed payment terms.
                Failure to do so will result in immediate suspension of further
                credit.
                <br />
                <br />
                4.3. TradeDepot reserves the right to conduct periodic credit
                reviews of the Customer’s account.
                <br />
                <br />
                4.4. In the event of default, TradeDepot may take the following
                actions:
                <li>Suspend or terminate product supply.</li>
                <li>Charge late fees as specified in clause 4.3.</li>
                <li>
                  Engage third-party collection agencies to recover outstanding
                  amounts.
                </li>
                <li>
                  Report the default to credit bureaus, which may affect the
                  Customer’s credit rating.
                </li>
                <br />
                4.5. The Customer agrees to indemnify TradeDepot for any costs
                incurred in the collection of overdue payments, including legal
                fees.
              </p>
              <h3>5. Delivery</h3>
              <p>
                5.1. TradeDepot will deliver Products to the address specified
                by the Customer.
                <br />
                <br />
                5.2. Delivery dates are estimates only. TradeDepot is not liable
                for any delays in delivery.
                <br />
                <br />
                5.3. Risk of loss and title to the Products pass to the Customer
                upon delivery.
                <br />
                <br />
                5.4. All deliveries are subject to delivery charges, which will
                be communicated at the time of order.
              </p>
              <h3>6. Inspection and Acceptance of Products</h3>
              <p>
                6.1. The Customer must inspect the Products upon delivery and
                notify TradeDepot of any discrepancies within 48 hours.
                <br />
                <br />
                6.2. Failure to notify within this period constitutes acceptance
                of the Products.
                <br />
                <br />
                6.3. TradeDepot will not accept returns or provide refunds for
                Products that have been accepted by the Customer.
              </p>
              <h3>7. Returns and Refunds</h3>
              <p>
                7.1. Returns are only accepted for defective or damaged Products
                reported within 48 hours of delivery.
                <br />
                <br />
                7.2. Returned Products must be in their original packaging and
                condition.
                <br />
                <br />
                7.3. TradeDepot reserves the right to inspect the returned
                Products before processing any refunds or replacements.
              </p>
              <h3>8. Warranty</h3>
              <p>
                8.1. TradeDepot warrants that Products will conform to the
                description provided at the time of sale.
                <br />
                <br />
                8.2. This warranty is exclusive and in lieu of all other
                warranties, express or implied.
                <br />
                <br />
                8.3. Any claims under this warranty must be made within the
                specified warranty period.
              </p>
              <h3>9. Know Your Customer (KYC) and Know Your Business (KYB)</h3>
              <p>
                9.1. The Customer agrees to provide accurate and complete KYC
                and KYB information as required by TradeDepot.
                <br />
                <br />
                9.2. The Customer warrants that the individuals representing the
                Customer in transactions are duly authorized to do so.
                <br />
                <br />
                9.3. TradeDepot reserves the right to verify the information
                provided and may request additional documentation as necessary.
                <br />
                <br />
                9.4. Any false or misleading information provided by the
                Customer may result in immediate termination of services and
                legal action.
              </p>
              <h3>10. Limitation of Liability</h3>
              <p>
                10.1. TradeDepot’s liability is limited to the replacement of
                defective Products or the refund of the purchase price.
                <br />
                <br />
                10.2. TradeDepot is not liable for any indirect, incidental, or
                consequential damages, including loss of profit or business
                interruption.
                <br />
                <br />
                10.3. The Customer agrees to indemnify TradeDepot against any
                claims arising from the use of the Products.
              </p>
              <h3>11. Confidentiality</h3>
              <p>
                11.1. Both parties agree to keep confidential any proprietary
                information disclosed during the course of their relationship.
                <br />
                <br />
                11.2. Confidential information shall not include information
                that is publicly known or independently developed.
              </p>
              <h3>12. Governing Law</h3>
              <p>
                12.1. These Terms and Conditions are governed by the laws of the
                Federal Republic of Nigeria.
                <br />
                <br />
                12.2. Any disputes arising from this Agreement shall be resolved
                through arbitration in Nigeria.
                <br />
                <br />
                12.3. The Customer agrees to submit to the jurisdiction of the
                Nigerian courts for the enforcement of any arbitration award.
              </p>
              <h3>13. Amendments</h3>
              <p>
                13.1. TradeDepot reserves the right to amend these Terms and
                Conditions at any time.
                <br />
                <br />
                13.2. Customers will be notified of any changes through the
                designated communication channels.
                <br />
                <br />
                13.3. Continued use of TradeDepot’s services constitutes
                acceptance of the amended terms.
              </p>
              <h3>14. Force Majeure</h3>
              <p>
                14.1. TradeDepot is not liable for any failure to perform due to
                causes beyond its reasonable control, including natural
                disasters, pandemics, and government actions.
                <br />
                <br />
                14.2. In the event of a force majeure, TradeDepot will notify
                the Customer and make reasonable efforts to mitigate the
                effects.
              </p>
              <h3>15. Termination</h3>
              <p>
                15.1. Either party may terminate this Agreement with 30 days’
                written notice.
                <br />
                <br />
                15.2. TradeDepot may terminate the Agreement immediately if the
                Customer breaches any terms or becomes insolvent.
                <br />
                <br />
                15.3. Upon termination, all outstanding payments become
                immediately due and payable.
              </p>
              <h3>16. Miscellaneous</h3>
              <p>
                16.1. These Terms and Conditions constitute the entire agreement
                between TradeDepot and the Customer.
                <br />
                <br />
                16.2. No waiver by TradeDepot of any breach of these terms shall
                be considered a waiver of any subsequent breach.
                <br />
                <br />
                16.3. If any provision of these terms is held to be invalid, the
                remaining provisions shall remain in full force and effect.
                <br />
                <br />
                For any inquiries or further information, please contact us at{" "}
                <a href='mailto:sales@tradedepot.co'>sales@tradedepot.co</a>.
              </p>
              <h3>17. Confirmation</h3>
              <p>
                By signing the registration form, the Customer confirms that the
                information provided is correct and agrees to be bound by these
                Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
        <WebsiteFooter isColored={true} countryCode={"NG"} />
      </div>
    </>
  );
}
