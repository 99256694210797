import { useNavigate } from "react-router-dom";
import ArrDown from "../assets/chevron-arrow-down.png";

export function Variants({
  seeMoreText = "See more",
  areProducts = false,
  isBrands = false,
  products = [],
  brands = [],
  handleSeeMore,
  showSeeMore = true,
}) {
  const navigate = useNavigate();

  return (
    <div className='products_variants_wrapper'>
      <div className='products-variants'>
        {isBrands
          ? brands.map((brand, key) => (
              <div
                className='variant'
                key={key}
                onClick={() => navigate(`/brand/${brand._id}`)}
              >
                <div className='image-container'>
                  <img
                    src={brand?.image?.fields?.file?.url}
                    alt={`${brand?.image?.fields?.title || "brand-img"}`}
                    className='img-fluid'
                  />
                </div>
                <div className='details'>
                  <div className='names'>
                    <h3>{brand?.name}</h3>
                    <h6 className='brand-see-more'>See more</h6>
                  </div>
                  <div className='chevron chevron-right' />
                </div>
              </div>
            ))
          : products.map((product, key) => (
              <div className='variant product' key={key}>
                <div className='image-container'>
                  <img
                    src={product?.fields?.productImage?.fields?.file?.url}
                    alt={`${
                      product?.fields?.productImage?.fields?.title ||
                      "product-img"
                    }`}
                    className='img-fluid'
                  />
                </div>
                <div className='details'>
                  <div className='names'>
                    <h3>{product?.fields?.productName}</h3>
                    <h6>{product?.fields?.description}</h6>
                  </div>
                </div>
              </div>
            ))}
      </div>
      <div className={`see-more-wrapper ${!showSeeMore ? "empty-gap" : ""}`}>
        {showSeeMore ? (
          <>
            <p className='see-more-text'>{seeMoreText}</p>
            <p className='down-arr' onClick={handleSeeMore}>
              <img src={ArrDown} alt='down' className='img-fluid' />
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
}
