import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./approuter";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Router />
    </SnackbarProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
