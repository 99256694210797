import { formatMoney } from "../utils/functions";

export function CreditContractModal({
  creditAmount,
  continueToPay,
  loading,
  loanBreakdown,
}) {
  return (
    <>
      <button
        className='trigger-button'
        id='trigger-credit-contract-button'
        data-bs-toggle='modal'
        data-bs-target='#creditContractModal'
        style={{ display: "none" }}
      >
        Trigger Credit Contract Modal
      </button>
      <div
        className='trade-checkout_credit-contract modal fade'
        id='creditContractModal'
      >
        {" "}
        <button
          type='button'
          class='btn-close'
          data-bs-dismiss='modal'
          aria-label='Close'
          id='credit-contract-close-btn'
          style={{ display: "none" }}
        >
          <i class='bi bi-x'></i>
        </button>
        <div className='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-body'>
              <div className='credit-confirmation-info'>
                <center>
                  <div className='wallet-wrapper'>
                    <img src='../images/wallet.svg' alt='' />
                  </div>
                </center>
                <h3>Credit Confirmation</h3>
                <h4>
                  Kindly authorize the draw down of{" "}
                  <span>₦{formatMoney(creditAmount)}</span> from your available
                  credit limit to pay for this order
                  <br />
                  <br />
                  This will update your total draw down to{" "}
                  <span>₦{loanBreakdown.loanAmount}</span>
                </h4>
                <h5>See repayment details below:</h5>
              </div>
              <div className='_repayment-breakdown'>
                <li>
                  <h3>Repayment amount</h3>
                  <h5>₦{loanBreakdown?.nextRepayment}</h5>
                </li>
                <li>
                  <h3>Payment Frequency</h3>
                  <h5>{loanBreakdown?.paymentFrequency}</h5>
                </li>
                <li>
                  <h3>No of repayments</h3>
                  <h5>{loanBreakdown?.installments}</h5>
                </li>
                <li>
                  <h3>Total repayment amount</h3>
                  <h5>₦{loanBreakdown?.repaymentAmount}</h5>
                </li>
                <li>
                  <h3>Start Date</h3>
                  <h5>{loanBreakdown?.startDate}</h5>
                </li>
                <li>
                  <h3>End Date</h3>
                  <h5>{loanBreakdown?.endDate}</h5>
                </li>
                <li>
                  <h3>effective monthly interest</h3>
                  <h5>{loanBreakdown?.monthlyInterest}%</h5>
                </li>
                <div className='_repayment-note'>
                  <i class='bi bi-info-circle'></i>
                  <p>
                    <b>Note: </b>The draw down will not take effect until your
                    order is delivered.
                  </p>
                </div>
                <div className='_terms-charge'>
                  <h4>
                    By clicking Accept, you agree to our{" "}
                    <a
                      href={`${window.location.origin}/terms`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms and Conditions{" "}
                    </a>
                    and{" "}
                    <a
                      href={`${window.location.origin}/privacy`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Statement
                    </a>
                  </h4>
                  <button
                    className='trade-uk-app-button _accept'
                    disabled={loading}
                    onClick={continueToPay}
                  >
                    {loading ? (
                      <div
                        class='spinner-border text-success app-spinner'
                        role='status'
                      >
                        <span class='sr-only'>Loading...</span>
                      </div>
                    ) : (
                      "Accept"
                    )}
                  </button>
                  <button
                    className='trade-uk-app-button _decline'
                    disabled={loading}
                    onClick={() =>
                      document
                        .getElementById("credit-contract-close-btn")
                        .click()
                    }
                  >
                    Decline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
