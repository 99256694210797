import { NewHeader } from "../../components/new-header";
import PartnerImgOne from "../../assets/Header_Partner.png";
import SubTextImg from "../../assets/Meeting couple.png";
import { NewFooter } from "../../components/new-footer";
import { useState } from "react";
import { Brands } from "../../components/brands";
import { Loading } from "../../components/loading-screen";

export function Partners() {
  const [hasError, setHasError] = useState(false);

  return (
    <Loading hasError={hasError}>
      <div className='td_new td_partners'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <div className='introduction'>
              <div className=''>
                <h3>Partner with Us</h3>
                <p>
                  Join forces with TradeDepot to expand your brand’s reach.
                  Connect with a global network of importers and distribution
                  partners, efficiently bringing your products to new markets.
                </p>
                <a href='#contactUs' className='td-btn-link'>
                  <button className='td-btn'>
                    <span>Join our network</span>
                    <span className='chevron chevron-right' />
                  </button>
                </a>
              </div>
              <div className=''>
                <img
                  src={PartnerImgOne}
                  alt='distributor-img'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='our-partners'>
              <h2>Our Partners</h2>
              <Brands isPartnersPage={true} setHasError={setHasError} />
            </div>
            <div className='sub-text'>
              <div className=''>
                <img src={SubTextImg} alt='' className='img-fluid' />
              </div>
              <div className=''>
                <h3>Why Partner with TradeDepot?</h3>
                <h6>
                  Partnering with TradeDepot provides you access to a growing
                  marketplace of distributors, importers, and retailers
                  worldwide. Our platform simplifies product distribution,
                  ensuring seamless entry into global markets and driving your
                  brand’s growth.
                </h6>
              </div>
            </div>
            <NewFooter isPartner />
          </div>
        </div>
      </div>
    </Loading>
  );
}
