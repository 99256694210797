import { useState } from "react";
import { CountryFilterPolicies } from "../../components/country-filter-policies";
import { NewHeader } from "../../components/new-header";
import { NGTerms, UKTerms } from "../../terms2";
import { NewFooter } from "../../components/new-footer";
import { SUPPORTED_COUNTRIES } from "../../utils/functions";

export function TermsAndConditions() {
  const [countryCode, setCountryCode] = useState("NG");

  return (
    <div className='td_new td_policy'>
      <div className='wrapper'>
        <NewHeader />
        <div className='body'>
          <div className='introduction'>
            <h2 className='title'>Terms of Service</h2>
            <h6>08.11.2024</h6>
            <hr />
            <CountryFilterPolicies
              countryCode={countryCode}
              page={"terms"}
              onSamePage={true}
              setCountryCode={setCountryCode}
            />
            <br />
            {/* <h5>Trade Depot Privacy Notice</h5> */}
          </div>
          {countryCode === SUPPORTED_COUNTRIES.NG ? <NGTerms /> : <UKTerms />}
        </div>
        <NewFooter />
      </div>
    </div>
  );
}
