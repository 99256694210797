import { useEffect, useRef, useState } from "react";
import TDLogo from "../assets/logo.svg";
import SearchIconSvg from "../assets/search.svg";
import { useNavigate } from "react-router-dom";
export function NewHeader() {
  const isOnSearchPage = window.location.pathname === "/search-results";
  const [showDesktopSearch, setShowDesktopSearch] = useState(isOnSearchPage);
  const searchValue = new URLSearchParams(window.location.search).get("q");
  const [searchString, setSearchString] = useState(searchValue || "");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDesktopSearch(false); // close search render
        if (document.getElementById("navbarScroll").classList.contains("show"))
          document.getElementById("mobile-navbar-scroll").click(); // close menu dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const checkShowClass = () => {
    const contentDiv = document.getElementById("navbarScroll");
    contentDiv.innerHTML = `
              <ul class='navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll' ref={dropdownRef}>
                <li class='nav-item'>
                  <a class='nav-link' href='/brands'>
                    Brands
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link' href='/partners'>
                    Partner with Us
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link' href='/distributors'>
                    Become a Distributor
                  </a>
                </li>
                <li class='nav-item'>
                  <a class='nav-link' href='/marketplace'>
                    Marketplace
                  </a>
                </li>
              </ul>
              <div class='search-bar'>
                <img
                  src={SearchIconSvg}
                  alt='search-icon'
                  class='img-fluid'
                  style={{ width: "25px" }}
                />
              </div>
            `;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter")
      window.location.replace(`/search-results?q=${event.target.value}`);
  };

  return (
    <div className='header-wrapper e'>
      <a
        href={process.env.REACT_APP_PROCUREMENT_LOGIN}
        rel='noreferrer'
        target='_blank'
        className='procurement-login-link'
      >
        Sign in <span className='chevron chevron-right' />
      </a>
      <nav className='navbar navbar-light navbar-expand-lg opaque-navbar header-container'>
        <div className='container-fluid header'>
          <a className='navbar-brand' href='/'>
            <img src={TDLogo} alt='td-logo' className='img-fluid' />
          </a>
          <div className='search-bar-mobile' style={{ display: "none" }}>
            <span
              onClick={() => {
                document.getElementById("open-toggle").click();
                const contentDiv = document.getElementById("navbarScroll");

                // Create the container div
                const searchContainer = document.createElement("div");
                searchContainer.classList.add("search-container");

                // Create the input element
                const searchInput = document.createElement("input");
                searchInput.classList.add("search-input");
                searchInput.setAttribute("placeholder", "Search...");
                searchInput.addEventListener("input", (event) =>
                  setSearchString(event.target.value)
                );
                searchInput.value = searchString;

                // Add the keydown event listener
                searchInput.addEventListener("keydown", handleKeyDown);

                // Create the chevron div
                const chevron = document.createElement("div");
                chevron.classList.add("chevron", "chevron-right");
                chevron.addEventListener("click", () => {
                  window.location.replace(
                    `/search-results?q=${searchInput.value}`
                  );
                });

                // Append the input and chevron to the container
                searchContainer.appendChild(searchInput);
                searchContainer.appendChild(chevron);

                contentDiv.innerHTML = "";
                contentDiv.appendChild(searchContainer);
              }}
            >
              <img
                src={SearchIconSvg}
                alt='search-icon'
                className='img-fluid'
                style={{ width: "30px" }}
              />
            </span>
            <button
              style={{ display: "none" }}
              id='open-toggle'
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarScroll'
              aria-controls='navbarScroll'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              Search Button Treigger
            </button>
            <button
              className='navbar-toggler'
              id='mobile-navbar-scroll'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarScroll'
              aria-controls='navbarScroll'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={checkShowClass}
            >
              <span className='navbar-toggler-icon'></span>
            </button>
          </div>
          {/* menu dropdown */}
          <div
            className='collapse navbar-collapse'
            id='navbarScroll'
            ref={dropdownRef}
          >
            <ul class='navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll'>
              <li class='nav-item'>
                <a class='nav-link' href='/brands'>
                  Brands
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='/partners'>
                  Partner with Us
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='/distributors'>
                  Become a Distributor
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='/marketplace'>
                  Marketplace
                </a>
              </li>
            </ul>
            <div
              class={`search-bar ${showDesktopSearch ? "show" : ""}`}
              onClick={() => {
                if (searchString)
                  return navigate(`/search-results?q=${searchString}`);
                return setShowDesktopSearch(!showDesktopSearch);
              }}
            >
              {showDesktopSearch ? (
                <input
                  className='form-control'
                  placeholder='Search...'
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={handleKeyDown}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              ) : null}
              <img
                src={SearchIconSvg}
                alt='search-icon'
                class='img-fluid'
                style={{ width: "25px" }}
              />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
