import { NewHeader } from "../../components/new-header";
import CircleSectionOne from "../../assets/circle-section-one.svg";
import Brands from "../../assets/Group 3716@2x.png";
import { NewFooter } from "../../components/new-footer";
import { Variants } from "../../components/variants";
import { PartnerComponent } from "../../components/partner";
import { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Loading } from "../../components/loading-screen";
import { useNavigate } from "react-router-dom";

export function FeaturedBrands() {
  const navigate = useNavigate();
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });
  const [brands, setBrands] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [starBrand, setStarBrand] = useState(null);
  const [loading, setLoading] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState({
    pages: 0,
    limit: 17,
    currentPage: 0,
  });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    handleFetchStarBrand();
    handleFetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchStarBrand = async () => {
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "brands",
      });
      let starBrand = res.items.find((item) => item.fields.isStarProduct);
      starBrand = {
        name: starBrand.fields.itemName,
        description: starBrand.fields.itemDescription,
        _id: starBrand.sys.id,
        image: starBrand.fields.brandLogo,
      };
      setStarBrand(starBrand);
      const pages = Math.ceil(res.total / paginationOptions.limit);
      setPaginationOptions({ ...paginationOptions, pages });
    } catch (error) {
      console.log(error, "fetching star brand");
      setHasError(true);
    }
  };

  const handleFetchBrands = async () => {
    setLoading(true);
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "brands",
      });
      let allBrands = res.items.map((item) => ({
        name: item.fields.itemName,
        description: item.fields.itemDescription,
        historyText: item.fields.brandHistoryText,
        _id: item.sys.id,
        successStory: item.fields.successStory,
        image: item.fields.brandLogo,
        featuredBrand: item.fields.isStarProduct,
        orderIndex: item.fields.orderIndex,
      }));
      allBrands = allBrands
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .filter((brand) => !brand.featuredBrand);
      setAllBrands(allBrands);
      setBrands(allBrands.slice(0, paginationOptions.limit));
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
      setHasError(true);
    }
  };

  const handleSeeMore = () => {
    setLoading(true);
    setTimeout(() => {
      appendMore();
      setLoading(false);
    }, 2000);

    function appendMore() {
      const newCurrentPage = paginationOptions.currentPage + 1;
      const nextBatch = allBrands.slice(
        brands.length,
        paginationOptions.limit * (newCurrentPage + 1)
      );
      setPaginationOptions((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }));
      setBrands([...brands, ...nextBatch]);
    }
  };

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_brands'>
        <div className='wrapper'>
          <NewHeader />
          <div className='body'>
            <div className=''>
              <img
                src={CircleSectionOne}
                alt='circle-section-one'
                className='img-fluid circle-section-one'
              />
            </div>
            <h1>Featured Brands</h1>
            <h3>
              Discover our portfolio of premium brands sourced from global
              manufacturers, tailored for diverse markets around the world.
            </h3>
            <div className='brands-img'>
              <img src={Brands} alt='brands' className='img-fluid brands-img' />
            </div>
            <div className='brand-dove-wrapper'>
              <div className='brand-dove row'>
                <div className='col-md-7 col-sm-12 brand-logo-view'>
                  <div className='star-product-container'>
                    <img
                      src={starBrand?.image?.fields?.file?.url}
                      alt={`${starBrand?.image?.fields?.title || "brand-img"}`}
                      className='img-fluid'
                    />
                  </div>
                </div>
                <div className='col-md-5 col-sm-12'>
                  {starBrand?.image?.fields?.file?.url ? (
                    <div className='image-h2'>
                      <img
                        src={starBrand?.image?.fields?.file?.url}
                        alt={`${
                          starBrand?.image?.fields?.title || "brand-img"
                        }`}
                        className='img-fluid'
                      />
                    </div>
                  ) : null}
                  <h2>{starBrand?.name}</h2>
                  <h4>{starBrand?.description}</h4>
                  <button
                    className='td-btn'
                    onClick={() => navigate(`/brand/${starBrand._id}`)}
                  >
                    <span>See more</span>
                    <div className='chevron chevron-right' />
                  </button>
                </div>
              </div>
            </div>
            <Variants
              brands={brands.sort((a, b) => a.orderIndex - b.orderIndex)}
              isBrands={true}
              handleSeeMore={handleSeeMore}
              showSeeMore={
                !(paginationOptions.pages === paginationOptions.currentPage + 1)
              }
            />
            <PartnerComponent />
            <NewFooter />
          </div>
        </div>
      </div>
    </Loading>
  );
}
