/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { WebsiteHeader } from "./components/website-header";
import { WebsiteFooter } from "./components/website-footer";
import { useLocation } from "react-router-dom";

export const LandingUk = () => {
  const location = useLocation();
  const USER_LOCATION =
    location?.pathname === "/en_gb"
      ? "UK"
      : location.pathname === "/en_ng"
      ? "NG"
      : "Global";
  return (
    <>
      <div className='trade-uk-landing'>
        <div className=''>
          <div className='trade-uk-landing__intro'>
            <WebsiteHeader />
            <div className='container-fluid'>
              <div className='row trade-uk-landing__intro_products'>
                <div className='col-12 d-block d-sm-none'>
                  <img src='images/uk-products-2.png' alt='' />
                </div>
                <div className='col-lg-12 col-xl-5 col-md-12 col-xs-12'>
                  <h1>Your Local, Global Marketplace</h1>
                  <p>
                    {/* Bringing the best global flavours straight to UK retailers.
                    Quality, Convenience, Variety & Ethical Sourcing - all under
                      one roof */}
                    Bringing the best global flavours straight to{" "}
                    {USER_LOCATION === "UK"
                      ? "UK retailers"
                      : "retailers everywhere"}
                    . Quality, Convenience, Variety & Ethical Sourcing - all
                    under one roof
                  </p>
                  <a className='button' href='#getapp'>
                    Get started
                  </a>
                </div>
                <div className='col-md-7 d-none d-sm-block'>
                  <img src='images/uk-products.png' alt='' />
                </div>
              </div>
            </div>
          </div>
          <div className='trade-uk-landing__brands'>
            <div className='container-fluid'>
              <center>
                <h1>
                  Hundreds of{" "}
                  {USER_LOCATION === "UK"
                    ? "Afro-Caribbean "
                    : "international "}
                  staples from brands you can trust.
                </h1>
              </center>
              <div className='trade-uk-landing__brands-imgs'>
                <p>
                  <img src='images/dufil.svg' alt='' />
                </p>
                <p>
                  <img src='images/nb.svg' alt='' />
                </p>
                <p>
                  <img src='images/guiness.svg' alt='' />
                </p>
                <p>
                  <img src='images/fmn.svg' alt='' />
                </p>
                <p>
                  <img src='images/nestle.svg' alt='' />
                </p>
                <p>
                  <img src='images/universal.svg' alt='' />
                </p>
              </div>
              <div className='trade-uk-landing__brands-imgs'>
                <p>
                  <img src='images/pz.svg' alt='' />
                </p>
                <p>
                  <img src='images/cardbury.svg' alt='' />
                </p>
                <p>
                  <img
                    src='images/friesland.png'
                    alt=''
                    style={{ width: "-webkit-fill-available" }}
                  />
                </p>
                <p>
                  <img src='images/nbc.svg' alt='' />
                </p>
                <p>
                  <img src='images/karibbean.svg' alt='' />
                </p>
                <p>
                  <img src='images/grace.svg' alt='' />
                </p>
              </div>
              <span>Popular brands on TradeDepot</span>
            </div>
          </div>
          <div className='trade-uk-landing__services'>
            <div className='trade-uk-landing__services_card-flex'>
              <div className='trade-uk-landing__services_card'>
                <div className='trade-uk-landing__services_card__title'>
                  <img src='images/range.svg' width='50' alt='' />
                  <h3>Wide Range</h3>
                </div>
                <p>
                  Everything’s available - from Nigerian staples to Caribbean
                  treats, your inventory will never be dull again
                </p>
              </div>
              <div className='trade-uk-landing__services_card'>
                <div className='trade-uk-landing__services_card__title'>
                  <img src='images/cart.svg' width='50' alt='' />
                  <h3>Effortless Ordering</h3>
                </div>
                <p>
                  Download the app, create an account and start stocking up your
                  shop in just a few clicks
                </p>
              </div>
              <div className='trade-uk-landing__services_card'>
                <div className='trade-uk-landing__services_card__title'>
                  <img src='images/delivery.svg' width='50' alt='' />
                  <h3>Dependable delivery</h3>
                </div>
                <p>
                  We guarantee fast and efficient delivery of your items
                  everytime
                </p>
              </div>
            </div>
          </div>
          <div className='trade-uk-landing__aboutus'>
            <img src='images/farmers.png' className='farmers-svg' alt='' />
            <div className='trade-uk-landing__aboutus-flex'>
              <div className='trade-uk-landing__aboutus_card'>
                <img src='images/handshake.svg' alt='' />
                <h3>Ethically Sourced</h3>
                <p>
                  We partner with leading brands for products that are
                  responsibly sourced from farm to shelf
                </p>
              </div>
              <div className='trade-uk-landing__aboutus_card'>
                <img src='images/range.svg' alt='' />
                <h3>Retailer Friendly</h3>
                <p>
                  No matter your business size, TradeDepot can cater to your
                  scale
                </p>
              </div>
              <div className='trade-uk-landing__aboutus_card'>
                <img src='images/cart.svg' alt='' />
                <h3>Always Stocked</h3>
                <p>
                  You no longer have to worry about supply issues, say goodbye
                  to empty shelves
                </p>
              </div>
              <div className='trade-uk-landing__aboutus_card'>
                <img src='images/competitive.svg' alt='' />
                <h3>Competitive Pricing</h3>
                <p>
                  Access great products at the most affordable wholesale prices
                </p>
              </div>
            </div>
            <div className='trade-uk-landing__aboutus-carousel'>
              <div id='carouselExampleCaptions' className='carousel slide'>
                <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <div className='trade-uk-landing__aboutus_card'>
                      <img src='images/handshake.svg' alt='' />
                      <h3>Ethically Sourced</h3>
                      <p>
                        We partner with leading brands for products that are
                        responsibly sourced from farm to shelf
                      </p>
                    </div>
                  </div>
                  <div className='carousel-item'>
                    <div className='trade-uk-landing__aboutus_card'>
                      <img src='images/range.svg' alt='' />
                      <h3>Retailer Friendly</h3>
                      <p>
                        No matter your business size, TradeDepot can cater to
                        your scale
                      </p>
                    </div>
                  </div>
                  <div className='carousel-item'>
                    <div className='trade-uk-landing__aboutus_card'>
                      <img src='images/cart.svg' alt='' />
                      <h3>Always Stocked</h3>
                      <p>
                        You no longer have to worry about supply issues, say
                        goodbye to empty shelves
                      </p>
                    </div>
                  </div>
                  <div className='carousel-item'>
                    <div className='trade-uk-landing__aboutus_card'>
                      <img src='images/competitive.svg' alt='' />
                      <h3>Competitive Pricing</h3>
                      <p>
                        Access great products at the most affordable wholesale
                        prices
                      </p>
                    </div>
                  </div>
                </div>
                <div className='carousel-indicators'>
                  <p
                    type='button'
                    data-bs-target='#carouselExampleCaptions'
                    data-bs-slide-to='0'
                    className='active'
                    aria-current='true'
                    aria-label='Slide 1'
                  />
                  <p
                    type='button'
                    data-bs-target='#carouselExampleCaptions'
                    data-bs-slide-to='1'
                    aria-label='Slide 2'
                  />
                  <p
                    type='button'
                    data-bs-target='#carouselExampleCaptions'
                    data-bs-slide-to='2'
                    aria-label='Slide 3'
                  />
                  <p
                    type='button'
                    data-bs-target='#carouselExampleCaptions'
                    data-bs-slide-to='3'
                    aria-label='Slide 4'
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className='trade-uk-landing__download'
            data-bs-spy='scroll'
            id='getapp'
          >
            <img src='images/phone-full.png' className='phone-img' alt='' />
            <div className='trade-uk-landing__download-app'>
              <h2>Get the app</h2>
              <p>
                Streamline your orders on the go with the TradeDepot Wholesale
                app - Quick easy, and always at your fingertips.
              </p>
              <div className='devices-downloads'>
                <a
                  href={process.env.REACT_APP_PLAY_STORE_DOWNLOAD_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='images/google-play-badge.png' alt='' />
                </a>
                <a
                  href={process.env.REACT_APP_APPLE_STORE_DOWNLOAD_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src='images/ios-app-badge.svg' alt='' />
                </a>
              </div>
            </div>
          </div>
          <div className='trade-uk-landing__faqs'>
            <center>
              <h2>Got Questions? We have Answers!</h2>
            </center>
            <div className='accordion' id='accordionExample'>
              <div className='accordion-item'>
                <button
                  className='accordion-button'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  Can individuals shop on TradeDepot Wholesale?
                </button>
                <div
                  id='collapseOne'
                  className='accordion-collapse collapse show'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    TradeDepot Wholesale is dedicated to serving registered
                    businesses. At this time, our platform is tailored for
                    business customers only.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  Where Is TradeDepot Wholesale Located?
                </button>
                <div
                  id='collapseTwo'
                  className='accordion-collapse collapse'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    We operate exclusively online, offering a seamless digital
                    shopping experience without physical storefronts.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  How do I place an order on TradeDepot Wholesale?
                </button>
                <div
                  id='collapseThree'
                  className='accordion-collapse collapse'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    Placing an order is easy! Simply <a>download our app</a>,
                    register your business, and you're set to start ordering
                    from our extensive product range.
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFour'
                  aria-expanded='false'
                  aria-controls='collapseFour'
                >
                  What's the Delivery Process for Orders?
                </button>
                <div
                  id='collapseFour'
                  className='accordion-collapse collapse'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    We deliver directly to your business location using reliable
                    courier services. For detailed information, visit our{" "}
                    <a href='/returns' target='_blank'>
                      Shipping & Returns
                    </a>{" "}
                    page .
                  </div>
                </div>
              </div>
              {/* <div className='accordion-item'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFive'
                  aria-expanded='false'
                  aria-controls='collapseFive'
                >
                  To what areas do you deliver?
                </button>
                <div
                  id='collapseFive'
                  className='accordion-collapse collapse'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    Currently, we deliver across Mainland UK. We're working on
                    expanding our reach, but international shipping isn't
                    available at the moment.
                  </div>
                </div>
              </div> */}
              <div className='accordion-item'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseSix'
                  aria-expanded='false'
                  aria-controls='collapseSix'
                >
                  What's Your Return Policy?
                </button>
                <div
                  id='collapseSix'
                  className='accordion-collapse collapse'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    Your satisfaction is important to us. If you need to return
                    an item, please refer to our{" "}
                    <a href='/returns' target='_blank'>
                      Shipping & Returns
                    </a>{" "}
                    page for the necessary steps.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <WebsiteFooter />
        </div>
      </div>
    </>
  );
};
