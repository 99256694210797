import { useNavigate } from "react-router-dom";
import { NewHeader } from "../../components/new-header";
import EmptyCan from "../../assets/empty can.png";

export function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className='td_new td_api_failed_page'>
      <div className='wrapper'>
        <NewHeader />
        <div className='body'>
          <h1>Error 404!</h1>
          <h3>
            This page does not exist. <br />
            Plese return to the home page
          </h3>
          <button className='td-btn' onClick={() => navigate("/")}>
            <span>Home</span>
            <div className='chevron chevron-right' />
          </button>
          <img src={EmptyCan} alt='empty-can' className='img-fluid empty-can' />
        </div>
      </div>
    </div>
  );
}
