import { NG_WHATSAPP_LINE, UK_WHATSAPP_LINE } from "../utils/functions";
import { useLocation } from "react-router-dom";

export function WebsiteHeader() {
  const location = useLocation();
  const USER_LOCATION =
    location?.pathname === "/en_gb"
      ? "UK"
      : location.pathname === "/en_ng"
      ? "NG"
      : "Global";
  return (
    <nav className='navbar navbar-light navbar-expand-lg opaque-navbar trade-uk-landing__nav'>
      <div className='container-fluid'>
        <a className='navbar-brand' href='/'>
          <img src='/images/logo-alt-black.svg' width='200' alt='' />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarScroll'
          aria-controls='navbarScroll'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarScroll'>
          <ul className='navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll'>
            <li className='nav-item'>
              <a
                className='nav-link'
                target='_blank'
                rel='noreferrer'
                href={
                  USER_LOCATION === "NG" ? NG_WHATSAPP_LINE : UK_WHATSAPP_LINE
                }
              >
                Contact us
              </a>
            </li>
            <li className='nav-item'>
              <a
                type='button'
                href='#getapp'
                className='trade-uk-landing__nav__getstarted'
              >
                Get started
                <img src='/images/circle-arr-down.svg' width='23' alt='' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
