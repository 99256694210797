import ReactPaginate from "react-paginate";

export const Pagination = ({ pages, limit, setCurrentPage }) => {
  return (
    <div className='custom_pagination controllers'>
      <ReactPaginate
        breakLabel='...'
        renderOnZeroPageCount={null}
        pageCount={Math.ceil(pages / limit)}
        onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        pageRangeDisplayed={1}
        previousLabel=<div className='chevron chevron-left'></div>
        nextLabel=<div className='chevron chevron-right'></div>
        disableInitialCallback={true}
        activeClassName='active'
      />
    </div>
  );
};
