import { CountryFilterPolicies } from "./components/country-filter-policies";
import { WebsiteHeader } from "./components/website-header";
import { WebsiteFooter } from "./components/website-footer";
import { SUPPORTED_COUNTRIES } from "./utils/functions";

export function TermsConditions() {
  const qParameters = new URLSearchParams(window.location.search);
  const countryCode = qParameters.get("country") || SUPPORTED_COUNTRIES.GB;

  return (
    <>
      <div className=''>
        <div className='trade-uk-landing__intro'>
          <WebsiteHeader />
        </div>
        <div className='trade-uk-terms'>
          <p className='trade-uk-terms__title'>Terms & Conditions</p>
          <div className='container'>
            <CountryFilterPolicies countryCode={countryCode} page='terms' />
            {countryCode === SUPPORTED_COUNTRIES.NG ? <NGTerms /> : <UKTerms />}
          </div>
        </div>
        <WebsiteFooter isColored={true} countryCode={countryCode} />
      </div>
    </>
  );
}

export const NGTerms = () => {
  return (
    <div className='trade-uk-terms__content'>
      <p>
        This Terms of Use (“Terms of Useˮ) is a legal agreement between you
        <b>
          (“Youˮ /“Yourˮ/ “TradeDepot Retailerˮ/ "Importer"/ "Distribution
          Partner"),
        </b>
        , and TradeDepot <b>(“TradeDepot” “Us” “Our” “We”)</b>. This Terms of
        Use (together with the Cash Advance Terms of Use and our Privacy Policy
        for the App, referred to as the
        <b> “Related Agreements”</b>) sets out the complete terms and conditions
        upon which You may use the TradeDepot Platform, which includes our B2B
        eCommerce services (the <b>"Platform"</b>) and website hosted at{" "}
        <a href='https://tradedepot.co' target='_blank' rel='noreferrer'>
          https://tradedepot.co
        </a>{" "}
        (the <b>“Website”</b>).
        <br /> <br />
        By signing up to create a profile{" "}
        <b>(“TradeDepot Wholesale Profile”)</b>, You acknowledge that You have
        read, understood, and agree to be bound by this Terms of Use, and the
        Related Agreements, where applicable. You also agree to comply with all
        agreements, applicable laws, and regulations incorporated into this
        Terms of Use. The provisions of this Terms of Use and the Related
        Agreements govern Your use of the Platform and the business relationship
        between You and TradeDepot. If You do not accept the terms of this
        Agreement and other Related Agreements, do not access or use this site
        or the services.
        <br /> <br />
        The Platform allows TradeDepot Retailers to purchase stock of Consumer
        Goods (the <b>“Products/Goods”</b>) using our TradeDepot Wallet, which
        is exclusive to retailers. For Importers and Distribution Partners, the
        Platform provides a comprehensive B2B eCommerce interface, including
        TradeDepot Gateway and punchout catalogs, for cross-border trade and
        procurement of Products (collectively referred to as the{" "}
        <b>“Servicesˮ</b>). Any new features or tools which are added to the
        Services shall also be subject to the Terms of Use.
        <br />
        <br />
        TradeDepot reserves the right to amend this Terms of Use and the Related
        Agreements at any time and will notify You of any such changes by
        posting the revised terms on the Platform. All changes shall be
        effective upon posting. You should check this Terms of Use and the
        Related Agreements periodically for such changes. Your continued use of
        the Platform after any such changes constitutes Your agreement to be
        bound by them. TradeDepot may terminate, suspend, change, or restrict
        access to all or any part of the Services without notice or liability.
      </p>
      <br />
      <p>
        You acknowledge and agree that Your use of the Services, including
        information transmitted to or stored by TradeDepot, is governed by Our
        Privacy Policy.
      </p>
      <h3>1. TRADEDEPOT PROFILE TERMS</h3>
      <p>
        1.1. You hereby declare that You are a human being over 18 years of age
        and of a sound mind, or at least the age of majority in the jurisdiction
        where You reside and from which You use the Services. Your TradeDepot
        Profile may be deleted without warning if We believe, in Our sole
        discretion, that You are less than 18 years of age.
        <br />
        <br />
        1.2. In order to create a TradeDepot Profile and access the Services,
        You would be required to register through the Platform. Alternatively,
        You may sign up for a TradeDepot Profile by registering through a
        verified TradeDepot representative (Verified Representative).
        <br />
        <br />
        1.3. In order to create a TradeDepot Profile on the Platform or through
        a Verified Representative, You would be required to provide the
        following: Your first name, last name, phone number, business name,
        password, business address, and any other information indicated as
        required or that We may ask from You from time to time{" "}
        <b>(“Informationˮ)</b>.
        <br />
        <br />
        1.4. When You create a TradeDepot Profile, You and Your employers or
        principals will be deemed as the contracting party and as a TradeDepot
        Retailer, Importer, or Distribution Partner for the purposes of this
        Terms of Use.
        <br />
        <br />
        1.5. We reserve the right to deactivate Your TradeDepot Profile on
        suspicion of illegal activities or other activities that directly or
        indirectly breach the provisions of this Terms of Use and the Related
        Agreements or for no reason at all, in our sole discretion.
        <br />
        <br />
        1.6. You acknowledge that TradeDepot will use the phone number You
        provided to Us when You created Your TradeDepot Profile or as updated by
        You from time to time as the primary mode of communicating with You.
        Your communications with TradeDepot can only be authenticated if they
        come from Your TradeDepot Profile phone number.
        <br />
        <br />
        1.7. As part of Our security procedures, You determine Your sign-up
        details or any Information You input on the Platform. By signing up for
        a TradeDepot Profile, You agree to treat Your sign-in details as
        confidential and not to disclose those details to any third party.
        TradeDepot does not have access to Your sign-in details. If You forget
        Your sign-in details or know or suspect that a third party has gained
        access to them, You must promptly change Your sign-in details by
        resetting Your password or by contacting Us to place a restriction on
        Your TradeDepot Profile.
        <br />
        <br />
        1.8. You confirm that all information You provide to Us is true,
        correct, and not misleading. You agree to provide any additional
        documents and/or information as may be required from time to time. In
        the event that the Information You provided to Us or a Verified
        Representative changes, You hereby agree to notify Us of such changes
        immediately.
        <br />
        <br />
        1.9. You consent to Us collecting and using technical information about
        the Platform and related software, hardware, and peripherals to improve
        Our products and to provide any other services to You. If You use the
        Services, You consent to Us and Our affiliates' and licensees'
        processing, transmission, collection, retention, maintenance, and use of
        Your personal data to improve Our Services and/or Your experience while
        using the Platform.
        <br />
        <br />
        1.10. By accessing the Platform, You hereby authorize Us to provide the
        Services herein contemplated.
      </p>
      <h3>2. TRADEDEPOT PROFILE ACTIVATION </h3>
      <p>
        2.1. Your TradeDepot Profile will be active as soon as We are able to
        verify Your Information and, where necessary, pay a visit to the address
        provided by You while signing up on the Platform.
      </p>
      <h3>3. TRADEDEPOT WHOLESALE WALLET</h3>
      <p>
        3.1. <b>For Retailers:</b> Your TradeDepot Profile (the “TradeDepot
        Wholesale Profileˮ) is accompanied by a unique digital TradeDepot
        Wholesale wallet (the “TradeDepot Wholesale Walletˮ). The unique account
        number for Your TradeDepot Wholesale Wallet is displayed on the “Add
        Moneyˮ tab on the Platform. You must add Your preferred bank account on
        the Platform.
        <br />
        <br />
        3.2. You would be able to fund Your TradeDepot Wholesale Wallet by: (i)
        Funding Your TradeDepot Wholesale Wallet through electronic transfer;
        (ii) Funding Your TradeDepot Wholesale Wallet using the Unstructured
        Supplementary Service Data USSD code displayed on the Platform; and
        (iii) Visiting the nearest mobile money agent or bank to deposit money
        into Your TradeDepot Wholesale Wallet through your unique account
        number.
        <br />
        <br />
        3.3. Please note that while accessing the Services, standard/approved
        banking charges may apply. It is Your responsibility to pay all fees, or
        charges as may be required from time to time.
        <br />
        <br />
        3.4. If there is a challenge with processing any amounts due to us
        through Your TradeDepot Wholesale Wallet, We may charge any other valid
        payment method associated with Your TradeDepot Profile.
        <br />
        <br />
        3.5. Please note that We may share Your Information with third parties
        to the extent required to provide the Services and for the efficient
        performance of the Platform.
      </p>
      <h3>4. PURCHASES AND PAYMENTS</h3>
      <p>
        4.1. There is a minimum order requirement (excluding delivery charges)
        for every order placed which would be disclosed to You at the time of
        Your purchase (the “Minimum Order Requirementˮ).
        <br />
        <br />
        4.2. Products featured on the Platform are subject to availability and
        prevailing market conditions. In the event of non-availability of any
        Products you order, You may browse the Platform for a list of Pay
        Partners near You or You may contact a Verified Representative on
        08005550555 to assist You with locating a Pay Partner closest to You,
        from whom You may purchase Products from. We reserve the right to
        discontinue the sale of any Product featured on the Platform at any
        time, and our only liability in such cases will be limited to a refund
        of any money paid to Us for Products which We may no longer be able to
        deliver to You (after any transfer charges).
        <br />
        <br />
        4.3. To purchase Products on the Platform, You would be required to
        select the Products You wish to purchase, the quantity of the Products
        and then add the selected Products to Your cart and click “Proceed to
        checkoutˮ (“Offerˮ). You will be guided through the "checkout" process
        by a series of instructions. If You want to correct any errors You have
        made, You can use the functionality on the Platform to go back and
        remove Products from Your order or empty Your cart and start the Offer
        process all over again. Alternatively, You may contact a Verified
        Representative on 08005550555 to make an Offer by providing the Verified
        Representative with information about the Products and the quantity of
        the Products You wish to purchase (“Telesales Offerˮ).
        <br />
        <br />
        4.4. Upon making an Offer, You may be required to pay for the Products
        with funds in Your TradeDepot Wholesale Wallet or to pay cash on
        delivery (if your profile allows it) of the Products to You. You may
        also utilize credit if your profile allows it.
        <br />
        <br />
        4.5. Your Offer or Telesales Offer is deemed accepted when You opt to
        pay for the Products on delivery of the Products to You or when You opt
        to pay for the Products through Your TradeDepot Wholesale Wallet or by
        credit and a complete payment is fully authorized by the payment gateway
        (“Acceptanceˮ).
        <br />
        <br />
        4.6. By opting to pay for the Products through Your TradeDepot Wholesale
        Wallet, You hereby authorize that automatic debit deductions be made in
        favor of TradeDepot from Your TradeDepot Wholesale Wallet for the price
        of the Products, applicable taxes, standard approved bank charges, and
        any fees incurred in relation to shipping of the Products to You.
        <br />
        <br />
        4.7. We reserve the right to adjust prices, Products, and special offers
        at our discretion. Deals, gift coupons, vouchers, and other related
        promotions (“Promotionsˮ) are exclusive and cannot be combined and may
        not be exchanged for cash. TradeDepot reserves the right to discontinue
        Promotions at any time without any prior notice.
        <br />
        <br />
        4.8. The following shall apply to a TradeDepot Retailer who purchases
        Products from a Pay Partner:
        <li>
          Identify yourself as a TradeDepot Retailer to enable a Pay Partner to
          verify Your identity.
        </li>
        <li>
          After a Pay Partner has verified Your Identity, proceed to shop for
          Products.
        </li>
        <li>
          As soon as You are done selecting Products You wish to buy, present
          the Products to a Pay Partner.
        </li>
        <li>
          A Pay Partner would calculate the cost of the Products You selected
          and input the total amount of the Products in the “amountˮ field on
          the Pay Partnerʼs app
        </li>
        <li>
          You are required to confirm the accuracy of the total amount charged
          by a Pay Partner and thereafter proceed to make payment using the
          relevant payment and validation options available.
        </li>
        <li>
          The total amount charged by a Pay Partner would be debited from Your
          TradeDepot Wholesale Wallet or your credit.
        </li>
        <li>
          TradeDepot bears no liability for the price and/or quality of Products
          purchased from Pay Partners or from the Platform.
        </li>
        <br />
        4.9. By authorizing payment for Products You bought from a Pay Partner,
        You hereby authorize that automatic debit deductions be made in favor of
        TradeDepot from Your TradeDepot Wholesale Wallet, for the price of the
        Products, including applicable taxes, standard approved bank charge, and
        any fees incurred in relation to shipping of the Products to You.
      </p>
      <h3>5. RISK, DELIVERY TERMS, AND OTHER FEES</h3>
      <p>
        5.1. Risk of loss and damage of Products passes to You at the time You
        pick up the Products or at the time the Products are delivered to You or
        are left with a person or in a place nominated by You (“Deliveryˮ). We
        shall not be responsible for any loss or damage caused to the Products
        after Delivery.
        <br />
        <br />
        5.2. All Deliveries are subject to delivery charges as communicated to
        You by Us.
        <br />
        <br />
        5.3. We use reasonable efforts to ensure that Your Products are
        delivered on the delivery date provided in Your delivery confirmation
        message. However, please note the delivery dates provided by Us are only
        Our best estimates and the actual delivery of Your Products may be later
        than the date provided. TradeDepot gives no guarantee or undertaking as
        to dates of dispatch or Delivery and shall accept no liability for any
        loss resulting to You from late Delivery of the Products.
        <br />
        <br />
        5.4. In the event that Products are returned undelivered or refused at
        the time of Delivery, You hereby agree that We reserve the right to
        charge the full delivery charges for the back and forth movements of the
        Products and any other applicable fee and transaction charges.
        <br />
        <br />
        5.5. Please note that Products featured on the Platform are for
        illustration purposes only. Upon pickup or delivery of the Products, the
        Products may look different than is shown in the image. TradeDepot bears
        no liability for differences in shape, color, or size of the Products.
        <br />
        <br />
        5.6. You hereby agree that We are not liable for the quality of the
        Products delivered to You, such as, but not limited to, taste, texture,
        color, etc. While We are willing to escalate any Product-related
        complaint to the manufacturer, We are not responsible for the resolution
        of such complaints.
        <br />
        <br />
        5.7. You hereby agree and give Your consent to the sharing of Your
        Information with third parties to the extent required to provide the
        Services, where we consider necessary to protect and/or advance our
        legitimate interests and for the efficient performance of the Platform.
      </p>
      <h3>6. CASH ADVANCES</h3>
      <p>
        6.1. If You are interested in obtaining cash advances from TradeDepot,
        please refer to the Cash Advance Terms & Conditions in this Agreement.
        <br />
        <br />
        6.2. As a Cash Advance Plus Customer, You will also be required to pay
        all costs associated with Our delivery of the PoS Terminal to You,
        should we choose to deliver a PoS Terminal to You.
        <br />
        <br />
        6.3. We and/or Our payment partners may charge transaction fees, in
        connection with the transactions carried on within your TradeDepot
        Wholesale Wallet and from time to time amend or vary the transaction
        fees. If You are a Cash Plus Advance Customer, transactions on Your PoS
        Terminal shall be subject to a transaction charge for every transaction
        on the PoS Terminal. The relevant transaction charge shall be notified
        to You at the time of Your application for a Cash Advance. We will use
        reasonable endeavors to notify You of any changes in relation to
        transaction charges.
        <br />
        <br />
        6.4. You shall be responsible to pay the costs associated with
        registration of our security interests over the Products at the relevant
        movable asset registry.
        <br />
        <br />
        6.5. If You do not repay the Cash Advance, as and when due, You shall be
        liable to pay Us a late payment fee of up to 48% Forty-Eight Percent)
        of the total amount due and outstanding on the Cash Advance together
        with any outstanding Cash Advance Interest (<b>“Late Payment Feesˮ</b>).
        <br />
        <br />
        6.6. You shall be responsible for all legal costs and expenses incurred
        by Us in connection with any enforcement action taken by Us towards the
        collection and repayment of the Cash Advance and all amounts due and
        outstanding on any Cash Advance granted to You, including the costs of
        repossession of any Products and the legal expenses incurred towards
        filing a legal action against You for the recovery of any amounts due to
        Us.
      </p>
      <h3>7. SECURITY</h3>
      <p>
        7.1. As security for our advance of the Cash Advance to You, You hereby
        as Chargor, grants to Us, as Chargee, by way of a hypothecation, for Our
        benefit and Our lenders or assigns, a first priority security interest
        in:
        <br />
        <br />
        <li style={{ listStyle: "lower-roman", marginBottom: "5px" }}>
          all the Products which constitutes the inventory/stock of Your
          business hereto now owned or hereafter acquired by You or Your
          business (collectively, the <b>“Charged Interestsˮ</b>),
        </li>
        <li style={{ listStyle: "lower-roman", marginBottom: "5px" }}>
          all other property hereafter delivered to, or in the possession or in
          Chargorʼs custody, in substitution for or in addition to the Charged
          Interests,
        </li>
        <li style={{ listStyle: "lower-roman", marginBottom: "5px" }}>
          any other property of Chargor, now or hereafter delivered to, or in
          the possession or custody of Chargor, and
        </li>
        <li style={{ listStyle: "lower-roman", marginBottom: "5px" }}>
          all proceeds from the sale of the Products, as collateral security
          for:
        </li>
        <div>
          <li style={{ listStyle: "lower-alpha", marginBottom: "5px" }}>
            the prompt and complete payment when due (whether at the stated
            maturity, by acceleration or otherwise) of all amounts outstanding
            and unpaid relating to the Cash Advance, the Cash Advance Interest;
            and
          </li>
          <li style={{ listStyle: "lower-alpha", marginBottom: "5px" }}>
            the due and punctual payment and performance by You of Your
            obligations and liabilities under, arising out of or in connection
            with this Agreement. You agree that the security hereby created
            gives TradeDepot the right to a repossession of the Products or any
            other products in Your store/warehouse, including any
            store/warehouse controlled by You, at the time of any repossession
            by Us where you fail to comply with the terms of this Agreement or
            when any amount is due and unpaid by You in connection with a Cash
            Advance.
          </li>
        </div>
        <br />
        7.2. As additional security for the Cash Advance hereby granted, You
        hereby convey, assign, transfer the contractual and property rights to
        repossess without a Court Order, any and all Products in Your custody at
        the time of default, (<b>“Repossessed Propertyˮ</b>) upon Your failure
        to repay the Cash Advance or any amount due and outstanding on the Cash
        Advance or upon the occurrence of an Event of Default. You waive any
        rights to sue Us or any of our recovery agents for trespass, breach of
        privacy, breach of data privacy or breach of peace or any other legal
        rights You may have under any law, in so far as the actions taken by Us
        or Our recovery agents are directed towards recovery of all amounts due
        and outstanding on the Cash Advance granted to You. You hereby waive the
        rights to any notice or judicial or court hearing relating to Our
        exercise of the rights to repossession herein granted. You agree that we
        may sell, lease or otherwise dispose of the Repossessed Property and
        that We may apply what We receive from the disposition of the
        Repossessed Property to Our expenses, legal expenses and the outstanding
        amounts on the Cash Advance granted to You. You agree that if the
        Repossessed Products are perishables or threatens to decline speedily in
        value, We may, without notice to You, dispose of any or all such
        Repossessed Property in a commercially reasonable manner and at Your
        expense.
        <br />
        <br />
        7.3. You shall deposit with TradeDepot post dated cheques for the amount
        of installments or the complete amount as agreed. These cheques shall be
        presented to the bank by TradeDepot when there is an overdue payment and
        in TradeDepotʼs opinion, efforts attempted to receive payments have
        failed.
        <br />
        <br />
        The cheques will be from the corporate account of the business. Under
        special circumstances, personal cheques or cheques from guarantors may
        be accepted
        <br />
        <br />
        You hereby authorize Trade Depot to present the cheques for payment if
        required. You confirm that these cheques have not been provided under
        duress but as an obligation to be fulfilled to safeguard against default
      </p>
      <h3>8. OUR RIGHT AND NOTICE TO ASSIGN TO THIRD PARTIES</h3>
      <p>
        8.1. Trade Depot reserves the rights to assign the amount of any Cash
        Advance herein granted to You (including the amount of any Cash Advance
        Interest and any related security, rights, obligations, interests or
        receivables) whereof TradeDepot hereby notifies You of the transfer and
        assignment of all its rights, title to the Cash Advance hereby granted
        to You, (including the amount of any Cash Advance Interest and any
        related security, rights, obligations or interests), to TD Capital SPC
        acting for the account and on behalf of Segregated Portfolio, SP1 (the{" "}
        <b>“Buyerˮ</b>) (<b>“Notice of Assignmentˮ</b>). By accepting Our offer
        of Cash Advance, You hereby acknowledge receipt of this Notice of
        Assignment and affirm that You have not received notice that any other
        person has an interest in the Cash Advance herein granted to You.
        Notwithstanding the foregoing, You will continue to be liable to pay all
        amounts due and outstanding, including any Cash Advance Interest in
        accordance with the provisions Agreement or as required by TradeDepot
        from time to time. You consent irrevocably and unconditionally to our
        provision to the Buyer of all information relating to the Cash Advance
        hereby granted to You including all your personal and business
        information provided at the time of registration on the App and at the
        time that You applied for a Cash Advance, as may from time to time be
        requested by the Buyer or its agents.
      </p>
      <h3>9. REPAYMENT OF CASH ADVANCE, CASH ADVANCE INTEREST AND LATE FEES</h3>
      <p>
        9.1. All repayments of the Cash Advance and the Cash Advance Interest
        are to be made through the App or/and as provided for in clause
        9.1(i)-(iv) or in the case of a Cash Advance Plus Customer, through the
        PoS Terminal, the POS account or as agreed with You. You may repay all
        amounts due and outstanding on the Cash Advance hereby granted to You
        by:
        <br />
        <br />
        9.1.1. Paying all amounts due and outstanding on the Cash Advance
        including the Cash Advance Interest and any Late Fees, through the
        Payment Service Provider platform integrated within the App.
        <br />
        <br />
        9.1.2. Funding Your TradeDepot Wholesale Wallet with all amounts due and
        outstanding on the Cash Advance including the Cash Advance Interest and
        any Late Fees, through electronic transfer to Your Unique Account Number
        to enable direct debits to be made in favor of TradeDepot towards Your
        repayment of the Cash Advance extended to You by TradeDepot.
        <br />
        <br />
        9.1.3. Funding Your TradeDepot Wholesale Wallet with all amounts due and
        outstanding on the Cash Advance including the Cash Advance Interest and
        any Late Fees, by using the Unstructured Supplementary Service Data
        USSD code displayed on Your online profile on the App, to enable
        direct debits to be made in favor of TradeDepot towards Your repayment
        of the Cash Advance extended to You by TradeDepot.
        <br />
        <br />
        9.1.4. Visiting the nearest mobile money agent or bank to deposit money
        into Your Unique Account Number, to enable direct debits to be made from
        Your TradeDepot Wholesale Wallet in favor of TradeDepot for the
        repayment of the Cash Advance extended to You by TradeDepot.
        <br />
        <br />
        9.2. You hereby agree and give Your consent to the sharing of Your
        Information with third parties to the extent required to ensure
        repayment of the Cash Advance You obtained from Us.
        <br />
        <br />
        9.3. By obtaining a Cash Advance from TradeDepot, You hereby authorize
        that daily automatic debit deductions be made in favor of TradeDepot
        from Your TradeDepot Wholesale Wallet or the PoS Terminal, towards the
        repayment of the Cash Advance, Cash Advance Interest, Late Fees,
        applicable taxes, standard bank charges, and any fees incurred in
        relation to the grant of the Cash Advance.
        <br />
        <br />
        9.4. All payments to be made by You under this Agreement shall be made
        in full without any set off or counterclaim and save in so far as
        required by the law to the contrary, free and clear of and without any
        deduction or withholding whatsoever. If You are at any time required to
        make any deduction or withholding from any payment to TradeDepot, You
        shall immediately pay to TradeDepot such additional amounts as will
        result in TradeDepot receiving the full amount it would have received
        had no such deduction or withholding been required.
      </p>
      <h3>10. EVENTS OF DEFAULT</h3>
      <p>
        10.1. An event of default (<b>Event of Default</b>) occurs when:
        <br />
        <br />
        10.1.1. You fail to pay any sum payable pursuant to the Cash Advance
        granted to You in accordance with a scheduled repayment, for any reason
        at all.
        <br />
        <br />
        10.1.2. You fail to pay the Cash Advance Interest in accordance with a
        scheduled repayment.
        <br />
        <br />
        10.1.3. Any representation/information, warranty or assurance made or
        given by you in connection with the application for Cash Advance or any
        information or documentation supplied by you is incorrect, inaccurate or
        misleading.
        <br />
        <br />
        10.1.4. You do anything which may prejudice TradeDepotʼs rights under
        this Agreement.
        <br />
        <br />
        10.1.5. You use or attempt to use the App for any unauthorized purpose.
        <br />
        <br />
        10.1.6. You are declared bankrupt, insolvent or unable to pay Your debts
        as they fall due under the laws of Nigeria.
        <br />
        <br />
        10.1.7. As a Cash Advance Plus Customer, You fail for any reason at all
        to meet the usage covenant that shows the volume of transactions, either
        through the use of the PoS Terminal or transfers to the POS account on
        any day during the Term or during which any amount due and payable by
        You in connection with a Cash Advance is outstanding and unsettled.
        <br />
        <br />
        10.1.8. You fail to
        <br />
        <li style={{ listStyle: "lower-alpha" }}>
          fully comply with any trainings provided to You regarding the use of
          the POS Terminal or Your Shop Top-Up Wallet
        </li>
        <li style={{ listStyle: "lower-alpha" }}>
          fully comply with any instruction manuals provided to You in
          connection with the operation of the POS Terminal
        </li>
        <li style={{ listStyle: "lower-alpha" }}>
          immediately notify TradeDepot in the event of a damage, destruction or
          malfunction of a PoS Terminal.
        </li>
        <br />
        10.1.9. Where the POS Terminal is destroyed or damaged during the time
        when it is within Your possession or use.
        <br />
        <br />
        10.1.10. Where You fail to pay for the replacement or repair a POS
        Terminal which malfunctions, is destroyed or damaged during the time
        when it is within Your possession or use.
        <br />
        <br />
        10.1.11. Where we are unable to make the relevant daily deductions of
        the amounts due to Us through funds in your POS account, through a PoS
        Terminal or transfer or where Our ability to collect the Electronic
        Payments or any amounts due to Us is hampered in any way.
        <br />
        <br />
        10.1.12. You fail to inform TradeDepot of any outstanding debt/loan You
        owe to Your 3rd Party PoS Terminal provider. 10.2. At any time after an
        Event of Default has occurred which is continuing, TradeDepot may,
        without prejudice to any other right or remedy granted to it under any
        law:
        <br />
        <br />
        10.2. At any time after an Event of Default has occurred which is
        continuing, TradeDepot may, without prejudice to any other right or
        remedy granted to it under any law:
        <br />
        <br />
        10.2.1. terminate this Agreement immediately;
        <br />
        <br />
        10.2.2. declare that the Cash Advance (and all accrued Cash Advance
        Interest and all other amounts outstanding under this Agreement is
        immediately due and payable, whereupon they shall become immediately due
        and payable; and
        <br />
        <br />
        10.2.3. supply information concerning the Event of Default to credit
        bureaus licensed to hold such Cash Advance default information in
        Nigeria. A copy of any adverse information concerning You sent to a
        credit bureau shall be made available to You upon written request.
        <br />
        <br />
        10.3. TradeDepot, whether acting for itself or for any of its assigns,
        reserves the right to assign its right, title and interest under the
        Agreement, including the right to sue for any unpaid amounts due and
        outstanding on the Cash Advance, to an external collections agency or to
        any third party who will take all reasonable steps to collect the
        amounts outstanding on any Cash Advance (together with any Cash Advance
        Interest, Late Fees and fees accrued), and in this regard, TradeDepot
        shall have the right to share with such collection agencies, any
        information about the Borrower which it deems relevant. Third parties
        engaged by Us towards the recollection of any outstanding amounts may
        share Your information with other entities or contact Your family
        members, friends, guarantors in a bid to recover all amounts outstanding
        and Trade Depot will not be held liable for any misuse of the data. You
        hereby consent to the processing of Your personal data in this manner
        and for this purpose.
        <br />
        <br />
        10.4. TradeDepot also reserves the right to institute legal proceedings
        for the recovery of any amounts due and outstanding against You without
        any prior notice.
        <br />
        <br />
        10.5. In the event that an Event of Default occurs, You grant to
        TradeDepot the right to notify You and any other person or third party
        related or affiliated to You, who, in Trade Depotʼs opinion, may assist
        with the recovery of the outstanding amounts on the Cash Advance granted
        to You. You agree that this notification may be done by any means of
        communication which TradeDepot deems appropriate. By signing this
        Agreement, You hereby consent to the processing of Your personal data in
        this manner and waive any legal standing or defenses You may have in
        that regard.
      </p>
      <h3>11. STATEMENTS</h3>
      <p>
        11.1. . A statement and activity report in respect of the Cash Advance
        granted to You will be made available on request. Requests shall be made
        via our e-mail address:{" "}
        <a href='mailto:enquiries@tradedepot.co'>enquiries@tradedepot.co</a> or
        via a contact link on the App.
        <br />
        <br />
        11.2. The statement on the App shall provide details of the last 4
        (four) transactions (or such other number of transactions as determined
        by TradeDepot) on Your Cash Advance account initiated from Your device.
        Your statement will show all amounts added or taken from Your Cash
        Advance account. You must check Your statement carefully and notify
        TradeDepot as soon as possible, and no later than 48 hours after receipt
        of Your statement, if it includes any transaction or other entry which
        appears to You to be wrong or not made in accordance with Your
        instructions.
        <br />
        <br />
        11.3. TradeDepot reserves the right to rectify discrepancies, add and/or
        alter the entries in Your statements, without prior notice to You.
        <br />
        <br />
        11.4. Save for a manifest error, a statement issued to You in respect of
        Your Cash Advance shall be conclusive evidence of the transactions
        carried out on Your Cash Advance account for the period covered in the
        statement. .
      </p>
      <h3>12. Taxes</h3>
      <p>
        12.1. All payments to be made by You in connection with this Agreement
        or any Cash Advance provided to You by TradeDepot are calculated without
        regard to any taxes payable by You in Nigeria. If any taxes are payable
        in connection with the repayments of the Cash Advance granted to You,
        You must ensure that You pay such taxes and that You pay to TradeDepot
        such additional amounts as will result in TradeDepot receiving the full
        amount it would have received had no such taxes been applicable to the
        payments.
        <br />
        <br />
        12.2. You hereby consent and agree that TradeDepot may withhold amounts
        in Your TradeDepot Wholesale Wallet if any tax authority in Nigeria
        requires TradeDepot to do so, or TradeDepot is otherwise required by law
        or pursuant to agreements with any tax authority to do so, or if
        TradeDepot needs to comply with internal policies or with any applicable
        order or sanction of a tax authority in Nigeria. You will, however, be
        notified if such deductions are made.
        <br />
        <br />
        12.3. You acknowledge that to the full extent permitted by law,
        TradeDepot shall not be liable for any unauthorized drawing, debit,
        transfer, remittance, disclosure, any activity or any incident on your
        TradeDepot Wholesale Wallet or online profile by the fact of the
        knowledge and/or use or manipulation of Your TradeDepot Wholesale
        profile, Your TradeDepot Wholesale Wallet, personal identification
        number PIN, password, ID or any means whether or not occasioned by
        Your negligence or willful misconduct.
        <br />
        <br />
        12.4. You hereby agree and authorize TradeDepot to obtain and procure
        Your Personal Data from the Cash Advance bureaus and You further agree
        and consent to the disclosure and provision of such Personal Data by the
        Cash Advance bureaus.
        <br />
        <br />
        12.5. TradeDepot reserves the right to request for further information
        from You pertaining to Your application. Failure to provide such
        information within the time required by TradeDepot may result in
        TradeDepot declining to accept Your application for a Cash Advance.
        <br />
        <br />
        12.6. TradeDepot reserves the right to share information related to any
        Cash Advance shared with You and other personal information about You to
        credit bureaus. You consent and agree that TradeDepot may also seek
        information on Your credit worthiness from any such credit bureaus.
      </p>
      <h3>13. VARIATION AND TERMINATION</h3>
      <p>
        13.1. TradeDepot may at any time, upon notice to You, terminate or vary
        its business relationship with You and close Your TradeDepot Wholesale
        Wallet/Profile and in particular but without prejudice to the generality
        of the foregoing, TradeDepot may cancel a Cash Advance which it has
        granted and require the repayment of outstanding debts resulting from
        such Cash Advance within such time as TradeDepot may determine.
        <br />
        <br />
        13.2. Without prejudice to Trade Depotʼs rights under clause 13.1,
        TradeDepot may at its sole discretion suspend Cash Advance to You or
        close your TradeDepot Wholesale Wallet if:
        <br />
        <br />
        13.2.1. You use the Cash Advance or Your TradeDepot Wholesale Wallet for
        unauthorized purposes or where TradeDepot detects any abuse/misuse,
        breach of content, fraud or attempted fraud relating to Your use of the
        App;
        <br />
        <br />
        13.2.2. Your agreement with a mobile network operator is terminated for
        whatever reason; or
        <br />
        <br />
        13.2.3. TradeDepot is required or requested to comply with an order or
        instruction of or a recommendation from the government, court, regulator
        or other competent authority.
        <br />
        <br />
        13.2.4. TradeDepot reasonably suspects or believes that You are in
        breach of this Agreement (including non-payment of any Cash Advance
        amount due from You where applicable) which You fail to remedy (if
        remediable) within 14 days after the service of notice by email, SMS or
        other electronic means requiring You to do so.
        <br />
        <br />
        13.2.5. Such a suspension or variation is necessary as a consequence of
        technical problems or for reasons of safety; to facilitate update or
        upgrade the contents or functionality of the App from time to time; or
        where Your TradeDepot Wholesale profile on the App or Your TradeDepot
        Wholesale Wallet becomes inactive or dormant.
        <br />
        <br />
        13.2.6. TradeDepot decides to suspend or cease the provision of the App
        or Cash Advance for commercial reasons, to protect its legitimate
        interests or for any other reason as it may determine in its absolute
        discretion.
        <br />
        <br />
        13.3. Termination shall however not affect any accrued rights of
        TradeDepot.
        <br />
        <br />
        13.4. If TradeDepot receives notice of Your demise, TradeDepot shall not
        be obliged to allow any operation or withdrawal from Your TradeDepot
        Wholesale Wallet by any person except upon production of administration
        letters from a competent authority or confirmed grant of letters of
        administration or confirmed grant of probate by Your legal
        representatives duly appointed by a court of competent jurisdiction and
        upon our recovery of any amounts due and outstanding to Us.
      </p>
      <h3>14. INDEMNITY</h3>
      <p>
        14.1. In consideration of Our grant of the Cash Advance, You agree to
        indemnify TradeDepot and it assigns, agents, employees and hold them
        harmless against any loss, charge, damage, expense, fee or claim which
        TradeDepot suffers or incurs or sustains thereby as a result of your
        breach of this Agreement, any demands, claims, actions, losses and
        damages of whatever nature which may be brought against TradeDepot by
        You or by any third party ( including regulators) or which We may suffer
        or incur arising from the grant of the Cash Advance, Your use of a PoS
        Terminal, Our enforcement of this Agreement or the response to any
        regulatory requests. You hereby absolve TradeDepot from all liability
        for loss or damage which You may sustain from TradeDepot acting in
        accordance with this Agreement.
      </p>
      <br />
      <h3>GENERAL</h3>
      <h3>15. OUR COMPLAINT HANDLING PROCEDURES</h3>
      <p>
        15.1. Meeting and exceeding our clients' expectations is the most
        important part of our business. However, there may be occasions when
        something goes wrong and leaves you dissatisfied. If this happens,
        please let us know and weʼll do everything We can to resolve the problem
        with You. Our aim is to always resolve such matters as soon as We can
        and to Your satisfaction.
        <br />
        <br />
        15.2. If You believe something has gone wrong and You would like to make
        a formal complaint, please get in touch and let Us know by providing as
        much detail about the issue as You can. This can be done by either
        <li style={{ listStyle: "lower-alpha" }}>
          Sending us an email at{" "}
          <a href='mailto:enquiries@tradedepot.co'>enquiries@tradedepot.co</a>.
        </li>
        <li style={{ listStyle: "lower-alpha" }}>
          Calling our customer care number:{" "}
          <a href='tel:08005550555'>08005550555</a>
        </li>
        <li style={{ listStyle: "lower-alpha" }}>
          Writing to us at: ¾ Adewunmi Industrial Estate, Kudirat Abiola Way,
          Oregun Lagos
        </li>
        <li style={{ listStyle: "lower-alpha" }}>
          Reaching us on our whatsapp line on{" "}
          <a
            href='https://wa.me/+2348175551555'
            target='_blank'
            rel='noreferrer'
          >
            08175551555
          </a>
        </li>
        <br />
        15.3. Once We have received Your complaint, We will begin our
        investigation into what has happened. We will always aim to resolve the
        issue as soon as possible. Once Our investigation into an issue is
        complete, will We send You our final response to Your complaint.
      </p>
      <h3>16. ENTIRE AGREEMENT</h3>
      <p>
        16.1. This Terms of Use and, where applicable the other Related
        Agreements constitute the entire agreement between You and Us and
        supersede and extinguish all previous agreements, promises, assurances,
        warranties, representations and understandings between Us, whether
        written or oral, relating to its subject matter.
        <br />
        <br />
        16.2. You acknowledge that You do not rely on any statement,
        representation, assurance or warranty (whether made innocently or
        negligently) that is not referenced or set out in this Terms of Use and
        the Related Agreements.
      </p>
      <h3>17. FORCE MAJEURE</h3>
      <p>
        17.1. TradeDepot shall not be in breach of this Terms of Use nor liable
        for delay in performing, or failure to perform, any of its obligations
        under this Terms of Use if such delay or failure results from events,
        circumstances or causes beyond Our control. In such circumstances the
        time for performance shall be extended by a period equivalent to the
        period during which performance of the obligation has been delayed or
        failed to be performed.
      </p>
      <h3>18. ASSIGNMENT</h3>
      <p>
        18.1. You shall not assign, transfer or novate any of Your rights and
        obligations under this Terms of Use or any Related Agreements, without
        the written consent of TradeDepot.
      </p>
      <h3>19. SEVERANCE</h3>
      <p>
        19.1. If any provision of this Terms of Use and the Related Agreements
        is determined by any court of competent jurisdiction or arbitrator to be
        invalid, illegal, or unenforceable to any extent, that provision shall,
        if possible, be construed as though more narrowly drawn, if a narrower
        construction would avoid such invalidity, illegality, or
        unenforceability or, if that is not possible, such provision shall, to
        the extent of such invalidity, illegality, or unenforceability, be
        severed, and the remaining provisions of this Terms of Use and the
        Related Agreements shall remain in effect.
      </p>
      <h3>20. SEVERANCE</h3>
      <p>
        20.1. This Terms of Use and the Related Agreements shall not be
        construed as creating any partnership, joint venture, agency or similar
        relationship between you and TradeDepot other than the relationship with
        respect to carrying out the terms of this Terms of Use and the Related
        Agreements. All rights, duties, obligations, and liabilities of
        TradeDepot and you shall be separate, individual and several and not
        joint or joint and several. Except as set forth in this Terms of Use and
        the Related Agreements, you have no authority to act as agent for
        TradeDepot or bind TradeDepot in any way.
        <br />
        <br />
        For general enquiries regarding this Terms of Use, please feel free to
        contact us at{" "}
        <a href='mailto:enquiries@tradedepot.co'>
          enquiries@tradedepot.co
        </a> or{" "}
        <a href='mailto:hello@tradedepotwholesale.com'>
          hello@tradedepotwholesale.com
        </a>
        .
      </p>
      <h3>21. REMEDIES CUMULATIVE</h3>
      <p>
        21.1. No failure on the part of TradeDepot to exercise, or delay on its
        part in exercising, any right, power or remedy provided by this
        Agreement or by law shall operate as a waiver thereof, nor shall any
        single or partial exercise of any such right, power or remedy preclude
        any further or other exercise of that, or any other, right, power or
        remedy.
      </p>
      <h3>22. NO WAIVER</h3>
      <p>
        22.1. No failure by TradeDepot to exercise, and no delay in exercising,
        any right or remedy in respect of any provision of this Agreement shall
        operate as a waiver of such right or remedy.
      </p>
      <h3>23. GOVERNING LAW & DISPUTE RESOLUTION</h3>
      <p>
        23.1. This Terms of Use and the Related Agreements is made under,
        governed by and to be construed in accordance with the laws of the
        Federal Republic of Nigeria. All disputes related to the Terms of Use
        and Related Agreements, shall be submitted for resolution by the
        relevant courts in Nigeria.
      </p>
      <h3>24. BEWARE OF SCAM</h3>
      <p>
        24.1. Scammers are getting increasingly sophisticated in their attempts
        to have access to Your money, Your financial information and other
        personal information. It is Your obligation to keep Your Information and
        sign -in details safe. Do not share Your TradeDepot Wholesale
        Profile/Wallet details with anyone.
        <br />
        <br />
        24.2. TradeDepot would never:
        <br />
        <br />
        24.2.1. Send You suspicious texts, pop-up windows, links or attachments
        in emails. If You receive any suspicious email/ text purporting to be
        from TradeDepot kindly disregard the email and proceed to inform
        TradeDepot by clicking the “Helpˮ tab on the App.
        <br />
        <br />
        24.2.2. Request Your sign-in details for the purpose of investing the
        funds in Your TradeDepot Wholesale Wallet on Your behalf, and receiving
        free Product/Product trials.
        <br />
        <br />
        24.2.3. Have a tech support call You to inform You of viruses detected
        on Your device, and requesting that you send a screenshot of your QR
        code, click a link or provide Your sign-in details or payment PIN for
        the purpose of providing antivirus software to You.
        <br />
        <br />
        24.2.4. Send an email, SMS, or call You or leave a voice message
        requesting You repay the Cash Advance you obtained from TradeDepot into
        an account provided by the caller or in the SMS, threatening that,
        unless funds are wired immediately, legal action will be taken or you'll
        be arrested. Kindly click here to find out how a Cash Advance obtained
        from TradeDepot can be re-paid.
        <br />
        <br />
        24.2.5. Send an email, SMS, or call You or leave a voice message
        informing you about a refund and requesting that You to provide Your
        sign-in details or payment PIN to claim such refund.
        <br />
        <br />
        24.2.6. Send an email, SMS, or call You or leave a voice message
        informing You that TradeDepot is issuing payment cards and requesting
        personal and financial information, or asking that you click on link
        before being issued a payment card.
        <br />
        <br />
        24.2.7. Send an email, SMS, or call You or leave a voice message
        requesting that you make donations to a church, mosque, charity cause or
        any non-governmental organization.
        <br />
        <br />
        24.2.8. Send an email, SMS, or call You or leave a voice message
        offering You a free gift card and requesting that You provide Your
        sign-in details, payment PIN or click a link to receive the free gift
        card.
        <br />
        <br />
        24.2.9. Request that you provide Your sign -in details, payment PIN to
        dispatch riders or any third party.
        <br />
        <br />
        24.2.10. Ask any third party to request for a Cash Advance on Your
        behalf.
        <br />
        <br />
        24.3. TradeDepot shall bear no liability for any loss of business, loss
        of business opportunity, loss of revenue, loss of profits, loss of
        anticipated savings, loss of goodwill, business interruption, wasted
        expenditure or for loss of any other economic advantage however it may
        arise, or for loss of data or data corruption, or for any indirect,
        punitive, special, incidental or consequential loss, arising as a result
        of the events contemplated in clauses 24.1 - 24.2(i)-(x).
        <br />
        <br />
        24.4. In the event that You suspect that You may have fallen victim to
        scammers, You are obliged to immediately contact TradeDepot to place a
        restriction on Your TradeDepot Wholesale Wallet. You shall be
        responsible for such charges and for any transactions that occurred on
        Your TradeDepot Wholesale Profile/Wallet as a result of the information
        or access You granted to scammers. You hereby agree to indemnify and
        hold TradeDepot and its affiliates harmless for any losses, liabilities,
        damages, costs and charges arising out of or in relation to any
        transactions that occurred on Your TradeDepot Wholesale Profile/Wallet
        prior to placing a restriction on Your TradeDepot Wholesale Wallet.
        <br />
        <br />
        24.5. TradeDepot takes reasonable measures in securing all
        communications. However, please note that the confidentiality of
        communications via any public telecommunications network is susceptible
        to unlawful interception and hacking. TradeDepot shall not be liable for
        any loss or damage whether direct or consequential arising out of any
        such unlawful interception or access.
      </p>
      <h4 className='last-modified'>Last Revised: 8th November, 2024</h4>
    </div>
  );
};

export const UKTerms = () => {
  return (
    <div className='trade-uk-terms__content'>
      <h3>1. INTRODUCTION</h3>
      <p>
        1.1. These Terms and Conditions, together with our Privacy Policy and
        Shipping and Returns Policy, outline the terms on which you may use
        TradeDepotʼs Platform (the Platform), including but not limited to the
        Global B2B Marketplace, Mobile App, Web App, and WhatsApp Service,
        whether as a guest or a registered user. Use of the TradeDepot Platform
        includes accessing, browsing, or registering to use the Global B2B
        Marketplace, Mobile App or Web App.
        <br />
        <br />
        1.2. Please read these Terms and Conditions carefully before you start
        to use the TradeDepot Platform. If you do not agree to these Terms and
        Conditions, you must not use the Mobile App, Web App, or WhatsApp
        Service.
        <br />
        <br />
        1.3. TradeDepot adheres to a strict set of Terms & Conditions. When
        ordering from TradeDepot for commercial purposes, Consumer Regulations
        do not apply, and the Customer agrees to be bound by the following
        Business Terms & Conditions.
        <br />
        <br />
        1.4. The following terminology applies to these Terms and Conditions,
        Privacy Policy, Shipping and Returns policy, and any or all Agreements:
        "Client", “Customerˮ, “Youˮ, and “Yourˮ refers to you, the person
        accessing the Platform and accepting the Companyʼs terms and conditions.
        “Frontier Africa Trading Co Ltdʼʼ, “Frontier Africaʼʼ, “TradeDepotˮ,
        “The Company", “Ourselvesˮ, “Weˮ, “Ourˮ, and "Us" refers to our Company,
        TradeDepot Inc, or any of its subsidiaries. “Partyˮ, “Partiesˮ, or “Usˮ
        refers to both the Client and ourselves, or either the Client or
        ourselves. Any use of the above terminology or other words in the
        singular, plural, capitalization, and/or he/she or they, are taken as
        interchangeable and therefore as referring to the same.
      </p>
      <h3>2. About Us</h3>
      <p>
        2.1. TradeDepotʼs Platform is operated by TradeDepot Inc, a company
        registered in the United States, whose registered address is 1390 Market
        Street, San Francisco, California 94102.
      </p>
      <h3>3. Changes to these terms</h3>
      <p>
        3.1. We reserve the right to change these terms and conditions from time
        to time in our absolute discretion in order to comply with the operation
        of law, regulation, guidance, a decision of the court, or equivalent
        body, or as we otherwise see fit. Check this page from time to time to
        take notice of any changes we may make, as they are binding on you. We
        may update the Mobile App, Web App, and WhatsApp Service from time to
        time, and may change the content at any time.
      </p>
      <h3>4. Accessing TradeDepot Wholesale</h3>
      <p>
        4.1. You hereby declare that you are a human being over the age of 18
        years and of sound mind. Your TradeDepot profile may be deleted without
        notice if we believe, in our sole discretion, that you are less than 18
        years of age.
        <br />
        <br />
        4.2. The TradeDepot Mobile App and Web App are made available free of
        charge. You can download the app from the Google Play Store or the Apple
        App Store and accept the license agreement. You are responsible for
        making all arrangements necessary to access TradeDepot.
        <br />
        <br />
        4.3. We do not guarantee that TradeDepot, or any content on it, will
        always be available or uninterrupted and do not guarantee that they or
        any content on it will be free from errors or omissions. Access to
        TradeDepot is permitted on a temporary basis. We may suspend, withdraw,
        discontinue, or change all or any part of TradeDepot without notice. We
        will not be liable to you if, for any reason, TradeDepot is unavailable
        at any time or for any period.
        <br />
        <br />
        4.4. You are also responsible for ensuring that all persons who access
        TradeDepot through your internet connection are aware of these Terms and
        Conditions and other applicable terms and conditions, and that they
        comply with them.
        <br />
        <br />
        4.5. TradeDepot is directed to businesses globally, with specific
        features for different markets. We do not represent that content
        available on or through TradeDepot is appropriate or available in all
        locations. If you choose to access TradeDepot from outside your country
        of registration, you do so at your own risk.
      </p>
      <h3>5. Your account and password</h3>
      <p>
        5.1. If you choose, or you are provided with, a customer account,
        password, pin, passcode, or any other piece of information as part of
        our security procedures, you must treat such information as
        confidential. You must not disclose it to any third party.
        <br />
        <br />
        5.2. We have the right to disable any customer account or password,
        whether chosen by you or allocated by us, at any time if, in our
        reasonable opinion, you have failed to comply with any of the provisions
        of these Terms and Conditions.
        <br />
        <br />
        5.3. If you know or suspect that your account details, password, pin, or
        passcode are no longer confidential, you must reset your password, pin,
        or passcode and promptly notify us by sending an email or via the chat
        function in the Mobile App or Web App.
      </p>
      <h3>6. Intellectual property rights</h3>
      <p>
        6.1. TradeDepot is the owner or the licensee of all intellectual
        property rights in the Mobile App, Web App, and in the material
        published on it.
        <br />
        <br />
        6.2. You must not modify any materials you have printed off or
        downloaded in any way, and you must not use any illustrations,
        photographs, video or audio sequences, or any graphics separately from
        any accompanying text.
        <br />
        <br />
        6.3. You must not use any part of the content on TradeDepot for
        commercial purposes other than to purchase our products and services.
      </p>
      <h3>7. Acceptable use</h3>
      <p>
        7.1. Access to our product listings and prices is limited to registered
        customers only and is confidential. You may not disclose this
        information to any third party.
        <br />
        <br />
        7.2. You may not use the TradeDepot Mobile App, Web App, or WhatsApp
        Service:
        <br />
        <br />
        7.2.1. In any way that breaches any applicable local, national, or
        international law or regulation;
        <br />
        <br />
        7.2.2. In any way that is unlawful or fraudulent, or has any unlawful or
        fraudulent purpose or effect;
        <br />
        <br />
        7.2.3. To knowingly transmit any data, send, or upload any material that
        contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
        spyware, adware, or any other harmful programs or similar computer code
        designed to adversely affect the operation of any computer software or
        hardware;
        <br />
        <br />
        7.2.4. To reproduce, duplicate, copy, display, or sell any part of
        TradeDepot or its content, or use any content other than for the
        purchase of our products and services. You may not use any data mining,
        data robots, or any other similar tools for any purpose;
        <br />
        <br />
        7.2.5. Access without authority, interfere with, damage, or disrupt any
        part of the TradeDepot Mobile App or Web App, any equipment or network
        on which TradeDepot is stored, or any software used in the provision of
        TradeDepot.
        <br />
        <br />
        7.3 In the event of a breach of these Terms and Conditions, we may, at
        our discretion, withdraw your right to use TradeDepot and take any
        action against you we deem appropriate.
      </p>
      <h3>8. Sales Conditions and Customers</h3>
      <p>
        8.1. The following Conditions are the only conditions on which We sell
        goods. Please ensure You understand them before You place an order or
        buy Goods from Us. Unless otherwise mutually agreed in writing they will
        apply notwithstanding any other terms on which You wish to purchase
        Goods from Us. Our representatives are not authorised to agree to any
        other conditions or to vary these Conditions.
        <br />
        <br />
        8.2. These Conditions supersede any previous conditions between you and
        us (whether oral or in writing) and also supersede any previous course
        of dealing, trade custom, or understanding between you and us.
        <br />
        <br />
        8.3. In these Conditions, “Goodsˮ means all goods sold by us to you.
        “Contractˮ means the contract between us and you for the sale and
        purchase of goods in accordance with these Conditions.
        <br />
        <br />
        8.4. These Conditions cover sales of goods to you where an order is
        placed via the TradeDepot Mobile App, Web App, or WhatsApp Service for
        delivery of goods. You can use our app to place an order by selecting
        the goods you wish to buy and adding them to your app cart. Goods you do
        not require can be removed from your cart at any time. Your order will
        be completed on the app by checking out the items in your cart and
        choosing your appropriate delivery and payment method.
        <br />
        <br />
        8.5. Sales transactions through the TradeDepot Mobile App, Web App, or
        WhatsApp Service are fulfilled on the agreement that you are not a
        consumer. This means that you are accessing our platform and shall enter
        a contract with us by requesting the supply of products as part of your
        business, and your deliveries will be made to a business address.
        <br />
        <br />
        8.6. We reserve the right to refuse your custom, suspend sale or
        delivery, or cancel any order in the following circumstances or events:
        <br />
        <br />
        8.6.1. That circumstances arise which, in our view, oblige us to do so;
        <br />
        <br />
        8.6.2. That our accounts are not paid to terms;
        <br />
        <br />
        8.6.3. That payments are not received from a registered customer or
        other authorized payment provider or agent;
        <br />
        <br />
        8.6.4. Your insolvency or that you cease or threaten to cease business
        or enter into voluntary arrangement or become bankrupt;
        <br />
        <br />
        8.6.5. Our inability, through shortage of stocks or for any other
        reason, to execute delivery wholly or in part; and
        <br />
        <br />
        8.6.6. In our absolute discretion, if there is a risk that to proceed
        with such activity may result in a breach of any law, rule, or
        regulation of any jurisdiction including but not limited to bribery,
        corruption, money-laundering, terrorist financing, or the breach of any
        sanctions-related laws or rules including any economic, financial, or
        trade laws or regulations enacted or enforced by any jurisdiction.
        <br />
        <br />
        8.7. We reserve the right to make reasonable alterations to products
        and/or packaging as circumstances may demand. We will not be liable for
        costs, damages, losses, or expenses of any nature resulting from part
        delivery, suspension of delivery, reduction, or cancellation of orders.
        <br />
        <br />
        8.8. In the event we suspend sale or delivery or refuse to accept orders
        placed by you due to one of the events in clause 8.6, then without
        limiting any other rights available to us, any goods that have been
        delivered but not paid for shall become immediately due for payment
        notwithstanding any previous agreement or arrangement to the contrary.
        <br />
        <br />
        8.9. Where you are a sole trader, you will be personally liable to make
        payment for goods and you hereby guarantee and acknowledge personal
        liability for payment of the goods and interest chargeable in accordance
        with these Conditions, and no waiver that might be extended in respect
        of these Conditions shall affect your liability hereunder. In the event
        you become a partnership or limited company, then you will still remain
        personally jointly and/or severally liable for payments.
        <br />
        <br />
        8.10. Where you are a partnership or a limited company, an individual or
        individuals with authority to bind you will be personally liable to make
        payment for goods and interest chargeable in accordance with these
        Conditions, and no waiver that might be extended in respect of these
        Conditions shall affect your liability hereunder. Where you are a
        partnership, these Conditions are binding on all of the partners with
        joint and several liability and no waiver shall affect their individual
        liability. You shall be obliged to notify us of any change in the
        partners of your business.
        <br />
        <br />
        8.11. We may collect information from you in order to prevent and detect
        fraud, money laundering, and other crime.
        <br />
        <br />
        8.12. We reserve the right to take any action that is required by us in
        order to comply with any law, regulation (including guidance from
        regulators), or an order of the court. This includes but is not limited
        to bribery, corruption, money- laundering, terrorist financing, or the
        breach of any sanctions-related laws or rules, including any economic,
        financial, or trade laws or regulations enacted or enforced by any
        jurisdiction.
      </p>
      <h3>9. Prices</h3>
      <p>
        9.1. Prices for our goods are displayed on the Platform where
        applicable. All prices are subject to validation and are considered
        final only upon order confirmation by TradeDepot and receipt of payment
        from you. The confirmed prices will be reflected on the final approved
        order and in your invoice.
        <br />
        <br />
        9.2. If you have been charged an incorrect price, we reserve the right
        to rectify our invoice, provided only that any claim in respect of
        incorrect prices is brought to our attention in writing no later than
        one working day from the date of the invoice.
        <br />
        <br />
        9.3. We reserve the right to alter prices without notice to reflect
        increases in costs to us.
        <br />
        <br />
        9.4. Prices are displayed in the applicable currency for the country of
        your registration and exclusive of VAT. VAT will be charged on the
        supply of goods at the rate prevailing at the tax point date.
        <br />
        <br />
        9.5. Delivery charges and a processing fee might also be charged and
        will be shown on the Platform prior to you placing your order.
      </p>
      <h3>10. Orders, Delivery and Acceptance</h3>
      <p>
        10.1. We reserve the right to refuse orders for any reason, including
        (but not limited to) those considered economically non-viable, or
        hazardous to our vehicles or drivers, or where we have reason to believe
        that you are involved in illegal activity, or as required by the
        operation of law, regulation, guidance, or a decision of the court or
        equivalent body. There is no Contract between us and you until we
        confirm acceptance of your order. Once an order is accepted by us, we
        specifically reserve the right to cancel or suspend an order in the
        event of non- payment for any goods previously ordered by you. Each
        order is a separate contract, and you are responsible for ensuring the
        accuracy of the order submitted and for giving us all the information we
        need to process the order.
        <br />
        <br />
        10.2. <b>Minimum Order Values:</b> Minimum order values for goods
        (excluding VAT are in effect and can be seen on our Mobile App, Web
        App, or WhatsApp Service.
        <br />
        <br />
        10.3. <b>Delivery Charges:</b> You will be charged for delivery of goods
        at the point of placing your order on our Mobile App, Web App, or
        WhatsApp Service. Delivery charges are displayed on TradeDepotʼs
        Platform.
        <br />
        <br />
        10.4. <b>Order Cancellation:</b> Once an order is created on our
        platform, you will be unable to cancel it. Once the goods are received,
        you can initiate the returns process for the unwanted items. Please
        refer to our Shipping and Returns Policy for more information.
        <br />
        <br />
        10.5. All delivery dates quoted or agreed by us are estimates and are
        not of the essence of the contract between us. Delivery of the goods
        shall be completed upon the goods' arrival at your specified business
        address.
        <br />
        <br />
        10.6. You have 7 calendar days from the date of delivery to examine all
        goods delivered to you. You will not have any claim in respect of the
        goods once this time has elapsed.
      </p>
      <h3>11. Property and Risk</h3>
      <p>
        11.1. . Risk in the Goods shall pass to You at the time of delivery of
        the Goods to You or Your agent or when You have paid in full for the
        Goods, whichever is the earlier and You hereby undertake to insure the
        Goods to their full value for all risks and liability including fire and
        theft.
        <br />
        <br />
        11.2. Title in the Goods will only pass to You when We have received
        cleared payment in full for the Goods plus VAT and/or any other payment
        howsoever due to Us from You.
        <br />
        <br />
        11.3. . Until all payments due for the Goods have been received:
        <br />
        <br />
        11.3.1. . full legal and equitable title shall remain with Us and
        <br />
        <br />
        11.3.2. You shall hold the Goods as bailee and shall be required to
        store the Goods in such a manner as to preserve their value, in such a
        way that they may be clearly identified as Our property and separate
        from Your own property or the property of any other person or entity and
        <br />
        <br />
        11.3.3. Although the Goods remain Our property until paid for, they are
        at Your risk from the time of delivery and You shall hold the proceeds
        of any insurance against loss or damage as trustee for Us and
        <br />
        <br />
        11.3.4. We may trace any proceeds of sale of our Goods into any account
        which You maintain and
        <br />
        <br />
        11.3.5. You must not assign any rights arising from the sale of such
        Goods without Our consent.
        <br />
        <br />
        11.4. If You breach clause 11.3 then We are entitled to compensatory
        damages but You may resell or Use the Goods on the condition that as
        long as You have not paid in full for the price of those Goods, You
        shall be able to account to Us with the proceeds of the sale of those
        Goods which, shall be kept in a separate account. If You sell the Goods
        before You have paid for them in full, You sell them as principal and
        not as Our agent and title to the Goods shall pass from Us to You
        immediately before the time at which You sell them.
        <br />
        <br />
        11.5. . If payment is overdue in whole or in part, or upon commencement
        of any proceedings for insolvency, We may (without prejudice to any of
        Our other rights) recover or recall the Goods or any of them and We (and
        Our agents) may enter upon Your premises or any premises where the Goods
        are stored for such purpose and You hereby grant Us (and Our agents) a
        licence to enter upon any premises occupied or controlled by You so as
        to recover or inspect such Goods, title to which has not passed to You
        (irrespective of whether such Goods can be specified, or attributed to
        corresponding purchase orders or delivery notes and without regard to
        sub-clause 12.3 below) to the value of the amount due and payable.
        <br />
        <br />
        11.6. You may not for any indebtedness pledge or in any way charge by
        way of security (including without limitation fixed and floating
        charges) any of the Goods which remain Our property. Without prejudice
        to Our other rights, if You do so, all sums owing by You to Us shall
        forthwith become due and payable.
        <br />
        <br />
        11.7. Your right to possession of the Goods shall cease if upon the
        presentation to a bank of any cheque tendered in respect of payment for
        Goods it is returned or dishonoured or You have not paid for the Goods
        in full by the expiry of a credit period allowed by Us under a Credit
        Sales Agreement or You are declared bankrupt or make any proposal to
        Your creditors for a composition or other voluntary arrangement or a
        receiver, liquidator or administrator is appointed in respect of Your
        business. If Your right to possession of the Goods ceases pursuant to
        this clause, You shall at Your own expense make the Goods available to
        Us and allow us to repossess them.
      </p>
      <h3>12. Credit and Payment</h3>
      <p>
        12.1. . You may apply for but We are not obliged to give You the ability
        to make payment for Goods by credit for a specified time period and
        financial limit (the ‘Relevant Applicable Credit Limitʼ) and subject to
        such other terms as We agree.
        <br />
        <br />
        12.2. You must give true and accurate information on any application for
        a credit facility and acknowledge and agree to Us processing Your data
        in accordance with Our Privacy Policy including sharing Your information
        and data with credit reference agencies. Where You provide Us with
        information in relation to Your business partners or directors then You
        must obtain their consent to disclose this information to Us.
        <br />
        <br />
        12.3. We may at any time in Our discretion and without notice, vary Your
        Relevant Applicable Credit Limit or withdraw any credit or cheque
        payment facility.
        <br />
        <br />
        12.4. Unless a credit limit has been extended to You, cash payment is
        required at the point of placing an order on Our Web or Mobile app. An
        invoice will be available for download on our Mobile and Web application
        once your items have been delivered. Credit payments are in accordance
        with the specific credit terms agreement and will be payable on the date
        specified on any invoice. Time of payment is of the essence.
        <br />
        <br />
        12.5. If at any time any invoices are overdue or Your indebtedness to Us
        exceeds the Relevant Applicable Credit Limit then all unpaid balances
        owing to Us from You shall become immediately due and payable.
        <br />
        12.6. If You fail to make any payment due to Us in accordance with these
        terms then You shall pay interest on the overdue amount at the rate of
        4% per annum above the Bank of Englandʼs base rate chargeable both
        before and after any proceedings on the amount unpaid accruing on a
        daily basis, until payment is made in full. You shall pay the interest
        together with the overdue amount.
        <br />
        <br />
        12.7. We may also charge You interest at the rate prescribed by and
        compensation due in accordance with the Late Payment of Commercial Debts
        Interest) Act 1998 and the Late Payment of Commercial Debts Regulations
        2002.
        <br />
        <br />
        12.8. You shall pay all and any costs and expenses incurred by Us in
        connection with and/or in relation to the recovery of debt, sums and or
        damages from You including but not limited to the costs of issuing a
        formal letter before action and any legal costs incurred in relation to
        actual or prospective legal proceedings by Us against You.
        <br />
        <br />
        12.9. You shall pay all amounts due under the Contract in full without
        any set-off, counterclaim, deduction or withholding (except for any
        deduction or withholding required by law). We may at any time, without
        limiting any other rights or remedies We may have, set off any amount
        owing to Us by You against any amount payable by the Us to You.
        <br />
        <br />
        12.10. We reserve the right to withdraw any credit facilities and
        suspend deliveries if circumstances arise which in Our view oblige Us to
        do so or as required by the operation of law, regulation, guidance or a
        decision of the court or equivalent body.
      </p>
      <h3>13. Open Date Marketing</h3>
      <p>
        13.1. No Goods are offered on a sale or return basis. Our
        representatives are not authorised to accept orders on a sale or return
        basis. It is therefore Your responsibility to sell the Goods prior to
        the expiry of any “sell byˮ, “best beforeˮ, “use byˮ or similar date.
        <br />
        <br />
        13.2. We reserve the right to amend the specification of any Goods if
        required by any applicable statutory or regulatory requirements.
      </p>
      <h3>14. Consumer Complaints</h3>
      <p>
        14.1. Any consumer complaint concerning any of Our Goods must be
        referred directly to Us and We will manage it. We will not accept
        responsibility for any payment made by You to a consumer in settlement
        of any such claim.
      </p>
      <h3>15. Warranties and Liabilities</h3>
      <p>
        15.1. Nothing in these Conditions shall affect Our implied undertakings
        given to You under S12 Sale of Goods Act 1979 or Your statutory rights
        under the Agriculture Act 1970 (as amended).
        <br />
        <br />
        15.2. Nothing in these Conditions shall exclude or limit Our liability
        for death or personal injury resulting from Our negligence, fraud or
        fraudulent misrepresentation or defective products under the Consumer
        Protection Act 1987.
        <br />
        <br />
        15.3. All Goods are warranted by Us on delivery to comply with all
        relevant UK food law from time to time in force. No warranty is given
        that the Goods comply with food and other relevant legislation or do not
        infringe third party rights outside the UK. We warrant that the Goods
        are sold with good title and comply with Our description and
        specification of them.
        <br />
        <br />
        15.4. Save as set out in clauses 15.1 to 15.3, all conditions,
        warranties, guarantees and representations (both innocent and negligent)
        whether express or implied by law custom or trade or otherwise are
        excluded.
        <br />
        <br />
        15.5. Our liability to You under or in any way related to the sale and
        purchase of Goods or otherwise whether involving Our negligence or not
        shall only extend to giving an appropriate credit for or repayment of
        the price paid by You for defective Goods. Subject to clauses 15.1 to
        15.3 We shall not be liable, whether in contract, tort (including
        negligence), breach of statutory duty or otherwise for any loss of
        profit, or any indirect or consequential loss arising under or in
        connection with the Contract, any loss of turnover or of goodwill, or
        any damage to Your property.
        <br />
        <br />
        15.6. Nothing in these conditions shall affect Your duty to mitigate
        Your losses. Save as set out above We shall not have any liability
        whatsoever to You under or if any way related to the sale and purchase
        of the Goods or otherwise (whether in contract, tort (including without
        limitation negligence) or by way of statutory duty) for any claims, loss
        or damage of any nature whatsoever including without limitation
        consequential losses of any nature whatsoever.
        <br />
        <br />
        15.7. You shall indemnify Us in full for all liability, loss, damages,
        costs and expenses (including legal expenses) awarded against or
        incurred by or paid by Us as a result of or in connection with any
        breach or non-compliance with any of the terms of these Conditions.
        <br />
        <br />
        15.8. You shall at all times supply full and accurate information as
        required by us relating to Your business, Your personal details and any
        personal details of Your agents and representatives.
        <br />
        <br />
        15.9. You warrant and represent that You shall comply with all laws,
        enactments, regulations, regulatory policies, guidelines and industry
        codes applicable to You in ordering and purchasing product from Us and
        shall maintain such authorisations and all other approvals, permits and
        authorities as are required from time to time to perform Your
        obligations under or in connection with the order and purchase of Our
        products including but not limited to;
        <br />
        <br />
        15.9.1. all laws, rules and regulations of any jurisdiction relating to
        bribery and corruption;
        <br />
        <br />
        15.9.2. all economic sanctions, financial or trade laws, regulations,
        embargoes or restricted measures administered, enacted or enforced by
        the Office of Foreign Assets Control of the US Department of State, or
        by the United Nations Security Council or the European Union.
        <br />
        <br />
        15.10. Nothing in these Terms of Use excludes or limits our liability
        for death or personal injury arising from our negligence, or our fraud
        or fraudulent misrepresentation, or any other liability that cannot be
        excluded or limited by English law. To the extent permitted by law, we
        exclude all conditions, warranties, representations or other terms which
        may apply to TradeDepot Wholesale or any content on it, whether express
        or implied.
        <br />
        <br />
        15.11. We will not be liable to any user for any loss or damage, whether
        in contract, tort (including negligence), breach of statutory duty, or
        otherwise, arising under or in connection with use of, or inability to
        use TradeDepot Wholesale, use of or reliance on any content displayed on
        TradeDepot Wholesale. We will not be liable for any loss of profits,
        business interruption, loss of business opportunity or any indirect or
        consequential loss or damage.
        <br />
        <br />
        15.12. We will not be liable for any loss or damage caused by a virus,
        distributed denial-of-service attack, or other technologically harmful
        material that may infect your computer equipment, computer programs,
        data or other proprietary material due to your use of the TradeDepot
        Wholesale or to your downloading of any content on it, or on any mobile
        or web app linked to it.
        <br />
        <br />
        15.13. We assume no responsibility for the content of websites linked on
        TradeDepot Wholesale. Such links should not be interpreted as
        endorsement by us of those linked websites. We will not be liable for
        any loss or damage that may arise from your use of them.
      </p>
      <h3>16. Proprietary Marks</h3>
      <p>
        16.1. You acknowledge that the goodwill and other rights in any patents,
        trade marks, trade names, copyright designs, know-how or other
        intellectual property right used and adopted by Us and any logo or
        symbol associated with Our name vest in Us and shall remain vested in
        Us.
        <br />
        <br />
        16.2. You must not in any way use or display or make any statement or
        representation about any of Our proprietary marks unless We authorise
        You to do so, threaten or take any action to contest the validity of Our
        marks or sell, assign, transfer, charge or otherwise deal with Our
        marks.
        <br />
        <br />
        16.3. No right or license is granted under these Terms under any patent,
        trade mark, trade name, copyright, designs, know-how or other
        intellectual property right except the right to use or resell the Goods.
        We cannot guarantee that selling the Goods will affect the rights of any
        third party.
      </p>
      <h3>17. Force Majeure</h3>
      <p>
        17.1. Notwithstanding any other condition We shall have no liability
        whatsoever under or in any way related to the sale and purchase of the
        Goods or otherwise (whether in contract, tort (including without
        limitation negligence) or by way of statutory duty) for any failure to
        fulfil any obligation hereunder if and to the extent that such
        fulfilment is prevented by circumstances beyond Our reasonable control.
      </p>
      <h3>18. No Reliance on Information</h3>
      <p>
        18.1. Although we make reasonable efforts to update the information on
        TradeDepot Wholesale, we make no representations, warranties or
        guarantees, whether express or implied, that the content on TradeDepot
        Wholesale is accurate, complete or up-to-date.
      </p>
      <h3>19. Uploading Content to the TradeDepot Wholesale</h3>
      <p>
        19.1. Whenever you upload content to TradeDepot Wholesale, you must
        comply with the provisions in these terms and conditions. You warrant
        that such content does comply with those terms and that you will be
        liable to us and indemnify us for any breach of that warranty.
        <br />
        <br />
        19.2. We reserve the right to remove any content that does not comply
        with these Terms and Conditions.
      </p>
      <h3>20. Viruses</h3>
      <p>
        20.1. We do not guarantee that TradeDepot Wholesale will be secure or
        free from bugs or viruses.
        <br />
        <br />
        20.2. You are responsible for configuring your information technology,
        computer programmes and platform in order to access TradeDepot
        Wholesale. You should use your own virus protection software.
        <br />
        <br />
        20.3. You must not misuse TradeDepot Wholesale by knowingly introducing
        viruses, trojans, worms, logic bombs or other material which is
        malicious or technologically harmful. You must not attempt to gain
        unauthorised access to TradeDepot Wholesale, the server on which
        TradeDepot Wholesale is stored or any server, computer or database
        connected to TradeDepot Wholesale. You must not attack TradeDepot
        Wholesale via a denial-of-service attack or a distributed denial-of
        service attack. By breaching this provision, you would commit a criminal
        offence under the Computer Misuse Act 1990. We will report any such
        breach to the relevant law enforcement authorities and we will
        co-operate with those authorities by disclosing your identity to them.
        In the event of such a breach, your right to use TradeDepot Wholesale
        will cease immediately.
      </p>
      <h3>21. Links</h3>
      <p>
        21.1. You shall not link to our mobile application, home page, or
        website pages without our written consent. Where the Platform contains
        links to other sites and resources provided by third parties, these
        links are provided for your information only. We have no control over
        the contents of those sites or resources.
      </p>
      <h3>22. General</h3>
      <p>
        22.1. These Conditions shall be governed by the laws of England and You
        agree to submit to the exclusive jurisdiction of the English courts.
        <br />
        <br />
        22.2. The Contract constitutes the entire agreement between Us and You.
        You acknowledge that You have not relied on any statement, promise,
        representation, assurance or warranty made or given by or on behalf of
        Us which is not set out in a Contract.
        <br />
        <br />
        22.3. No waiver by Us of any breach of these Conditions by You shall be
        considered a breach as a waiver of any subsequent breach.
        <br />
        <br />
        22.4. If any provision of these Conditions is held by any court to be
        invalid or unenforceable in whole or in part the validity of the other
        provisions of these Conditions or the remainder of the provision in
        question shall not be affected.
        <br />
        <br />
        22.5. You must not pledge Our credit nor represent Yourself as being Us
        nor Our agent, partner or employee and must not hold Yourself out as
        having any such authority or power to incur any obligations on Our
        behalf.
        <br />
        <br />
        22.6. Subject to clause 22.3, any person who is not a party to a
        Contract between Us shall not have any rights to enforce its terms.
      </p>
      <h3>23. Contact Us</h3>
      <p>
        23.1 To contact us, you can use the chat function on our mobile or web
        application or send us an email at{" "}
        <a href='mailto:support.uk@tradedepot.co'>support.uk@tradedepot.co</a>,
        or chat with us on WhatsApp on{" "}
        <a href='https://wa.me/+447999020000' target='_blank' rel='noreferrer'>
          +44 7999 020000
        </a>
      </p>
      <h4 className='last-modified'>Last Revised: 8th November, 2024</h4>
    </div>
  );
};
