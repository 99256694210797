import { useFormik } from "formik";
import {
  REGISTERED_CONTACT_FOR_RESOURCE_DOWNLOAD,
  saveToHubSpot,
  validateEmail,
} from "../utils/functions";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Loader } from "../checkout-screens/loader";

export function ResourcesContactModal({ resource = null }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const customThrowError = (message = "An error occured") =>
    enqueueSnackbar(message, { variant: "error" });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      company: "",
    },
    onSubmit: async (values) => handleSubmitForm(values),
    // validationSchema: ResourceContactSchema,
  });

  const closeModal = () => document.getElementById("closeModal")?.click();

  const handleSubmitForm = async (values) => {
    if (!values.name) return customThrowError("Please enter your full name");
    if (!values.email || !validateEmail(values.email))
      return customThrowError("Please enter a valid email");
    if (
      !values.phoneNumber ||
      String(values.phoneNumber).length < 6 ||
      String(values.phoneNumber).length > 13
    )
      return customThrowError("Please enter a valid phone number");
    if (!values.company)
      return customThrowError("Please enter your company name");
    const body = {
      ...values,
      firstname: values.name.split(" ")[0] || "",
      lastname: values.name.split(" ")[1] || "",
      companyName: values.company,
      phone: values.phoneNumber,
      state: "",
      city: "",
      zip: "",
      country: "",
      description: "Registered to download resources",
      address: "",
    };
    // Send form data to server
    try {
      setLoading(true);
      await saveToHubSpot(body);
      localStorage.setItem(
        REGISTERED_CONTACT_FOR_RESOURCE_DOWNLOAD,
        JSON.stringify(true)
      );
      window.open(resource?.downloadUrl, "_blank");
      setLoading(false);
      closeModal();
    } catch (error) {
      console.log("catch error creating hubspot", error);
      setLoading(false);
      customThrowError(
        "Something went wrong while registering your details. Please try again later"
      );
    }
  };

  return (
    <>
      <div
        className='modal fade resources-contact-modal'
        id='resourcesContactModal'
        tabindex='-1'
        aria-labelledby='resourcesContactModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='resourcesContactModalLabel'>
                Register to access
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                id='closeModal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <input
                placeholder='Full name*'
                id='name'
                onChange={formik.handleChange}
              />
              <input
                placeholder='Work Email*'
                id='email'
                onChange={formik.handleChange}
              />
              <input
                placeholder='Phone Number*'
                id='phoneNumber'
                onChange={formik.handleChange}
                type='number'
                pattern='[0-9]{10}'
              />
              <input
                placeholder='Company*'
                id='company'
                onChange={formik.handleChange}
              />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={formik.handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    Download <span className='chevron chevron-right' />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
