import { NewHeader } from "../../components/new-header";
import DistributorImgOne from "../../assets/Group 3902@2x.png";
import UploadIcon from "../../assets/Upload Symbol_2.svg";
import { NewFooter } from "../../components/new-footer";
import { Loading } from "../../components/loading-screen";
import { useEffect, useState } from "react";
import { createClient } from "contentful";
import { Pagination } from "../../components/pagination";
import { ResourcesContactModal } from "../../components/resources-contact-modal";
import { REGISTERED_CONTACT_FOR_RESOURCE_DOWNLOAD } from "../../utils/functions";

export function Resources() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [paginationOptions, setPaginationOptions] = useState({
    pages: 0,
    limit: 8,
    currentPage: 0,
  });

  const hasRegisteredForResourceDownload =
    JSON.parse(
      localStorage.getItem(REGISTERED_CONTACT_FOR_RESOURCE_DOWNLOAD)
    ) || false;

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  });

  useEffect(() => {
    handleFetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchResources = async () => {
    setLoading(true);
    try {
      const res = await client.getEntries({
        "sys.contentType.sys.id": "resources",
        order: "sys.createdAt",
        limit: paginationOptions.limit,
        skip: !paginationOptions.currentPage
          ? paginationOptions.currentPage
          : paginationOptions.currentPage * paginationOptions.limit,
      });
      if (!paginationOptions.pages)
        setPaginationOptions({ ...paginationOptions, pages: res.total });
      const resources = res.items.map((item) => ({
        title: item.fields.caseTitle,
        downloadUrl: item.fields.caseDocument.fields.file.url,
      }));
      setResources([...resources]);
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(true);
      setHasError(true);
    }
  };

  useEffect(() => {
    if (paginationOptions.currentPage < 1) return;
    handleFetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOptions.currentPage]);

  return (
    <Loading loading={loading} hasError={hasError}>
      <div className='td_new td_resources'>
        <div className='wrapper'>
          <NewHeader hasTopCircle={false} />
          <div className='body'>
            <div className='introduction'>
              <div className=''>
                <h3>Resources</h3>
                <p>
                  Explore our curated collection of tools, guides, and insights
                  designed to support your journey in global trade and retail.
                  From industry reports and whitepapers to how-to articles and
                  best practices, our resources are here to help you succeed.
                </p>
              </div>
              <div className=''>
                <img
                  src={DistributorImgOne}
                  alt='distributor-img'
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='case-studies'>
              {resources.map((study, key) => (
                <div className='study' key={key}>
                  <div className='_title'>Download</div>
                  <div className='_body'>
                    <p>{study?.title}</p>
                    <button
                      className='td-btn'
                      data-bs-toggle={
                        !hasRegisteredForResourceDownload ? "modal" : ""
                      }
                      data-bs-target={
                        !hasRegisteredForResourceDownload
                          ? "#resourcesContactModal"
                          : null
                      }
                      onClick={() => {
                        setSelectedResource(study);
                        if (hasRegisteredForResourceDownload)
                          window.open(study?.downloadUrl, "_blank");
                      }}
                    >
                      <span>Download</span>
                      <img
                        src={UploadIcon}
                        alt='upload-icon'
                        className='img-fluid'
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              pages={paginationOptions.pages}
              limit={paginationOptions.limit}
              setCurrentPage={(newPage) =>
                setPaginationOptions({
                  ...paginationOptions,
                  currentPage: newPage,
                })
              }
            />
            <NewFooter />
            <ResourcesContactModal resource={selectedResource} />
          </div>
        </div>
      </div>
    </Loading>
  );
}
