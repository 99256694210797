import { useNavigate } from "react-router-dom";
import PartnerImgOne from "../assets/Laptop Lady.png";

export function PartnerComponent({ selectedBrand = null }) {
  const navigate = useNavigate();
  return (
    <div className='td_new_partner'>
      <div className='partner-with-us'>
        <div className='row'>
          <div className='col-6'>
            <img src={PartnerImgOne} alt='side-img' className='img-fluid' />
          </div>
          <div className='col-6'>
            <h3>Become a Distributor</h3>
            <h6>
              We select qualified partners to distribute our premium brands in
              key markets. If you have the expertise and meet our requirements,
              apply to join our trusted network and help bring top global
              products to your region.
            </h6>
            <a href='/distributors#distributor-form' className='td-btn-link'>
              <button
                className='td-btn'
                onClick={() =>
                  navigate(
                    `/distributors#distributor-form?brandName=${selectedBrand}`,
                    {
                      state: { itemname: selectedBrand },
                    }
                  )
                }
              >
                <span>Get started</span>
                <div className='chevron chevron-right' />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
