import { useNavigate } from "react-router-dom";
import { NewHeader } from "./new-header";
import Spanner from "../assets/spanner.png";

export function Loading({ loading = false, children, hasError = false }) {
  const navigate = useNavigate();
  return hasError ? (
    <div className='td_new td_api_failed_page'>
      <div className='wrapper'>
        <NewHeader />
        <div className='body'>
          <h1>Error!</h1>
          <h3>Something went wrong.</h3>
          <p>
            We are sorry! While we try to fix the problem <br />
            please return to the home page and try again later
          </p>
          <button className='td-btn' onClick={() => navigate("/")}>
            <span>Home</span>
            <div className='chevron chevron-right' />
          </button>
          <img src={Spanner} alt='empty-can' className='img-fluid empty-can' />
        </div>
      </div>
    </div>
  ) : (
    <div className='global-overlay'>
      {loading ? (
        <div className='global-overlay-container'>
          <div className='loader'></div>
        </div>
      ) : null}
      {children}
    </div>
  );
}
