/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StatusRenderer } from "./error-screen";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  formatMoney,
  handleRetrieveCartOrders,
  handleRetrieveRetailOutlet,
  handleRetrieveUser,
  printErrorMessage,
} from "../utils/functions";

const CheckoutForm = ({ clientSecret, setStripeHasError, setErrorMessage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsStripeLoading(false);
      });
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }
    setIsProcessing(true);

    // elements.

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();

    if (submitError) {
      // Show error to your customer
      setIsProcessing(false);
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/wholesale/confirm-payment`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setStripeHasError(true);
      setIsProcessing(false);
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement />
      <button type='submit' disabled={isStripeLoading || isProcessing}>
        {isProcessing ? (
          <div class='spinner-border text-success app-spinner' role='status'>
            <span class='sr-only'>Loading...</span>
          </div>
        ) : (
          "Process Payment"
        )}
      </button>
    </form>
  );
};

export function Payment() {
  const {
    state: {
      country = "",
      cartOrder,
      orderPaymentReference = "",
      amount,
      creditBalance = 0,
      walletCapacity = 0,
    },
  } = useLocation();

  return country === "NG" ? (
    <PayWithBankTransfer
      amount={amount}
      creditBalance={creditBalance}
      walletCapacity={walletCapacity}
      orderPaymentReference={orderPaymentReference}
    />
  ) : (
    <PayWithStripe cartOrder={cartOrder} />
  );
}

function PayWithBankTransfer({
  amount: balance,
  creditBalance,
  walletCapacity,
  orderPaymentReference,
}) {
  const navigate = useNavigate();
  const user = handleRetrieveUser();
  const { authToken } = handleRetrieveCartOrders();
  const { account } = handleRetrieveRetailOutlet();

  useEffect(() => {
    document.getElementById("trigger-transfer-button").click();
  }, []);

  const initialMinutes = 15;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(0);
  const [moneySent, setMoneySent] = useState(false);
  const [canBalancePay, setCanBalancePay] = useState(false);
  const [hasTimerExpired, setTimerExpired] = useState(false);
  const [error, setError] = useState({
    hasError: false,
    message: "",
    title: "",
  });

  const handleCloseModalAndRedirect = () => {
    document.getElementById("btn-payment-modal-close").click();
    let url = `/wholesale/checkout?token=${authToken}`;
    if (orderPaymentReference)
      url = url + `&orderPaymentReference=${orderPaymentReference}`;
    return window.location.replace(url);
  };

  const handleCloseModal = () =>
    document.getElementById("btn-payment-modal-soft-close").click();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          setTimerExpired(true);
          clearInterval(intervalId);
          return;
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [minutes, seconds]);

  // if time runs out and we're still on this screen, close modal and redirect user
  if (minutes === 0 && seconds === 0) {
    if (!moneySent) handleCloseModalAndRedirect();
  }

  useEffect(() => {
    // every 10 secs, it checks to see if balance is not able to pay
    // and if time has not expired and makes the call to fetch balances
    let intervalId;
    intervalId = setInterval(() => {
      if (!canBalancePay && minutes === 0 && seconds === 0)
        handleFetchBalances();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [canBalancePay]);

  const handleFetchBalances = async () => {
    try {
      console.log("fetch balance......");
      const res = await axios.get(
        `${process.env.REACT_APP_CONSOLE_V3}/retail/balance`,
        {
          headers: {
            "x-api-key": user.apiKey,
          },
        }
      );
      const {
        data: {
          data: { account },
        },
      } = res;
      if (account?.currentBalance >= walletCapacity + balance) {
        // is able to make wallet payment
        setCanBalancePay(true);
        await handleChargeOrder(walletCapacity + balance);
      }
    } catch (error) {
      const { message, title } = printErrorMessage(error);
      setError({ hasError: true, message, title });
    }
  };

  const handleChargeOrder = async (walletMoney) => {
    try {
      const body = {
        availableBalance: walletMoney,
        creditBalance,
      };
      if (orderPaymentReference)
        body["orderPaymentReference"] = orderPaymentReference;
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL}/charge-order`,
        body,
        {
          headers: {
            "x-api-key": user?.apiKey,
            "Content-Type": "application/json",
          },
        }
      );
      return navigate("/wholesale/confirm-payment", {
        state: {
          reference: data.transactionReference,
          hasOrderReference: true, // the order is created and has a reference
        },
      });
    } catch (error) {
      const { message, title } = printErrorMessage(error);
      setError({
        hasError: true,
        message,
        title,
        canRetry: true,
        retryText: "Try Again!",
        retryFunction: handleCloseModalAndRedirect,
      });
    }
  };

  const handleSentMoney = () => {
    handleCloseModal();
    setMoneySent(true);
  };

  return error.hasError ? (
    <StatusRenderer
      message={error.message}
      title={error.title}
      canRetry={error?.canRetry || false}
      retryText={error?.retryText || null}
      retryFunction={error?.retryFunction || null}
    />
  ) : (
    <div className='trade-checkout_bank-transfer'>
      <button
        className='trigger-button'
        id='trigger-transfer-button'
        data-bs-toggle='modal'
        data-bs-target='#modalBankTransfer'
        style={{ display: "none" }}
      >
        Trigger Modal
      </button>
      <div
        className='trade-checkout-modal modal fade'
        id='modalBankTransfer'
        tabIndex='-1'
        aria-labelledby='modalBankTransferLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='exampleModalLabel'>
                Payment
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                id='btn-payment-modal-soft-close'
                onClick={() => handleCloseModal()}
                style={{ display: "none" }}
              >
                <i class='bi bi-x'></i>
              </button>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                id='btn-payment-modal-close'
                onClick={() => handleCloseModalAndRedirect()}
              >
                <i class='bi bi-x'></i>
              </button>
            </div>
            <div className='modal-body'>
              <p className='_transfer-text'>
                Transfer <b>NGN {formatMoney(balance)}</b> to the account below
              </p>
              <div className='_amount-to-pay'>
                <p>Amount to pay</p>
                <div>₦{formatMoney(balance)}</div>
              </div>
              <div className='_account-details'>
                <div>
                  <p>
                    <h6>bank name</h6>
                    <h5>{account?.secBankName}</h5>
                  </p>
                  <CopyToClipboard
                    text={account?.secBankName}
                    onCopy={() => window.alert("Bank Name copied!")}
                  >
                    <span>
                      <i class='bi bi-copy'></i>
                    </span>
                  </CopyToClipboard>
                </div>
                <div>
                  <p>
                    <h6>account number</h6>
                    <h5>{account?.secAccountNumber}</h5>
                  </p>
                  <CopyToClipboard
                    text={account?.secAccountNumber}
                    onCopy={() => window.alert("Account Number copied!")}
                  >
                    <span>
                      <i class='bi bi-copy'></i>
                    </span>
                  </CopyToClipboard>
                </div>
                <div>
                  <p>
                    <h6>amount</h6>
                    <h5>NGN {formatMoney(balance)}</h5>
                  </p>
                  <CopyToClipboard
                    text={balance}
                    onCopy={() => window.alert("Balance copied!")}
                  >
                    <span>
                      <i class='bi bi-copy'></i>
                    </span>
                  </CopyToClipboard>
                </div>
              </div>
              <center>
                <h6 className='_expires-text'>
                  Expires in{" "}
                  <span>
                    {minutes} minutes {seconds} seconds
                  </span>
                </h6>
              </center>
              <div className='_action-buttons'>
                <button className='_sent-money' onClick={handleSentMoney}>
                  I've sent the money
                </button>
                <button
                  className='_cancel-payment'
                  onClick={() => handleCloseModalAndRedirect()}
                >
                  Cancel Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {moneySent && (
        <div className='trade-checkout_confirm-payment'>
          <p className='repeat-container'>
            <i className='bi bi-arrow-repeat'></i>
          </p>
          <h3>Payment Confirmation</h3>
          {!hasTimerExpired ? (
            <>
              <h5>
                We are confirming your payment. This may take a few minutes.
              </h5>
              <div class='loader-line'></div>
            </>
          ) : (
            <>
              <h5>
                It’s taking a while to confirm your payment, If you need any
                assistance, please don't hesitate to contact our support team.
              </h5>
              <div className='timer-expired-action'>
                <button
                  onClick={handleCloseModalAndRedirect}
                  className='trade-uk-app-button'
                >
                  Go to cart
                </button>
                <button
                  className='intercom-custom-btn'
                  onClick={() => window.Intercom("show")}
                >
                  <img src='../images/intercom.svg' alt='' />
                  Contact Support
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function PayWithStripe({ cartOrder }) {
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeHasError, setStripeHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSetStripeData = useCallback(() => {
    setClientSecret(cartOrder.clientSecret);
    setStripePromise(loadStripe(cartOrder.publishableKey));
  }, []);

  useEffect(() => {
    if (cartOrder) handleSetStripeData();
  }, []);

  return stripeHasError ? (
    <StatusRenderer
      title={"Stripe Payment Failure"}
      message={errorMessage}
      canRetry={true}
      retryFunction={() => {
        setStripeHasError(false);
      }}
    />
  ) : (
    <div className='trade-uk_checkout-pay'>
      {stripePromise && clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, loader: "always" }}
        >
          <CheckoutForm
            clientSecret={clientSecret}
            setStripeHasError={setStripeHasError}
            setErrorMessage={setErrorMessage}
          />
        </Elements>
      )}
    </div>
  );
}
